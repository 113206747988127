.modal-lg {
    max-width: 1070px;
}

.modal-backdrop.show {
    opacity: 0.75;
}

.ekko-lightbox .modal-dialog {
    margin-top: 0;
    margin-bottom: 0;
}

.modal-content {
    border: none;
}

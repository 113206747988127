.centeredButtonContainerOuter {
    .centeredButtonContainer {
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }

    @include media-breakpoint-down(sm) {
        .centeredButtonContainer {
            margin-top: 45px;
        }
    }
}

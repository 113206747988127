//== General
$page-width:     1600px;
$page-min-width:  320px;
$content-width:  1070px;


//== Colors
$gray-100: #e9ecef;
$gray-200: #d5d5d5;
$gray-300: #b3b3b3;
$gray-400: #737373;
$gray-500: #323232;

$green-light:  #e3f5df;
$green:        #3db60f;
$green-darker: #31930c;
$green-dark:   #267407;

$error-red: #d23100;

// $white-link-hover-color: #cefbce;
$white-link-hover-color: #e5ede1;

$primary: $green;

$theme-colors: (
    'green':       $green,
    'green-light': $green-light,
    'gray':        $gray-100,
);


//== Options
$enable-rounded: false;


//== Body
$body-color: #333;


//== Fonts
$font-family-sans-serif: TheSans, "Trebuchet MS", sans-serif;

$font-weight-normal: 500;

$line-height-base: 1.625;

$h1-font-size:   46px;
$h1-line-height: 50px;

$h2-font-size:   30px;
$h2-line-height: 38px;

$h3-font-size:   20px;
$h3-line-height: 30px;

$headings-font-weight: 300;

$lead-font-size:   20px;
$lead-line-height: 28px;


//== Fontawesome
$fa-font-path: '../fonts/Fontawesome';


//== Tables
$table-cell-padding: 15px;
$table-border-color: $gray-200;
$table-accent-bg: $gray-100;


//== Paragraphs
$paragraph-margin-bottom: 10px;


//== Links
$link-hover-color: $green-dark;
$link-hover-decoration: none;


//== Spacing
$sizes: (
    33: percentage(1 / 3),
    66: percentage(2 / 3),
);

//== Forms
$input-border-color: #dde0e5;

$input-focus-border-color: $green;
$input-focus-box-shadow: none;

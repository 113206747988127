.offcanvasContainer {
    position: relative;

    .offcanvas {
        display: none;
        position: absolute;
        top: 160px;
        // We need a very high z-index, because LiveZilla has 20000001
        z-index: 100000000;
        width: 100%;
        transform: translate3d(100%, 0, 0);
        transition: transform 250ms ease;

        &.showOffcanvas {
            transform: translate3d(0, 0, 0);
            top: 110px;
        }
    }

    .offcanvasCounterpart {
        // the following line is commented out, because it causes problems, with
        // position fixed elements inside of it
        // @include translate3d(0, 0, 0);
        transition: transform 250ms ease;
    }

    &.showOffcanvas .offcanvasCounterpart {
        transform: translate3d(-100%, 0, 0);
    }
}

.uc-embed {
    .uc-checkbox {
    }

    div.uc-optInCheckboxWithLabel {
        position: relative;
        display: block;
        margin-top: 21px;

        > input {
            position: absolute;
            z-index: -1;
            opacity: 0;

            &:disabled {
                ~ label.uc-optInCheckboxWithLabel {
                    &:before {
                        background-color: #f2fbf2;
                    }

                    &:after {
                        color: #9ad287;
                    }

                    h3 {
                        color: #9ad287;
                    }
                }
            }
        }
    }

    label.uc-optInCheckboxWithLabel {
        padding: 7px 0 7px 33px;
        margin-right: 0;
        cursor: pointer;
        margin-bottom: 0;
        display: block !important;

        h3 {
            margin-top: 0;
            margin-bottom: 0;
            font-size: 16px;
            line-height: 26px;
        }

        &:before,
        &:after {
            position: absolute;
            display: block;
            pointer-events: none;
            content: "";
            user-select: none;
            transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }

        &:before {
            top: 10px;
            left: 0;
            width: 21px;
            height: 21px;
            border-radius: 3px;
            border: 1px solid #dde0e5;
            background-color: #fff;

            @at-root {
                div.uc-optInCheckboxWithLabel > input:checked  ~ label.uc-optInCheckboxWithLabel:before {
                    background-color: #fff;
                }
            }

            @at-root {
                div.uc-optInCheckboxWithLabel > input:focus  ~ label.uc-optInCheckboxWithLabel:before {
                    box-shadow: none;
                }
            }

            @at-root {
                div.uc-optInCheckboxWithLabel > input:active ~ label.uc-optInCheckboxWithLabel:before {
                    background-color: #fff;
                }
            }
        }

        &:after {
            top: 10px;
            left: 0;
            width: 21px;
            height: 21px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 50% 50%;

            @at-root {
                div.uc-optInCheckboxWithLabel > input:checked  ~ label.uc-optInCheckboxWithLabel:after {
                    top: 14px;
                    left: 4px;
                    @extend %iconfont-murrelektronik, %icon-check-bold:before;
                    color: $green;
                    font-size: 13px;
                    background-image: none;
                }
            }
        }

        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            padding-top: 9px;
            padding-bottom: 7px;
        }

        &:hover, &:focus {
            color: $green;
            border-color: $green;
        }

        @at-root {
            div.uc-optInCheckboxWithLabel > input:checked  ~ label.uc-optInCheckboxWithLabel {
                color: $green;
                border-color: $green;
            }
        }
    }

    h4 {
        &.uc-heading {
            font-weight: 700;
            color: $body-color;
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 10px;
        }
    }

    .uc-list,
    .uc-div {
        color: $body-color;
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 10px;
    }

    .uc-a {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 10px;
        display: inline-block;

        @include media-breakpoint-down(xs) {
            word-break: break-all;
        }
    }
}

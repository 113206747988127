.accordion {
    margin-top: 70px;
    margin-bottom: 28px;
    padding-bottom: 8px;

    .card {
        border: none;
        border-top: solid 1px $gray-100 !important;
        border-bottom: solid 1px $gray-100 !important;
        background-color: transparent;

        + .card {
            border-top: none !important;
        }

        .btn + .btn {
            margin-left: 30px;

            @include media-breakpoint-down(xs) {
                margin-left: 0;
                margin-top: 15px;
                display: table;
            }
        }
    }

    .card-header {
        background-color: transparent;
        border: none;
        padding: 0;

        .header {
            margin: 0;
            max-width: none;

            &:hover,
            &:focus {
                .btn {
                    color: $green;
                }
            }
        }

        .btn {
            position: relative;
            width: 100%;
            text-align: left;
            padding: 25px 54px 24px 0;
            font-size: 20px;
            line-height: 28px;
            white-space: normal;
            color: $body-color;

            &:before, &:after {
                display: block;
                content: '';
                position: absolute;
                height: 2px;
                background-color: currentColor;
                transition: transform 300ms linear, top 300ms linear;
            }

            &:before {
                right: 9px;
                width: 14px;
                transform-origin: left center;
            }

            &:after {
                right: -1px;
                width: 14px;
                transform-origin: right center;
            }

            &.collapsed {
                &:before, &:after {
                    top: 34px;
                }

                &:before {
                    transform: rotate(30deg);
                }

                &:after {
                    transform: rotate(-30deg);
                }
            }

            &:not(.collapsed) {
                &:before, &:after {
                    top: 42px;
                }

                &:before {
                    transform: rotate(-30deg);
                }

                &:after {
                    transform: rotate(30deg);
                }
            }
        }
    }

    .card-body {
        padding: 0 0 25px;

        > .contentElementContainer {
            padding-right: 0;
            padding-left: 0;
        }

        > :last-child {
            margin-bottom: 0;
        }

        .swiper-slide {
            width: 100%;
        }
    }

    .bg-green & {
        .card-header {
            .btn {
                color: #fff;

                &:hover, &:focus {
                    color: $white-link-hover-color;
                }
            }
        }
    }

    .bg-green-light & {
        .card {
            border-top: solid 1px $gray-300 !important;
            border-bottom: solid 1px $gray-300 !important;

            + .card {
                border-top: none !important;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        margin-top: 57px;
        margin-right: -15px;
        margin-left: -15px;

        .card-header {
            .btn {
                padding-left: 15px;
                padding-right: 69px;

                &:before {
                    right: 24px;
                }

                &:after {
                    right: 15px;
                }
            }
        }

        .collapse, .collapsing {
            padding-right: 15px !important;
            padding-left: 15px !important;
        }
    }
}

.bg-gray {
    .accordion {
        .card {
            border-top: solid 1px $gray-200 !important;
            border-bottom: solid 1px $gray-200 !important;

            + .card {
                border-top: none !important;
            }
        }
    }
}

.cObjText,
.cObjHeader {
    + .contentElementContainer {
         .accordion {
            margin-top: 26px;
        }
    }
}

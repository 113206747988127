//== Holy grail and general layout
html {
    min-width: $page-min-width;
}

body {
    font-size: 16px;

    &.modal-open {
        overflow: visible;
        padding-right: 0 !important;
    }
}

html,
body,
#pageContainer {
    min-height: 100%;
    height: 100%;
}

#pageContainer {
    display: flex;
    flex-direction: column;

    &.showOffcanvas {
        overflow-y: hidden;
    }

    // body.modal-open & {
    //     overflow: hidden;
    // }
}

#page {
    display: flex;
    flex-direction: column;
    min-height: 100%;

    // body.modal-open & {
    //     overflow: hidden;
    // }
}

#contentContainer {
    flex: 1 0 auto;
    overflow: hidden;
}

//== Remove outlines
a, input, button, textarea, select, ul, li, div {
    &:focus {
        outline: none;
    }
}

//== contentElementContainer
.contentElementContainer {
    max-width: #{$content-width + 30px};
    padding-right: 15px;
    padding-left: 15px;
    margin: 0 auto;

    &:after {
        display: block;
        content: '';
        clear: both;
    }

    h2, .h2 {
        + h3,
        + .h3 {
            margin-top: 0;
        }
    }

    > h1,
    > .h1 {
        margin-top: 74px;
        margin-bottom: 20px;
    }
}

#content {
    > .contentElementContainer {
        &:last-child {
            margin-bottom: 91px;

            @include media-breakpoint-down(sm) {
                margin-bottom: 59px;
            }
        }
    }
}

//== fullWidthElement
.fullWidthElement {
    &[class*="bg-"] {
        padding-top: 92px;
        padding-bottom: 93px;

        &.smallSpacing {
            padding-top: 62px;
            padding-bottom: 63px;
        }
    }

    #content > &:not(:first-child) {
        margin-top: 94px;
    }

    #content > &:not(:last-child) {
        margin-bottom: 91px;
    }

    @at-root {
        #content .fullWidthElement + .fullWidthElement {
            margin-top: -91px;
        }
    }

    > :first-child,
    .contentElementContainer:first-child > :first-child {
        margin-top: 0;
    }

    > :last-child {
        margin-bottom: 0;
    }

    .contentElementContainer:last-child {
        > :last-child,
        > .tab-content:last-child > .tab-pane > :last-child {
            margin-bottom: 0;
        }
        > :last-child.btn {
            margin-bottom: 7px;
        }
    }

    > .tilesContainerWrap:last-child {
        margin-bottom: 0;
    }

    > .contentElementContainer {
        > .socialMediaTeaser {
            h2, .h2 {
                margin-top: 0;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        &[class*="bg-"] {
            padding-top: 60px;
            padding-bottom: 58px;

            &.smallSpacing {
                padding-top: 44px;
                padding-bottom: 44px;
            }
        }

        #content > &:not(:first-child) {
            margin-top: 57px;
        }

        #content > &:not(:last-child) {
            margin-bottom: 59px;
        }

        @at-root {
            #content .fullWidthElement + .fullWidthElement {
                margin-top: -59px;
            }
        }
    }
}


//== Hyphenator
.hyphenate {
    @include hyphens(none);

    @include media-breakpoint-down(sm) {
        @include hyphens(auto);
    }
}

.hyphenate-always {
    @include hyphens(auto);
}

.donthyphenate {
    @include hyphens(none);
}

//== Misc
@-ms-viewport {
    width: auto !important;
}

.backgroundSrcsetImage {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    position: absolute;
    top: 0;
    left: 0;
}

html.noObjectFit .backgroundSrcsetImage {
    position: absolute;
    display: block;
    // display: none;
    width: 100%;
    visibility: hidden !important;
}

%menuIcon {
    position: relative;
    display: inline-block;
    width: 23px;
    height: 20px;

    span {
        position: absolute;
        width: 23px;
        height: 2px;
        background-color: #fff;
        top: 0;
        left: 0;
        transform: rotate(0);
        opacity: 1;
        transition: width 150ms ease 300ms;

        //TODO testweise drausen
        // transition:
        //     top 300ms ease 300ms,
        //     transform 300ms ease,
        //     opacity 300ms ease,
        // ;

        &:nth-child(1) {
            // Nothing to do here...
        }

        &:nth-child(2) {
            top: 9px;
            width: 18px;
        }

        &:nth-child(3) {
            top: 18px;
        }
    }
}

// TODO testweiße draußen
// %menuIcon-active {
//     span {
//         transition:
//             top 300ms ease,
//             transform 300ms ease 300ms,
//             opacity 300ms ease,
//         ;

        // &:nth-child(1) {
        //     top: 9px;
        //     transform: rotate(45deg);
        // }
        //
        // &:nth-child(2) {
        //     opacity: 0;
        // }
        //
        // &:nth-child(3) {
        //     top: 9px;
        //     transform: rotate(-45deg);
        // }
//     }
// }

.headlineFollowingText {
    margin-top: 94px;

    @include media-breakpoint-down(sm) {
        margin-top: 59px;
    }

    + h2,
    + .h2 {
        margin-top: 0;

        // + .lead {
        //     margin-top: 34px;
        //
        //     @include media-breakpoint-down(sm) {
        //         margin-top: 31px;
        //     }
        // }
    }

    + h1,
    + .h1 {
        margin-top: 0;
        margin-bottom: 18px;
        max-width: none;

        // + .lead {
        //     margin-top: 34px;
        //
        //     @include media-breakpoint-down(sm) {
        //         margin-top: 31px;
        //     }
        // }
    }
}

.pictureWithMaxWidthContainer {
    display: flex;
    margin-top: 54px;
    padding-top: 7px;
    margin-bottom: 63px;

    @include media-breakpoint-down(sm) {
        margin-top: 59px;
        margin-bottom: 59px;
        padding-top: 5px;
        padding-bottom: 6px;
    }

    .pictureWithMaxWidth {
        max-width: 800px;
        height: auto;
        text-align: center;
    }

    &.centered {
        justify-content: center;
    }

    &.alignRight {
        justify-content: flex-end;
    }
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -12;
    // pointer-events: none;
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 300ms linear;

    &.active {
        opacity: 1;
        z-index: 12;
        transition: opacity 300ms linear;
    }
}

.onSiteBacklinkContainer {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    .onSiteBacklink {
        position: relative;
        padding-top: 45px;
        padding-bottom: 45px;

        @include media-breakpoint-down(sm) {
            margin-top: 59px;
        }
        a {
            align-items: center;
        }

        &:hover,
        &:focus {
            .icon {
                background-color: $green-dark;
            }
        }

        .icon {
            position: relative;
            top: 1px;
            display: inline-block;
            width: 35px;
            height: 35px;
            flex-shrink: 0;
            background-color: $green;
            margin-right: 18px;

            @include media-breakpoint-down(xs) {
                width: 30px;
                height: 30px;
                margin-right: 10px;
            }

            &:before {
                position: relative;
                top: 8px;
                left: 14px;
                font-size: 19px;
                color: #fff;

                @include media-breakpoint-down(xs) {
                    font-size: 16px;
                    top: 7px;
                    left: 12px;
                }
            }
        }

        .text {
            font-size: 30px;
            line-height: 38px;

            @include media-breakpoint-down(md) {
                font-size: 24px;
                line-height: 30px;
            }

            @include media-breakpoint-down(sm) {
                font-size: 20px;
                font-weight: 500;
            }

            @include media-breakpoint-down(xs) {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
}

.wes_addresses-view {
    > form {
        > .fullWidthElement {
            margin-bottom: -94px;
            margin-top: 94px;
            position: relative;

            @include media-breakpoint-down(sm) {
                margin-bottom: -57px;
                margin-top: 57px;
            }

            &:after {
                content: "";
                position: absolute !important;
                width: 0;
                height: 0;
                border-left: 12px solid transparent;
                border-right: 12px solid transparent;
                border-top: 12px solid #fff;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                top: 0;
            }

            .loader {
                position: absolute;
                top: calc(50% - 30px);
                left: calc(50% - 30px);
                animation-name: infinite-spinning;
                animation-duration: 700ms;
                animation-iteration-count: infinite;
                animation-timing-function: linear;

                &:before {
                    content: "";
                    background-image: url("/fileadmin/images/ajax-loader.png");
                    background-size: 60px 60px;
                    height: 60px;
                    width: 60px;
                    display: block;
                }
            }
        }
    }
}

.gm-style-pbt {
    transform: translate(-50%, -50%);
    left: 50%;
}

.applyLink {
    // margin-top: 18px;

    .icon {
        margin-right: 10px;
    }
}

.noMaxWidth {
    max-width: none;
}

.thinText {
    font-weight: 300;
}

b, strong {
    font-weight: 700;
}

.moreInformation {
    margin-bottom: 25px;

    @include media-breakpoint-down(sm) {
        margin-bottom: 28px;
    }

    + .btn {
        white-space: normal;
    }
}

.noPictureFill {
    background-color: $gray-200;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-grow: 1;

    .icon {
        color: $gray-300;

        &:before {
            font-size: 80px;
        }
    }
}

.landingpageLink {
    &:before {
        font-family: 'iconfont-murrelektronik' !important;
        content: "\e909";
        font-weight: 300;
        padding-right: 10px;
        font-size: 14px;
        position: relative;
        top: 2px;
    }
    span {
        font-size: 16px !important;
        padding-right: 0 !important;
    }
}

.showOverflow {
    overflow: visible !important;
}

.text-center {
    // text-align: center !important;
    margin: 0 auto;
}

.stickyNewsletterLink {
    position: fixed;
    right: 15px;
    bottom: 110px;
    width: 80px;
    height: 80px;
    z-index: 12;
    border-radius: 50%;
    background-color: $green;
    border: 1px solid $green-darker;
    color: #fff;
    cursor: pointer;
    box-shadow: 1px 2px 27px 0px rgba(#000, 0.13);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateX(200px);
    transition: 300ms transform linear;

    &.show {
        transform: translateX(0);
    }

    @include media-breakpoint-down(sm) {
        width: 60px;
        height: 60px;
        bottom: 90px;
    }

    &:hover, &:focus {
        background-color: $green-dark;
        border-color: $green-dark;

        .icon,
        .text {
            color: #fff;
        }
    }

    .icon {
        font-size: 26px;
        // margin-top: 6px;
        margin-bottom: 5px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 0;
        }
    }

    .text {
        font-size: 12px;
        line-height: 14px;
        max-width: 72px;
        hyphens: none;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}

// @see OSF-536-10736
// Move WhatsApp badge on murrelektronik.com.br.
// IDs twice to increase specificy, so we can actually overwrite.
#bricks-component-ulHzEGbJ_PecPGlROD97Ng-wrapper#bricks-component-ulHzEGbJ_PecPGlROD97Ng-wrapper.floating-button.floating-button--close {
    bottom: 90px !important;
    right: 5px !important;

    @media (max-width: 767px) {
        bottom: 70px !important;
        right: -5px !important;
    }
}

.stickyElementsContainer {
    // Size of one sticky element.
    --size: 73px;

    position: fixed;
    right: 0;
    top: calc(50% + (71px / 2));
    transform: translate(var(--size), -50%);
    transition: transform 300ms linear;
    z-index: 12;
    display: flex;
    flex-direction: column;
    // align-items: flex-end;
    pointer-events: none;
    background: linear-gradient(270deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) var(--size), rgba(255,255,255,0) var(--size), rgba(255,255,255,0) 100%);

    &.show {
        transform: translate(0, -50%);
    }

    @include media-breakpoint-down(sm) {
        --size: 60px;
    }
}

.stickyElement {
    display: flex;
    align-items: center;
    height: var(--size);
    transform: translateX(calc(100% - var(--size)));
    transition: transform 250ms;
    background-color: #37a40d;
    color: #fff;
    padding-right: 10px;
    pointer-events: auto;

    // Fixes for <button>
    cursor: pointer;
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;
    border: none;
    text-align: left;

    + .stickyElement {
        margin-top: 1px;
    }

    &:hover,
    &.showBig {
        transform: translateX(0);
        color: #fff;
    }

    .iconContainer {
        flex-shrink: 0;
        width: var(--size);
    }

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;

        &:before {
            font-size: 30px;
        }
    }

    .iconText {
        font-size: 12px;
        line-height: 14px;
        hyphens: none;
        margin-top: 5px;
        text-align: center;
    }

    .text {
        font-size: 16px;
        line-height: 24px;
    }

    @include media-breakpoint-down(sm) {
        .iconText {
            display: none;
        }
    }
}

// .stickyElementsContainer {
//     &.v1 {
//         .stickyElement {
//             margin-bottom: 2px;
//         }
//     }

//     &.v2 {
//         .stickyElement {
//             margin-bottom: 0;
//             border: 1px solid #fff;
//             border-right: none;

//             + .stickyElement {
//                 margin-top: -1px;
//             }
//         }
//     }

//     &.v3 {
//         background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) var(--size), rgba(255,255,255,0) var(--size), rgba(255,255,255,0) 100%);

//         .stickyElement:last-child {
//             margin-bottom: 0;
//         }
//     }

//     &.v4 {
//         .stickyElement {
//             width: 326px;
//         }
//     }

//     &.v4.v5 {
//         .stickyElement {
//             width: 271px;
//         }
//     }

//     &.v6 {
//         top: calc(50% + (146px / 2));
//     }

//     &.v7 {
//         top: calc(50% + (71px / 2));
//     }

//     // &.v8.showAllBig {
//     //     .stickyElement {
//     //         animation-name: stickyElementShowAllBig;
//     //         animation-duration: 5s;
//     //         animation-iteration-count: 1;

//     //         transform: translateX(calc(100% - var(--size)));

//     //         &:nth-child(1) { animation-delay: 0s; }
//     //         &:nth-child(2) { animation-delay: 5s; }
//     //         &:nth-child(3) { animation-delay: 10s; }

//     //         &:hover {
//     //             transform: translateX(0);
//     //         }
//     //     }
//     // }

//     &.v9 {
//         background: linear-gradient(270deg, rgba(255,255,255,0.6) 0%, rgba(255,255,255,0.6) var(--size), rgba(255,255,255,0) var(--size), rgba(255,255,255,0) 100%);

//         .stickyElement:last-child {
//             margin-bottom: 0;
//         }
//     }

//     &.v10 {

//         .stickyElement:last-child {
//             margin-bottom: 0;
//         }
//     }
// }

// // @keyframes stickyElementShowAllBig {
// //     0% {
// //         transform: translateX(calc(100% - 73px));
// //     }
// //     #{percentage(250/5000)} {
// //         transform: translateX(0);
// //     }
// //     #{percentage(4750/5000)} {
// //         transform: translateX(0);
// //     }
// //     100% {
// //         transform: translateX(calc(100% - 73px));
// //     }
// // }
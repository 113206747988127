@mixin hyphens($value) {
    @if $value == auto {
        // Fallback for browsers, which don't support auto
        @include hyphens(manual);
    }

    -webkit-hyphens: $value;
       -moz-hyphens: $value;
        -ms-hyphens: $value;
            hyphens: $value;
}

@mixin placeholder($color) {
    &::placeholder { color: $color; }

    // Firefox
    &::-moz-placeholder {
        color: $color;
        opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
    }
    &:-ms-input-placeholder { color: $color; } // Internet Explorer 10+
    &::-webkit-input-placeholder { color: $color; } // Safari and Chrome
}

@mixin position-offset-zero($position: absolute) {
    position: $position;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

//== CSS border triangle
@mixin border-triangle($direction, $width, $height, $color) {
    @if $direction == up {
        border-bottom: $height solid $color;
        border-right: $width / 2 solid transparent;
        border-left: $width / 2 solid transparent;
    } @else if $direction == right {
        border-left: $height solid $color;
        border-top: $width / 2 solid transparent;
        border-bottom: $width / 2 solid transparent;
    } @else if $direction == down {
        border-top: $height solid $color;
        border-right: $width / 2 solid transparent;
        border-left: $width / 2 solid transparent;
    } @else if $direction == left {
        border-right: $height solid $color;
        border-top: $width / 2 solid transparent;
        border-bottom: $width / 2 solid transparent;
    }
}

//== Browser switches (requires initBrowserSwitch in main.js)
@mixin onlyInIE($versions: null) {
    @if $versions {
        @if type-of($versions) == number {
            @at-root .ie#{$versions} & {
                @content;
            }
        } @else if type-of($versions) == list {
            $s: '';
            @each $version in $versions {
                @if $s == '' {
                    $s: ".ie#{$version} #{&}";
                } @else {
                    $s: "#{$s}, .ie#{$version} #{&}";
                }
            }
            @at-root #{$s} {
                @content;
            }
        } @else {
            @error "Type #{type-of($versions)} in not support by the browser switch!";
        }
    } @else {
        @at-root .ie & {
            @content;
        }
    }
}

@mixin notInIE {
    @at-root html:not(.ie) & {
        @content;
    }
}

@mixin onlyInGC($versions: null) {
    @if $versions {
        @if type-of($versions) == number {
            @at-root .gc#{$versions} & {
                @content;
            }
        } @else if type-of($versions) == list {
            $s: '';
            @each $version in $versions {
                @if $s == '' {
                    $s: ".gc#{$version} #{&}";
                } @else {
                    $s: "#{$s}, .gc#{$version} #{&}";
                }
            }
            @at-root #{$s} {
                @content;
            }
        } @else {
            @error "Type #{type-of($versions)} in not support by the browser switch!";
        }
    } @else {
        @at-root .gc & {
            @content;
        }
    }
}

@mixin onlyInEdge {
    @at-root .edge & {
        @content;
    }
}

@mixin onlyInDesktopSafari {
    @at-root .safari & {
        @content;
    }
}

@mixin onlyOniOS {
    @at-root .ios & {
        @content;
    }
}

@mixin onlyOnMobile {
    @at-root .mobile & {
        @content;
    }
}

.productSecurity {
    .loader {
        margin: 0 auto;
        margin-top: 60px;
        width: 60px;
        height: 60px;
        top: 0;
        left: calc(50% - 15px);
        animation-name: infinite-spinning;
        animation-duration: 700ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

        &:before {
            display: block;
            height: 60px;
            width: 60px;
            content: "";
            background-image: url("/fileadmin/images/ajax-loader.png");
            background-size: 60px 60px;
        }
    }
}

table.dataTable {
    th, td {
        white-space: nowrap;
    }

    tr td:nth-child(3) {
        white-space: wrap;
    }

    thead > tr > th.dt-orderable-asc span.dt-column-order, 
    thead > tr > th.dt-orderable-desc span.dt-column-order {
        color: $green-darker;
    }

    thead > tr > th.dt-orderable-asc:hover, 
    thead > tr > th.dt-orderable-desc:hover {
        outline: none !important;
    }

    tbody > tr > * {
        box-shadow: none !important;
    }

    tbody > tr:nth-child(odd) >* {
        background-color: $green-light;
    }

    tbody > tr > td {
        padding: 10px !important;
    }

    &.display.row-border>tbody > tr >*, 
    &.display > tbody > tr >* {
        // border-top: 1px solid rgb(55, 164, 13) !important;
        border-top: none !important;
    }  

    td.dt-type-date, td.dt-type-numeric {
        padding-right: 30px !important;
    }

    span.inactive {
        color: $green;
        opacity: 0.5;
    }

    .downloads {
        display: flex;
        gap: 12px;
    }

    .fa {
        font-size: 12px;
        margin-right: 1px;
    }
}

#datatable_wrapper {
    margin-top: 60px;
    
    .top {
        margin-bottom: 30px;

        .dt-search {
            input {
                width: 100%;
            }
        }

        .dt-length {
            text-align: right;
        }
    }

    .dt-input {
        height: 48px;
        border-radius: 3px;
        border: 1px solid #dde0e5;
        transition: border-color 0.15s ease-in-out;
        padding: 6px 13px 7px 13px;
    }

    select.dt-input {
        margin-left: 8px;
        padding-right: 33px;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        border-radius: 3px;
        border-color: #dde0e5;
        background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 7'%3E%3Cpath fill='rgb(221,224,229)' d='M0 0L11 0L6 7z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
        background-size: 11px 7px;
    }

    .dt-input:focus {
        border-color: #3db60f;
        outline: 0;
    }

    .dt-paging nav  {
        display: flex;
    }

    .dt-paging-button {
        display: inline-flex;
        min-width: 26px;
        justify-content: center;
        align-items: center;
        background: none;
        border: none;
        border-radius: 0;
        padding: 0;

        &.current {
            background: none;
            border: none;
            color: $green !important;
        }

        &:active {
            box-shadow: none;
        }

        &:hover {
            color: $green !important;
        }

        &.previous,
        &.next {
            height: 40px;
            width: 40px;
            background-color: $green;
            color: #FFFFFF !important;
            padding: 7px 16px;
            font-size: 20px;
            
            &:hover {
                background-color: $green-dark;
                border: none;
            }
        }

        &.previous {
            margin-right: 20px;
        }

        &.next {
            margin-left: 20px;
        }
    }

    .pagination-custom {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 26px;
    }
}

#datatable {
    display: none;
}
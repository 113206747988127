#mobileNavigationContainer {
    height: calc(100% + 110px);
    padding-top: 19px;
    background-color: $green;
    overflow-x: hidden;

    .nav-link {
        position: relative;
        color: #fff;
        line-height: 20px;
        font-weight: 700;
        padding: 14px 68px 16px 15px;

        &:hover, &:focus {
            background-color: #37a40d;
            color: $white-link-hover-color;
        }

        &.backLink {
            padding-top: 12px;
            padding-right: 18px;
            padding-left: 44px;

            &:before {
                @extend %iconfont-murrelektronik, %icon-chevron-left:before;
                font-size: 19px;
                position: relative;
                top: 4px;
                left: -13px;
            }
        }

        &.btn {
            display: inline-block;
            margin-left: 15px;
            padding-left: 53px;
            width: calc(100% - 30px);
            margin-top: 5px;
            margin-bottom: 5px;

            // @include media-breakpoint-down(sm) {
            //     margin-left: 15px;
            //     width: calc(100% - 30px);
            // }
        }
    }

    .nav-item.active > .nav-link {
        background-color: #37a40d;
    }

    .nav-item.hasChildren > .nav-link:after {
        position: absolute;
        top: 15px;
        right: 31px;
        font-size: 19px;
        display: block;
        @extend %iconfont-murrelektronik, %icon-chevron-right:before;
    }

    > .nav {
        position: relative;
        transform: translate3d(0, 0, 0);
        transition: transform 300ms linear;

        .nav {
            display: none;
            position: absolute;
            top: 0;
            left: 100%;
            width: 100%;
        }
    }

    @include media-breakpoint-down(md) {
        .nav-link {
            &.backLink {
                padding-left: 29px;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .nav-link {
            padding-right: 53px;
            padding-left: 15px;
        }

        .nav-item.hasChildren > .nav-link:after {
            right: 16px;
        }
    }
}

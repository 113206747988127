.thumbnailNavigation {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);

    .thumbnail {
        position: relative;
        cursor: pointer;

        &.active {
            &:after {
                content: '';
                @include position-offset-zero;
                background-color: rgba($green, .8);
            }
        }
    }

    @include media-breakpoint-down(sm) {
        display: none;
    }
}

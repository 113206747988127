@font-face {
    font-family: 'TheSans';
    font-display: swap;
    src: url('../fonts/TheSans/TheSansC5-3_Light.woff2') format('woff2'),
         url('../fonts/TheSans/TheSansC5-3_Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'TheSans';
    font-display: swap;
    src: url('../fonts/TheSans/TheSansC5-5_Plain.woff2') format('woff2'),
         url('../fonts/TheSans/TheSansC5-5_Plain.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'TheSans';
    font-display: swap;
    src: url('../fonts/TheSans/TheSans_TT7_LP.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

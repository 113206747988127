.nav.nav-tabs {
    border-bottom: none;
    margin-top: 85px;
    margin-bottom: 40px;
    margin-left: -15px;
    margin-right: -15px;
    flex-wrap: nowrap;

    .nav-item {
        margin-top: -25px;
        text-align: center;
        display: flex;

        + .nav-item {
            margin-left: 23px;
        }

        .nav-link {
            padding: 15px;
            border: none;
            color: $body-color;
            position: relative;
            background-color: inherit;
            transition: color 300ms;

            &:after {
                content: "";
                position: absolute;
                width: 0;
                max-width: calc(100% - 30px);
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
                border-top: 1px solid $green;
                transition: width 300ms;
            }

            &.active {
                color: $green !important;
                transition: color 300ms ease 300ms;

                &:after {
                    width: 100%;
                    transition: width 300ms ease 300ms;
                }
            }

            &.h2 {
                margin-top: 0;
                margin-bottom: 0;
            }

            &:hover {
                color: $green !important;
            }
        }
    }
}

.tab-content {
    &.hideOverflowingTabContent {
        overflow: hidden;
    }

    > .tab-pane {
        animation-duration: 300ms;
        animation-fill-mode: forwards;

        .btn + .btn {
            margin-left: 30px;

            @include media-breakpoint-down(xs) {
                margin-left: 0;
                margin-top: 15px;
                display: table;
            }
        }

        .contentElementContainer:not(.downloadRouteMapContainer) {
            padding-right: 0;
            padding-left: 0;

            + .contentElementContainer {
                margin-top: 42px;

                @include media-breakpoint-down(sm) {
                    margin-top: 38px;
                }
            }
        }

        .downloadRouteMapContainer {
            padding-top: 92px;
            padding-bottom: 50px;
        }

        &.contactTabContentElementContainer {
            padding-top: 92px;
            padding-bottom: 93px;

            > .contentElementContainer {
                padding-right: 15px;
                padding-left: 15px;

                > .accordion {
                    max-width: 800px;
                    margin-top: 30px;

                    .card-body > .contentElementContainer {
                        margin-top: 27px;
                    }
                }
            }
        }

        > .row {
            > .col {
                &:first-child:not(:only-child) {
                    max-width: 33%;
                    flex-basis: 33%;
                    padding-right: 70px;
                }

                h2, .h2 {
                    margin-top: 0;
                    margin-bottom: 8px;
                }

                .h2 + p,
                h2 + p {
                    margin-bottom: 27px;
                }
            }
        }
    }
}

.bg-green-light {
    .nav-link {
        &:hover {
            color: $green-dark !important;
        }
    }
}

.bg-gray {
    .nav-link {
        color: $green !important;

        &:hover {
            color: $green-dark !important;
        }
    }
}

.bg-gray .nav-tabs {
    .nav-link {
        color: $body-color !important;
    }
}

.bg-green {
    .nav-tabs .nav-item .nav-link {
        text-decoration: none;
        color: #fff !important;

        &.active {

            &:after {
                content: " ";
                position: absolute;
                right: 15px;
                bottom: 0;
                border-top: 1px solid #fff;
            }
        }

        &:hover {
            color: $white-link-hover-color !important;
        }
    }
}

.productNavTabs {
    .nav-tabs {
        margin-top: -12px;
    }

    @include media-breakpoint-down(sm) {
        .col {
            h2, .h2 {
                margin-top: 0;
                margin-bottom: 10px;
            }

            .h2 + p,
            h2 + p {
                margin-bottom: 28px;
            }
        }
    }
}

.stageContainer {
    position: relative;
    overflow: hidden;
    margin-right: auto;
    margin-left: auto;
    max-height: 640px;

    &:not(.pyramidStage) {
        background-color: $gray-200;
    }

    &:before {
        display: block;
        content: '';
        padding-top: percentage(640 / 1600);
    }

    &.hasThumbnailNavigation {
        &, .swiper-container {
            overflow: visible;
        }

        .swiper-pagination {
            display: none;
        }

        .stage {
            &:after {
                display: none;
            }

            .swiper-container {
                .swiper-slide {
                    &:after {
                        display: block;
                        opacity: 1;
                        transition: opacity 750ms linear;
                        content: '';
                        @include position-offset-zero;
                        background-color: rgba(0, 0, 0, 0.15);
                    }
                    &.hideShadow {
                        &:after {
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }

    &.big {
        height: calc(100vh - 146px);
        max-height: none;
        min-height: 550px;

        .stage {
            .swiper-slide {
                width: 100%;

                &.centeredLeft {
                    .textContainer {
                        justify-content: flex-start;

                        @include media-breakpoint-down(md) {
                            justify-content: center;
                        }
                    }
                }

                &.centeredRight {
                    .textContainer {
                        justify-content: flex-end;

                        @include media-breakpoint-down(md) {
                            justify-content: center;
                        }
                    }
                }

                &.centerTop {
                    align-items: flex-start;

                    .textContainer {
                        position: relative;
                        top: 62px;
                        left: 0;
                        align-self: auto;
                    }
                }

                &.centerBottom {
                    align-items: flex-end;

                    .textContainer {
                        position: relative;
                        top: auto;
                        left: 0;
                        bottom: 116px;
                        align-self: auto;

                        @include media-breakpoint-down(sm) {
                            bottom: 78px;
                        }
                    }
                }

                &.textAlignLeft {
                    .text {
                        max-width: 100%;
                        text-align: left;
                    }
                }

                &.bigFont {
                    .textContainer {
                        .headline {
                            font-size: 60px;
                            line-height: 66px;

                            @include media-breakpoint-down(xs) {
                                font-size: 34px;
                                line-height: 42px;
                            }
                        }
                    }
                }

                &.darkTextShadow {
                    .textContainer {
                        .text {
                            &:after {
                                box-shadow: 100vw 100vh 100px rgba(0, 0, 0, 0.35);

                                @include onlyInIE {
                                    box-shadow: 100vw 100vh 100px rgba(0, 0, 0, 0.3);
                                }

                                @include onlyInGC {
                                    box-shadow: 100vw 100vh 100px rgba(0, 0, 0, 0.3);
                                }
                            }

                            .textShadow {
                                &:after {
                                    box-shadow: 100vw 100vh 100px rgba(0, 0, 0, 0.4);

                                    @include onlyInIE {
                                        box-shadow: 100vw 100vh 100px rgba(0, 0, 0, 0.32);
                                    }

                                    @include onlyInGC {
                                        box-shadow: 100vw 100vh 100px rgba(0, 0, 0, 0.32);
                                    }
                                }
                            }
                        }
                    }
                }

                &.brightTextShadow {
                    .textContainer {
                        .text {
                            &:after {
                                box-shadow: none;

                                @include onlyInIE {
                                    box-shadow: none;
                                }

                                @include onlyInGC {
                                    box-shadow: none;
                                }
                            }

                            .textShadow {
                                &:after {
                                    box-shadow: 100vw 100vh 100px rgba(0, 0, 0, 0.2);

                                    @include onlyInIE {
                                        box-shadow: 100vw 100vh 100px rgba(0, 0, 0, 0.12);
                                    }

                                    @include onlyInGC {
                                        box-shadow: 100vw 100vh 100px rgba(0, 0, 0, 0.12);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .text {
                max-width: none;
                text-align: center;
            }
        }

        @include media-breakpoint-down (md) {
            height: calc(100vh - 110px);
        }
    }


    @include media-breakpoint-down(md) {
        max-height: none;

        &:before {
            padding-top: percentage(550 / 736);
        }
    }

    @include media-breakpoint-down(sm) {
        min-height: 394px;

        &.stageProductContainerOuter {
            &:before {
                padding-top: 0;
            }
        }

        &.hasThumbnailNavigation {
            .textContainer {

                .text .btn {
                    margin-top: 0;
                }
            }

            .swiper-pagination {
                display: block;
                bottom: 10px !important;
            }
        }
    }

    &.pyramidStage {
        &:before {
            @media (max-width: 575px) {
                padding-top: 0;
            }
        }
    }

    .playButton {
        width: 76px;
        height: 76px;
        border-radius: 50%;
        cursor: pointer;
        background: none;
        border: none;
        padding: 0;

        .icon {
            font-size: 76px;
            color: #fff;
        }

        &:hover, &:focus {
            box-shadow: 0px 0px 21.2px 18.8px rgba(#fff, 0.2);
            background-color: rgba(#fff, 0.2);

            .icon {
                color: $green;
            }
        }
    }

    &:not(.big) {
        .stage.isVideo:not(.isAutoplay) .text {
            padding-right: 114px;
        }

        .playButtonContainer {
            position: static !important;
        }

        .playButton {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(38px, -50%);
            z-index: 2;
        }

        @include media-breakpoint-down(sm) {
            .stage.isVideo {
                .textContainer {
                    display: flex;
                    align-items: flex-end;
                    min-height: 50%;
                    left: 0;
                    top: auto;
                    bottom: 0;
                    transform: none;
                    padding-top: 60px;
                    padding-bottom: 35px;

                    .text {
                        position: static;
                        padding-right: 0;
                    }
                }

                .playButton {
                    top: 0;
                    right: auto;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    &.big {
        .playButtonContainer {
            margin-top: 30px;
        }
    }
}

.stage {
    @include position-offset-zero;

    &:not(.pyramidStage):not(.noShadowStage):not(.dontDarkenStage):after {
        display: block;
        opacity: 1;
        transition: opacity 300ms linear;
        content: '';
        @include position-offset-zero;
        background-color: rgba(0, 0, 0, 0.2);
    }

    &.noShadowStage {
        .text {
            &:after {
                box-shadow: none !important;
            }
        }
    }

    .img {
        @include position-offset-zero;
        background-size: cover;
        background-position: 50% 50%;
    }

    .video {
        @include position-offset-zero;
    }

    .textContainerPyramid {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-100%, -50%);
        z-index: 7;
        width: 50%;
        max-width: 550px;
        padding-right: 15px;
        padding-left: 15px;

        @include media-breakpoint-down(xs) {
            width: 100%;
        }
    }

    .textContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 7;
        width: 100%;
        max-width: 1100px;
        padding-right: 15px;
        padding-left: 15px;

        .text {
            position: relative;
            max-width: 50%;
            float: left;

            @include media-breakpoint-down(sm) {
                max-width: none;
            }

            .textShadow {

                p {
                    max-width: none;
                }

                &:after {
                    content: "";
                    position: absolute;
                    top: -100vh;
                    left: -100vw;
                    width: 125%;
                    height: 190%;
                    min-height: 17vh;
                    // Weil das ja klar ist...
                    transform: translate(percentage(-25/250), percentage(-90/380));
                    box-shadow: 100vw 100vh 100px rgba(0, 0, 0, 0.3);
                    border-radius: 50%;
                    z-index: -1;

                    @include onlyInIE {
                        box-shadow: 100vw 100vh 100px rgba(0, 0, 0, 0.22);
                    }

                    @include onlyInGC {
                        box-shadow: 100vw 100vh 100px rgba(0, 0, 0, 0.22);
                    }
                }
            }

            &:after {
                content: "";
                position: absolute;
                top: -100vh;
                left: -100vw;
                width: 125%;
                height: 200%;
                transform: translate(percentage(-25/250), percentage(-100/400));
                box-shadow: 100vw 100vh 100px rgba(0, 0, 0, 0.25);
                border-radius: 50%;
                z-index: -1;

                @include onlyInIE {
                    box-shadow: 100vw 100vh 100px rgba(0, 0, 0, 0.2);
                }

                @include onlyInGC {
                    box-shadow: 100vw 100vh 100px rgba(0, 0, 0, 0.2);
                }
            }

            > * {
                position: relative;
                z-index: 2;
            }

            + .text {
                margin-top: 33px;
            }

            .btn {
                margin-top: 25px;
            }
        }
    }

    .scrollDownLinkContainer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20%;
        max-height: 150px;
        background: linear-gradient(transparent, rgba(255, 255, 255, 0.25) 70%);


        &.text-white {
            background: -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.25) 70%);
            background: -o-linear-gradient(transparent, rgba(0, 0, 0, 0.25) 70%);
            background: linear-gradient(transparent, rgba(0, 0, 0, 0.25) 70%);

            a {
                color: #fff;
            }
        }
    }

    &.pyramidStage {
        @media (max-width: 575px) {
            position: relative;
        }

        .textContainerPyramid.pyramidStage {
            @media (max-width: 575px) {
                position: relative;
                left: 0;
                top: auto;
                bottom: 0;
                transform: none;
                padding-top: 20px;
                padding-bottom: 20px;

                .text {
                    max-width: none;
                }
            }
        }

        .scrollDownLinkContainer {
            @media (max-width: 575px) {
                position: relative;
            }
        }
    }

    .swiper-container {
        height: 100%;

        .swiper-slide {
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 2;
            transition-timing-function: ease-in-out;
        }

        .swiper-slide-active {
            z-index: 1;
        }

        .textContainer {
            position: static;
            transform: none;
            position: static;
            transform: none;
            display: flex;
            justify-content: center;
            align-self: center;
            max-width: 70%;

            @include media-breakpoint-down(sm) {
                max-width: 75%;
            }

            @media (max-width: 450px) {
                max-width: none;
            }

            .text {
                text-align: center;
            }

            // TODO: Test für Vorgehensweise mobil Stage mit viel Text
            @include media-breakpoint-down(xs) {
                .headline {
                    line-height: 36px;
                    font-size: 34px;
                }

                .introText {
                    font-size: 20px;
                    line-height: 26px;

                    @include media-breakpoint-down (sm) {
                        font-size: 18px;
                    }
                }
            }
        }

        .text {
            opacity: 0;
            transform: translateY(50px);
            transition: opacity 750ms, transform 750ms;

            &.animateIn {
                opacity: 1;
                transform: translateY(0px);
            }
        }
    }

    .swiper-container-fade {
        background-color: #fff;
    }

    .subheadline {
        font-size: 20px !important;
        line-height: 30px !important;
        font-weight: 700 !important;
        margin-bottom: 24px !important;
    }

    .headline {
        margin-bottom: 0;
    }

    .introText {
        font-size: 26px;
        line-height: 30px;
        font-weight: 300;
        margin-top: 16px;
        margin-bottom: 30px;
    }

    .scrollDownLink {
        position: absolute;
        bottom: 7px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 14px;
        line-height: 22px;
        z-index: 10;

        @include media-breakpoint-down(xs) {
            bottom: 0;
        }

        &.pyramidStage {
            @media (max-width: 575px) {
                position: relative;
            }
        }

        .icon {
            position: relative;
            top: -1px;
            z-index: 2;
            padding-right: 10px;
            font-size: 8px;
        }

        .text {
            position: relative;
            z-index: 2;
        }
    }

    @include media-breakpoint-down(sm) {
        .textContainer:not(.pyramidStage) {
            &.stageProductContainerInner {
                position: relative;
                bottom: 0;
                left: 0;
                top: auto;
                transform: none;
                padding-top: 15px;
                padding-bottom: 30px;
                background-color: #b0b2b6;

                .text {
                    margin-left: 0;
                }
            }
        }

        &.stageProductContainer {
            position: relative;

            &:after {
                top: 0;
                background: none;
            }

            .img {
                position: relative;

                img {
                    position: relative;
                }
            }
        }

        .subheadline {
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 4px;
        }

        .headline {
            font-size: 34px;
            line-height: 42px;
        }
    }

    @include media-breakpoint-down(xs) {
        &:not(.pyramidStage):after {
            display: block;
            content: '';
            @include position-offset-zero;
            top: 50%;
            background: linear-gradient(to top, rgba(#000, 0.25), transparent);
        }

        &.stageProductContainer {
            &:after {
                top: 0;
                background: none;
            }
        }

        .textContainer {
            left: 0;
            top: auto;
            bottom: 0;
            transform: none;
            padding-top: 20px;
            padding-bottom: 35px;
        }
    }

    &.stageTradeshows {
        .textContainer {
            display: flex;

            @include media-breakpoint-down(sm) {
                flex-direction: column;
            }

            @include media-breakpoint-down(xs) {
                padding-bottom: 35px;
            }

            .text {
                max-width: 100%;
                display: flex;

                @include media-breakpoint-down(md) {
                    flex-direction: column;
                }

                .textInner {
                    margin-right: 30px;

                    @include media-breakpoint-down(md) {
                        margin-right: 0;
                    }
                }
            }

            .logoContainer {
                margin-right: 30px;
                align-self: center;
                max-width: 150px;
                flex-shrink: 0;

                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }

            .ctaLink {
                margin-left: auto;
                align-self: center;

                @include media-breakpoint-down(md) {
                    align-self: auto;
                    margin-left: 0;
                    margin-top: 25px;
                }

                .btn {
                    margin-top: 0;
                }
            }
        }
    }
}


.stageProductContainer {
    &:after {
        display: none !important;
    }

    .backgroundImage {
        @include position-offset-zero;
        background-image: url('/fileadmin/images/Product-overview-hero-background.jpg');
        background-size: cover;
        background-position: center top;

        &.flip {
            transform: scaleX(-1);
        }

        @include media-breakpoint-down(sm) {
            background-image: url('/fileadmin/images/Product-overview-hero-background-mobile.jpg');
        }
    }

    .columnsContainer {
        position: relative;
        z-index: 1;
        max-width: 1100px;
        height: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin: 0 auto;

        .row {
            height: 100%;

            .text {
                .btn {
                    margin-top: 13px;
                }
            }
        }
    }

    &.textRight {
        .textContainer {
            justify-content: right;
        }

        .text {
            padding-left: 15px;
        }
    }

    &.textLeft {
        .text {
            padding-right: 15px;
        }
    }

    .textContainer {
        display: flex;
    }

    .text {
        h2 {
            margin-top: 0;
        }
    }

    .imageColumn {
        @include onlyInIE {
            @include media-breakpoint-up(md) {
                .img-fluid {
                    width: 100%;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .textColumn {
            margin-top: 30px;
            margin-bottom: 65px;
        }

        .imageColumn {
            margin-top: 30px;
        }
    }
}

.landingpageStage {
    .textContainer {
        @include media-breakpoint-down(xs) {
            padding-bottom: 45px;
        }
    }

    .scrollDownLink {
        @media (max-width: 400px) {

            &:before {
                box-shadow: none;
            }
        }
    }
}

#productPyramid {
    g {
        pointer-events: none;
    }

    a path {
        pointer-events: all;
    }
}

.pyramidStage {
    .pyramidStage:not(.pyramidStageWithBackground) {
        .stagePyramidElement {
            .pyramidContainer {
                svg {
                    @include media-breakpoint-down(sm) {
                        padding-left: 60px;
                    }
                    @include media-breakpoint-down(xs) {
                        padding-left: 0;
                    }
                }
            }
        }
    }
}

.pyramidStage{
    .stagePyramidElement {
        position: absolute;
        z-index: 5;
        max-width: 1100px;
        left: 50%;
        width: 100%;
        transform: translateX(-50%);
        height: 100%;
        padding-right: 15px;

        @media (max-width: 1500px) {
            padding-top: 30px;
            padding-bottom: 60px;
        }

        @media (max-width: 991px) {
            padding-top: 0;
            padding-bottom: 0;
        }

        @media (max-width: 830px) {
            padding-top: 30px;
            padding-bottom: 55px;
        }

        @media (max-width: 575px) {
            padding-left: 15px;
            padding-bottom: 30px;
            position: relative;
            transform: none;
            left: 0;
        }

        .pyramidContainer {
            height: 100%;

            &.selected {
                #productPyramid {
                    g {
                        &.active {
                            .colorfillContainer {
                                path {
                                    fill: rgba(61,182,15,0.6) !important;
                                }
                            }
                        }
                    }
                }
            }

            @media (max-width: 575px) {
                height: 0;
                width: 100%;
                position: relative;
                display: inline-block;
                padding-bottom: 100%;
            }

            svg {
                margin-left: auto;
                display: block;
                max-height: 100%;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                max-width: 374px;

                @media (max-width: 700px) {
                    @include onlyInIE {
                        max-width: 50vw;
                    }
                }

                @media (max-width: 575px) {
                    padding-left: 0;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }
}

.stageContainer.stageWithLightboxContainer {
    background-color: $gray-100 !important;

    @include media-breakpoint-down(sm) {
        &:before {
            padding-top: 0 !important;
        }
    }

    .stageWithLightbox {
        &:after {
            background: none !important;
        }

        .text {
            &:after {
                box-shadow: none;

                @include onlyInGC {
                    box-shadow: none;
                }

                @include onlyInIE {
                    box-shadow: none;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            position: relative;
            display: flex;
            flex-direction: column;
        }

        .textContainer {
            @include media-breakpoint-down(sm) {
                position: relative;
                top: auto;
                transform: none;
                left: auto;
                order: 2;
                margin-bottom: 20px;
                padding-top: 20px;
                padding-bottom: 31px;
            }

            .text {
                margin-top: -40px;
                padding-right: 15px;

                @include media-breakpoint-down(sm) {
                    margin-top: 0;
                    padding-right: 0;
                }
            }
        }

        .scrollDownLinkContainer {
            @include media-breakpoint-down(sm) {
                position: relative;
                order: 3;

                .scrollDownLink {
                    bottom: 0;
                }
            }
        }
    }
}

.lightboxThumbContainer {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(100%, -50%);
    z-index: 7;
    width: 100%;
    max-width: 550px;
    padding-right: 15px;
    padding-left: 15px;
    display: flex;
    align-items: center;

    @include media-breakpoint-down(sm) {
        flex-direction: column;
    }

    .pictureInStageContainer {
        width: calc(100% - 120px)   ;
        max-height: 350px;
        display: flex;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-down(sm) {
            position: static !important;
            margin-top: 50px;
            margin-bottom: 15px;
        }

        img {
            max-height: 330px;
            @include media-breakpoint-down(sm) {
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .row {
        flex-direction: column;
        max-width: 75px;
        margin-left: auto;
        flex-wrap: nowrap;

        @media (max-width: 1160px) {
            margin-right: 15px;
        }

        @include media-breakpoint-down(sm) {
            flex-direction: row;
            max-width: none;
            justify-content: center;
            margin-top: 35px;
            margin-bottom: 25px;
            margin-right: auto;
        }

        a {
            overflow: hidden;

            &:hover,
            &:focus {
                img {
                    border-color: $green;
                    border-width: 2px;
                }
            }
            &:not(:last-child) {
                padding-bottom: 10px;
            }

            @include media-breakpoint-down(sm) {
                padding-bottom: 0 !important;

                &:not(:last-child) {
                    padding-right: 10px;
                }
            }
        }

        img {
            margin-left: auto;
            border-color: #cdcdcd;
            border-width: 2px;
            border-style: solid;
        }
    }

    @media (max-width: 1160px) {
        max-width: 50%;
    }

    @include media-breakpoint-down(sm) {
        position: relative;
        top: 0;
        right: auto;
        transform: translate(50%, 0%);
        max-width: 100%;
        transform: none;
    }
}

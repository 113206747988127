#rootlineContainerWrap {
    height: 72px;
    flex-shrink: 0;
    position: relative;

    @include media-breakpoint-down(md) {
        height: 59px;
        display: none;
    }
}

#rootlineContainer {
    position: relative;
    z-index: 13;
    background-color: #fff;
    border-bottom: 1px solid $gray-200;

    &.affix {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 100;

        @include media-breakpoint-down(md) {
            top: 70px;
        }
    }

    &.noAffix {
        position: relative;
    }
}

#rootline {
    display: flex;
    // max-width: 1470px;
    max-width: 1100px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    .nav {
        flex-wrap: nowrap;
        max-width: 100%;
    }

    .dropdown {
        position: static;

        .rootlineTopLevel {
            padding: 22px 21px 23px 26px;
            font-weight: 700;
            position: relative;
            margin-left: 43px;
            // bottom: 5px;
        }

        .nav-link {
            white-space: nowrap;
        }

        + .dropdown {
            overflow: hidden;

            .nav-link {
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

    .dropdown-toggle:after {
        display: none;
    }

    .dropdown {
        .btn:not(.closeBtn) {
            padding: 0;
            // padding: 24px 8px 17px 20px;
            margin-left: -16px;
            font-size: 16px;
            position: relative;
            display: flex;
            border: none;
            transition: none;

            &:hover{
                .menuIcon {
                    span {
                        background-color: $green-dark;
                    }
                }
                .cross {
                    &:before,
                    &:after {
                        background-color: $green-dark;
                    }
                }
            }

            .menuIcon {
                @extend %menuIcon;
                position: absolute;
                top: 25px;
                left: 17px;

                span {
                    background-color: $green;
                }
            }

            .cross {
                display: block;
                position: absolute;
                top: 23px;
                left: 17px;
                width: 22px;
                height: 22px;
                transform: rotate(45deg);

                @include media-breakpoint-down(sm) {
                    top: 11px;
                }

                &:before, &:after {
                    display: block;
                    content: '';
                    position: absolute;
                    background-color: $green;
                }

                &:before {
                    position: absolute;
                    top: 0;
                    left: 10px;
                    width: 2px;
                    height: 0;
                    transition:
                        transform .15s ease-in-out,
                        width .15s ease-in-out,
                        height .15s ease-in-out,
                        background-color 0.3s ease
                    ;
                }

                &:after {
                    position: absolute;
                    top: 10px;
                    left: 0;
                    width: 0;
                    height: 2px;
                    transition:
                        transform .15s ease-in-out .15s,
                        width .15s ease-in-out .15s,
                        height .15s ease-in-out .15s,
                        background-color 0.3s ease
                    ;
                }
            }

            &[aria-expanded="true"] {
                .menuIcon span {
                    width: 0;
                    transition: width 150ms ease;
                }

                .cross {
                    &:before {
                        height: 100%;
                        transition:
                            transform .15s ease-in-out,
                            width .15s ease-in-out,
                            height .15s ease-in-out,
                            background-color 0.3s ease
                        ;
                    }

                    &:after {
                        width: 100%;
                        transition:
                            transform .15s ease-in-out .15s,
                            width .15s ease-in-out .15s,
                            height .15s ease-in-out .15s,
                            background-color 0.3s ease
                        ;
                    }
                }
            }

            // &[aria-expanded="true"] {
            //     .menuIcon {
            //         @extend %menuIcon-active;
            //     }
            // }
        }
    }

    > .nav {
        > .nav-item {

            &:nth-of-type(2) {
                @include onlyInIE {
                    flex-shrink: 0;
                }
            }

            > .nav-link {
                padding: 22px 21px 23px;
                font-weight: 700;
                position: relative;

                // @include onlyInGC {
                //     padding-top: 22px;
                //     padding-bottom: 21px;
                // }
                //
                // @include onlyInEdge {
                //     padding-top: 22px;
                //     padding-bottom: 21px;
                // }
                //
                // @include onlyInIE {
                //     padding-top: 22px;
                //     padding-bottom: 21px;
                // }

                &.dropdown-toggle {
                    position: relative;
                    padding-right: 56px;

                    &:before, &:after {
                        display: block;
                        content: '';
                        position: absolute;
                        height: 2px;
                        background-color: currentColor;
                        transition: transform 300ms linear, top 300ms linear;
                    }

                    &:before {
                        right: 29px;
                        width: 12px;
                        transform-origin: left center;
                    }

                    &:after {
                        right: 21px;
                        width: 12px;
                        transform-origin: right center;
                        border: none;
                        margin-left: 0;
                    }

                    &:not([aria-expanded="true"]) {
                        &:before, &:after {
                            top: 32px;
                        }

                        &:before {
                            transform: rotate(30deg);
                        }

                        &:after {
                            transform: rotate(-30deg);
                        }
                    }

                    &[aria-expanded="true"] {
                        &:before, &:after {
                            top: 35px;
                        }

                        &:before {
                            transform: rotate(-30deg);
                        }

                        &:after {
                            transform: rotate(30deg);
                        }
                    }

                    // &:after {
                    //     width: auto;
                    //     height: auto;
                    //     border: none;
                    //     @extend %iconfont-murrelektronik, %icon-chevron-down:before;
                    //     font-size: 8px;
                    //     margin-left: 14px;
                    //     transform: rotateX(0);
                    //     transition: transform 300ms;
                    // }
                    //
                    // &[aria-expanded="true"]:after {
                    //     transform: rotateX(180deg);
                    // }
                }
            }

            + .nav-item {
                display: flex;

                &:before {
                    position: relative;
                    top: 25px;
                    left: 0;
                    @extend %iconfont-murrelektronik, %icon-chevron-right:before;
                    color: $green;
                    font-size: 19px;
                }
            }
        }
    }

    .dropdown-menu {
        width: 100%;
        border: none;
        margin-top: 0;
        border-top: 1px solid $gray-200;
        padding-top: 38px;
        padding-bottom: 39px;
        transform: translate3d(0, 70px, 0) !important;

        .nav {
            display: block;
        }



        > .nav {
            // 1440 + 16px + 16px padding of links
            // max-width: 1472px;
            max-width: 1100px;
            margin: 0 auto;
            position: relative;

            .closeBtnLi {
                .closeBtn {
                    position: absolute;
                    right: 0;
                    top: -8px;
                    // top: -15px;

                    .icon {
                        font-size: 20px;
                    }
                }
            }

            &.level3 {
                padding-left: 0;
            }

            .nav {
                padding-left: 10px;
            }

            &.level3,
            .nav {
                margin-bottom: 3px;

                .nav-item {
                    .nav-link {
                        font-weight: 300;
                        padding-top: 5px;
                        padding-bottom: 4px;
                        line-height: 24px;
                        margin-top: 0;
                    }
                }
            }

            > .nav-item {
                overflow: hidden;
                page-break-inside: avoid;
                break-inside: avoid;

                > .nav-link {
                    font-weight: 700;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    margin-top: -8px;
                    overflow: hidden;
                }

                &.hasChildren > .nav-link {
                    padding-bottom: 5px;
                }
            }
        }

        .nav-link {
            color: $body-color;

            &:hover, &:focus {
                color: $green;
            }
        }

        .nav-item.active > .nav-link {
            color: $green;
        }
    }

    .backdrop {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 1;
        pointer-events: none;
        background-color: rgba(#000, .2);
        opacity: 0;
        transition: opacity 300ms;
    }

    .show > .backdrop {
        opacity: 1;
    }

    .backLink {
        padding: 15px 30px 15px;
        margin-left: -15px;
        font-weight: 700;

        .icon {
            position: relative;
            top: 3px;
            font-size: 19px;
            padding-right: 9px;
        }
    }

    @include media-breakpoint-down(md) {
        .backLink {
            margin-left: -30px;
        }
    }

    @include media-breakpoint-down(sm) {
        .backLink {
            padding-right: 15px;
            // padding-left: 15px;
        }
    }
}

@font-face {
  font-family: 'Linearicons';
  font-display: swap;
  src:
    url('../fonts/Linearicons/Linearicons.ttf?iclkdd') format('truetype'),
    url('../fonts/Linearicons/Linearicons.woff?iclkdd') format('woff'),
    url('../fonts/Linearicons/Linearicons.svg?iclkdd#Linearicons') format('svg');
  font-weight: normal;
  font-style: normal;
}

%linearicons, [class^="lnr-"], [class*=" lnr-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Linearicons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%lnr-home:before, .lnr-home:before {
  content: "\e600";
}
%lnr-home2:before, .lnr-home2:before {
  content: "\e601";
}
%lnr-home3:before, .lnr-home3:before {
  content: "\e602";
}
%lnr-home4:before, .lnr-home4:before {
  content: "\e603";
}
%lnr-home5:before, .lnr-home5:before {
  content: "\e604";
}
%lnr-home6:before, .lnr-home6:before {
  content: "\e605";
}
%lnr-bathtub:before, .lnr-bathtub:before {
  content: "\e606";
}
%lnr-toothbrush:before, .lnr-toothbrush:before {
  content: "\e607";
}
%lnr-bed:before, .lnr-bed:before {
  content: "\e608";
}
%lnr-couch:before, .lnr-couch:before {
  content: "\e609";
}
%lnr-chair:before, .lnr-chair:before {
  content: "\e60a";
}
%lnr-city:before, .lnr-city:before {
  content: "\e60b";
}
%lnr-apartment:before, .lnr-apartment:before {
  content: "\e60c";
}
%lnr-pencil:before, .lnr-pencil:before {
  content: "\e60d";
}
%lnr-pencil2:before, .lnr-pencil2:before {
  content: "\e60e";
}
%lnr-pen:before, .lnr-pen:before {
  content: "\e60f";
}
%lnr-pencil3:before, .lnr-pencil3:before {
  content: "\e610";
}
%lnr-eraser:before, .lnr-eraser:before {
  content: "\e611";
}
%lnr-pencil4:before, .lnr-pencil4:before {
  content: "\e612";
}
%lnr-pencil5:before, .lnr-pencil5:before {
  content: "\e613";
}
%lnr-feather:before, .lnr-feather:before {
  content: "\e614";
}
%lnr-feather2:before, .lnr-feather2:before {
  content: "\e615";
}
%lnr-feather3:before, .lnr-feather3:before {
  content: "\e616";
}
%lnr-pen2:before, .lnr-pen2:before {
  content: "\e617";
}
%lnr-pen-add:before, .lnr-pen-add:before {
  content: "\e618";
}
%lnr-pen-remove:before, .lnr-pen-remove:before {
  content: "\e619";
}
%lnr-vector:before, .lnr-vector:before {
  content: "\e61a";
}
%lnr-pen3:before, .lnr-pen3:before {
  content: "\e61b";
}
%lnr-blog:before, .lnr-blog:before {
  content: "\e61c";
}
%lnr-brush:before, .lnr-brush:before {
  content: "\e61d";
}
%lnr-brush2:before, .lnr-brush2:before {
  content: "\e61e";
}
%lnr-spray:before, .lnr-spray:before {
  content: "\e61f";
}
%lnr-paint-roller:before, .lnr-paint-roller:before {
  content: "\e620";
}
%lnr-stamp:before, .lnr-stamp:before {
  content: "\e621";
}
%lnr-tape:before, .lnr-tape:before {
  content: "\e622";
}
%lnr-desk-tape:before, .lnr-desk-tape:before {
  content: "\e623";
}
%lnr-texture:before, .lnr-texture:before {
  content: "\e624";
}
%lnr-eye-dropper:before, .lnr-eye-dropper:before {
  content: "\e625";
}
%lnr-palette:before, .lnr-palette:before {
  content: "\e626";
}
%lnr-color-sampler:before, .lnr-color-sampler:before {
  content: "\e627";
}
%lnr-bucket:before, .lnr-bucket:before {
  content: "\e628";
}
%lnr-gradient:before, .lnr-gradient:before {
  content: "\e629";
}
%lnr-gradient2:before, .lnr-gradient2:before {
  content: "\e62a";
}
%lnr-magic-wand:before, .lnr-magic-wand:before {
  content: "\e62b";
}
%lnr-magnet:before, .lnr-magnet:before {
  content: "\e62c";
}
%lnr-pencil-ruler:before, .lnr-pencil-ruler:before {
  content: "\e62d";
}
%lnr-pencil-ruler2:before, .lnr-pencil-ruler2:before {
  content: "\e62e";
}
%lnr-compass:before, .lnr-compass:before {
  content: "\e62f";
}
%lnr-aim:before, .lnr-aim:before {
  content: "\e630";
}
%lnr-gun:before, .lnr-gun:before {
  content: "\e631";
}
%lnr-bottle:before, .lnr-bottle:before {
  content: "\e632";
}
%lnr-drop:before, .lnr-drop:before {
  content: "\e633";
}
%lnr-drop-crossed:before, .lnr-drop-crossed:before {
  content: "\e634";
}
%lnr-drop2:before, .lnr-drop2:before {
  content: "\e635";
}
%lnr-snow:before, .lnr-snow:before {
  content: "\e636";
}
%lnr-snow2:before, .lnr-snow2:before {
  content: "\e637";
}
%lnr-fire:before, .lnr-fire:before {
  content: "\e638";
}
%lnr-lighter:before, .lnr-lighter:before {
  content: "\e639";
}
%lnr-knife:before, .lnr-knife:before {
  content: "\e63a";
}
%lnr-dagger:before, .lnr-dagger:before {
  content: "\e63b";
}
%lnr-tissue:before, .lnr-tissue:before {
  content: "\e63c";
}
%lnr-toilet-paper:before, .lnr-toilet-paper:before {
  content: "\e63d";
}
%lnr-poop:before, .lnr-poop:before {
  content: "\e63e";
}
%lnr-umbrella:before, .lnr-umbrella:before {
  content: "\e63f";
}
%lnr-umbrella2:before, .lnr-umbrella2:before {
  content: "\e640";
}
%lnr-rain:before, .lnr-rain:before {
  content: "\e641";
}
%lnr-tornado:before, .lnr-tornado:before {
  content: "\e642";
}
%lnr-wind:before, .lnr-wind:before {
  content: "\e643";
}
%lnr-fan:before, .lnr-fan:before {
  content: "\e644";
}
%lnr-contrast:before, .lnr-contrast:before {
  content: "\e645";
}
%lnr-sun-small:before, .lnr-sun-small:before {
  content: "\e646";
}
%lnr-sun:before, .lnr-sun:before {
  content: "\e647";
}
%lnr-sun2:before, .lnr-sun2:before {
  content: "\e648";
}
%lnr-moon:before, .lnr-moon:before {
  content: "\e649";
}
%lnr-cloud:before, .lnr-cloud:before {
  content: "\e64a";
}
%lnr-cloud-upload:before, .lnr-cloud-upload:before {
  content: "\e64b";
}
%lnr-cloud-download:before, .lnr-cloud-download:before {
  content: "\e64c";
}
%lnr-cloud-rain:before, .lnr-cloud-rain:before {
  content: "\e64d";
}
%lnr-cloud-hailstones:before, .lnr-cloud-hailstones:before {
  content: "\e64e";
}
%lnr-cloud-snow:before, .lnr-cloud-snow:before {
  content: "\e64f";
}
%lnr-cloud-windy:before, .lnr-cloud-windy:before {
  content: "\e650";
}
%lnr-sun-wind:before, .lnr-sun-wind:before {
  content: "\e651";
}
%lnr-cloud-fog:before, .lnr-cloud-fog:before {
  content: "\e652";
}
%lnr-cloud-sun:before, .lnr-cloud-sun:before {
  content: "\e653";
}
%lnr-cloud-lightning:before, .lnr-cloud-lightning:before {
  content: "\e654";
}
%lnr-cloud-sync:before, .lnr-cloud-sync:before {
  content: "\e655";
}
%lnr-cloud-lock:before, .lnr-cloud-lock:before {
  content: "\e656";
}
%lnr-cloud-gear:before, .lnr-cloud-gear:before {
  content: "\e657";
}
%lnr-cloud-alert:before, .lnr-cloud-alert:before {
  content: "\e658";
}
%lnr-cloud-check:before, .lnr-cloud-check:before {
  content: "\e659";
}
%lnr-cloud-cross:before, .lnr-cloud-cross:before {
  content: "\e65a";
}
%lnr-cloud-crossed:before, .lnr-cloud-crossed:before {
  content: "\e65b";
}
%lnr-cloud-database:before, .lnr-cloud-database:before {
  content: "\e65c";
}
%lnr-database:before, .lnr-database:before {
  content: "\e65d";
}
%lnr-database-add:before, .lnr-database-add:before {
  content: "\e65e";
}
%lnr-database-remove:before, .lnr-database-remove:before {
  content: "\e65f";
}
%lnr-database-lock:before, .lnr-database-lock:before {
  content: "\e660";
}
%lnr-database-refresh:before, .lnr-database-refresh:before {
  content: "\e661";
}
%lnr-database-check:before, .lnr-database-check:before {
  content: "\e662";
}
%lnr-database-history:before, .lnr-database-history:before {
  content: "\e663";
}
%lnr-database-upload:before, .lnr-database-upload:before {
  content: "\e664";
}
%lnr-database-download:before, .lnr-database-download:before {
  content: "\e665";
}
%lnr-server:before, .lnr-server:before {
  content: "\e666";
}
%lnr-shield:before, .lnr-shield:before {
  content: "\e667";
}
%lnr-shield-check:before, .lnr-shield-check:before {
  content: "\e668";
}
%lnr-shield-alert:before, .lnr-shield-alert:before {
  content: "\e669";
}
%lnr-shield-cross:before, .lnr-shield-cross:before {
  content: "\e66a";
}
%lnr-lock:before, .lnr-lock:before {
  content: "\e66b";
}
%lnr-rotation-lock:before, .lnr-rotation-lock:before {
  content: "\e66c";
}
%lnr-unlock:before, .lnr-unlock:before {
  content: "\e66d";
}
%lnr-key:before, .lnr-key:before {
  content: "\e66e";
}
%lnr-key-hole:before, .lnr-key-hole:before {
  content: "\e66f";
}
%lnr-toggle-off:before, .lnr-toggle-off:before {
  content: "\e670";
}
%lnr-toggle-on:before, .lnr-toggle-on:before {
  content: "\e671";
}
%lnr-cog:before, .lnr-cog:before {
  content: "\e672";
}
%lnr-cog2:before, .lnr-cog2:before {
  content: "\e673";
}
%lnr-wrench:before, .lnr-wrench:before {
  content: "\e674";
}
%lnr-screwdriver:before, .lnr-screwdriver:before {
  content: "\e675";
}
%lnr-hammer-wrench:before, .lnr-hammer-wrench:before {
  content: "\e676";
}
%lnr-hammer:before, .lnr-hammer:before {
  content: "\e677";
}
%lnr-saw:before, .lnr-saw:before {
  content: "\e678";
}
%lnr-axe:before, .lnr-axe:before {
  content: "\e679";
}
%lnr-axe2:before, .lnr-axe2:before {
  content: "\e67a";
}
%lnr-shovel:before, .lnr-shovel:before {
  content: "\e67b";
}
%lnr-pickaxe:before, .lnr-pickaxe:before {
  content: "\e67c";
}
%lnr-factory:before, .lnr-factory:before {
  content: "\e67d";
}
%lnr-factory2:before, .lnr-factory2:before {
  content: "\e67e";
}
%lnr-recycle:before, .lnr-recycle:before {
  content: "\e67f";
}
%lnr-trash:before, .lnr-trash:before {
  content: "\e680";
}
%lnr-trash2:before, .lnr-trash2:before {
  content: "\e681";
}
%lnr-trash3:before, .lnr-trash3:before {
  content: "\e682";
}
%lnr-broom:before, .lnr-broom:before {
  content: "\e683";
}
%lnr-game:before, .lnr-game:before {
  content: "\e684";
}
%lnr-gamepad:before, .lnr-gamepad:before {
  content: "\e685";
}
%lnr-joystick:before, .lnr-joystick:before {
  content: "\e686";
}
%lnr-dice:before, .lnr-dice:before {
  content: "\e687";
}
%lnr-spades:before, .lnr-spades:before {
  content: "\e688";
}
%lnr-diamonds:before, .lnr-diamonds:before {
  content: "\e689";
}
%lnr-clubs:before, .lnr-clubs:before {
  content: "\e68a";
}
%lnr-hearts:before, .lnr-hearts:before {
  content: "\e68b";
}
%lnr-heart:before, .lnr-heart:before {
  content: "\e68c";
}
%lnr-star:before, .lnr-star:before {
  content: "\e68d";
}
%lnr-star-half:before, .lnr-star-half:before {
  content: "\e68e";
}
%lnr-star-empty:before, .lnr-star-empty:before {
  content: "\e68f";
}
%lnr-flag:before, .lnr-flag:before {
  content: "\e690";
}
%lnr-flag2:before, .lnr-flag2:before {
  content: "\e691";
}
%lnr-flag3:before, .lnr-flag3:before {
  content: "\e692";
}
%lnr-mailbox-full:before, .lnr-mailbox-full:before {
  content: "\e693";
}
%lnr-mailbox-empty:before, .lnr-mailbox-empty:before {
  content: "\e694";
}
%lnr-at-sign:before, .lnr-at-sign:before {
  content: "\e695";
}
%lnr-envelope:before, .lnr-envelope:before {
  content: "\e696";
}
%lnr-envelope-open:before, .lnr-envelope-open:before {
  content: "\e697";
}
%lnr-paperclip:before, .lnr-paperclip:before {
  content: "\e698";
}
%lnr-paper-plane:before, .lnr-paper-plane:before {
  content: "\e699";
}
%lnr-reply:before, .lnr-reply:before {
  content: "\e69a";
}
%lnr-reply-all:before, .lnr-reply-all:before {
  content: "\e69b";
}
%lnr-inbox:before, .lnr-inbox:before {
  content: "\e69c";
}
%lnr-inbox2:before, .lnr-inbox2:before {
  content: "\e69d";
}
%lnr-outbox:before, .lnr-outbox:before {
  content: "\e69e";
}
%lnr-box:before, .lnr-box:before {
  content: "\e69f";
}
%lnr-archive:before, .lnr-archive:before {
  content: "\e6a0";
}
%lnr-archive2:before, .lnr-archive2:before {
  content: "\e6a1";
}
%lnr-drawers:before, .lnr-drawers:before {
  content: "\e6a2";
}
%lnr-drawers2:before, .lnr-drawers2:before {
  content: "\e6a3";
}
%lnr-drawers3:before, .lnr-drawers3:before {
  content: "\e6a4";
}
%lnr-eye:before, .lnr-eye:before {
  content: "\e6a5";
}
%lnr-eye-crossed:before, .lnr-eye-crossed:before {
  content: "\e6a6";
}
%lnr-eye-plus:before, .lnr-eye-plus:before {
  content: "\e6a7";
}
%lnr-eye-minus:before, .lnr-eye-minus:before {
  content: "\e6a8";
}
%lnr-binoculars:before, .lnr-binoculars:before {
  content: "\e6a9";
}
%lnr-binoculars2:before, .lnr-binoculars2:before {
  content: "\e6aa";
}
%lnr-hdd:before, .lnr-hdd:before {
  content: "\e6ab";
}
%lnr-hdd-down:before, .lnr-hdd-down:before {
  content: "\e6ac";
}
%lnr-hdd-up:before, .lnr-hdd-up:before {
  content: "\e6ad";
}
%lnr-floppy-disk:before, .lnr-floppy-disk:before {
  content: "\e6ae";
}
%lnr-disc:before, .lnr-disc:before {
  content: "\e6af";
}
%lnr-tape2:before, .lnr-tape2:before {
  content: "\e6b0";
}
%lnr-printer:before, .lnr-printer:before {
  content: "\e6b1";
}
%lnr-shredder:before, .lnr-shredder:before {
  content: "\e6b2";
}
%lnr-file-empty:before, .lnr-file-empty:before {
  content: "\e6b3";
}
%lnr-file-add:before, .lnr-file-add:before {
  content: "\e6b4";
}
%lnr-file-check:before, .lnr-file-check:before {
  content: "\e6b5";
}
%lnr-file-lock:before, .lnr-file-lock:before {
  content: "\e6b6";
}
%lnr-files:before, .lnr-files:before {
  content: "\e6b7";
}
%lnr-copy:before, .lnr-copy:before {
  content: "\e6b8";
}
%lnr-compare:before, .lnr-compare:before {
  content: "\e6b9";
}
%lnr-folder:before, .lnr-folder:before {
  content: "\e6ba";
}
%lnr-folder-search:before, .lnr-folder-search:before {
  content: "\e6bb";
}
%lnr-folder-plus:before, .lnr-folder-plus:before {
  content: "\e6bc";
}
%lnr-folder-minus:before, .lnr-folder-minus:before {
  content: "\e6bd";
}
%lnr-folder-download:before, .lnr-folder-download:before {
  content: "\e6be";
}
%lnr-folder-upload:before, .lnr-folder-upload:before {
  content: "\e6bf";
}
%lnr-folder-star:before, .lnr-folder-star:before {
  content: "\e6c0";
}
%lnr-folder-heart:before, .lnr-folder-heart:before {
  content: "\e6c1";
}
%lnr-folder-user:before, .lnr-folder-user:before {
  content: "\e6c2";
}
%lnr-folder-shared:before, .lnr-folder-shared:before {
  content: "\e6c3";
}
%lnr-folder-music:before, .lnr-folder-music:before {
  content: "\e6c4";
}
%lnr-folder-picture:before, .lnr-folder-picture:before {
  content: "\e6c5";
}
%lnr-folder-film:before, .lnr-folder-film:before {
  content: "\e6c6";
}
%lnr-scissors:before, .lnr-scissors:before {
  content: "\e6c7";
}
%lnr-paste:before, .lnr-paste:before {
  content: "\e6c8";
}
%lnr-clipboard-empty:before, .lnr-clipboard-empty:before {
  content: "\e6c9";
}
%lnr-clipboard-pencil:before, .lnr-clipboard-pencil:before {
  content: "\e6ca";
}
%lnr-clipboard-text:before, .lnr-clipboard-text:before {
  content: "\e6cb";
}
%lnr-clipboard-check:before, .lnr-clipboard-check:before {
  content: "\e6cc";
}
%lnr-clipboard-down:before, .lnr-clipboard-down:before {
  content: "\e6cd";
}
%lnr-clipboard-left:before, .lnr-clipboard-left:before {
  content: "\e6ce";
}
%lnr-clipboard-alert:before, .lnr-clipboard-alert:before {
  content: "\e6cf";
}
%lnr-clipboard-user:before, .lnr-clipboard-user:before {
  content: "\e6d0";
}
%lnr-register:before, .lnr-register:before {
  content: "\e6d1";
}
%lnr-enter:before, .lnr-enter:before {
  content: "\e6d2";
}
%lnr-exit:before, .lnr-exit:before {
  content: "\e6d3";
}
%lnr-papers:before, .lnr-papers:before {
  content: "\e6d4";
}
%lnr-news:before, .lnr-news:before {
  content: "\e6d5";
}
%lnr-reading:before, .lnr-reading:before {
  content: "\e6d6";
}
%lnr-typewriter:before, .lnr-typewriter:before {
  content: "\e6d7";
}
%lnr-document:before, .lnr-document:before {
  content: "\e6d8";
}
%lnr-document2:before, .lnr-document2:before {
  content: "\e6d9";
}
%lnr-graduation-hat:before, .lnr-graduation-hat:before {
  content: "\e6da";
}
%lnr-license:before, .lnr-license:before {
  content: "\e6db";
}
%lnr-license2:before, .lnr-license2:before {
  content: "\e6dc";
}
%lnr-medal-empty:before, .lnr-medal-empty:before {
  content: "\e6dd";
}
%lnr-medal-first:before, .lnr-medal-first:before {
  content: "\e6de";
}
%lnr-medal-second:before, .lnr-medal-second:before {
  content: "\e6df";
}
%lnr-medal-third:before, .lnr-medal-third:before {
  content: "\e6e0";
}
%lnr-podium:before, .lnr-podium:before {
  content: "\e6e1";
}
%lnr-trophy:before, .lnr-trophy:before {
  content: "\e6e2";
}
%lnr-trophy2:before, .lnr-trophy2:before {
  content: "\e6e3";
}
%lnr-music-note:before, .lnr-music-note:before {
  content: "\e6e4";
}
%lnr-music-note2:before, .lnr-music-note2:before {
  content: "\e6e5";
}
%lnr-music-note3:before, .lnr-music-note3:before {
  content: "\e6e6";
}
%lnr-playlist:before, .lnr-playlist:before {
  content: "\e6e7";
}
%lnr-playlist-add:before, .lnr-playlist-add:before {
  content: "\e6e8";
}
%lnr-guitar:before, .lnr-guitar:before {
  content: "\e6e9";
}
%lnr-trumpet:before, .lnr-trumpet:before {
  content: "\e6ea";
}
%lnr-album:before, .lnr-album:before {
  content: "\e6eb";
}
%lnr-shuffle:before, .lnr-shuffle:before {
  content: "\e6ec";
}
%lnr-repeat-one:before, .lnr-repeat-one:before {
  content: "\e6ed";
}
%lnr-repeat:before, .lnr-repeat:before {
  content: "\e6ee";
}
%lnr-headphones:before, .lnr-headphones:before {
  content: "\e6ef";
}
%lnr-headset:before, .lnr-headset:before {
  content: "\e6f0";
}
%lnr-loudspeaker:before, .lnr-loudspeaker:before {
  content: "\e6f1";
}
%lnr-equalizer:before, .lnr-equalizer:before {
  content: "\e6f2";
}
%lnr-theater:before, .lnr-theater:before {
  content: "\e6f3";
}
%lnr-3d-glasses:before, .lnr-3d-glasses:before {
  content: "\e6f4";
}
%lnr-ticket:before, .lnr-ticket:before {
  content: "\e6f5";
}
%lnr-presentation:before, .lnr-presentation:before {
  content: "\e6f6";
}
%lnr-play:before, .lnr-play:before {
  content: "\e6f7";
}
%lnr-film-play:before, .lnr-film-play:before {
  content: "\e6f8";
}
%lnr-clapboard-play:before, .lnr-clapboard-play:before {
  content: "\e6f9";
}
%lnr-media:before, .lnr-media:before {
  content: "\e6fa";
}
%lnr-film:before, .lnr-film:before {
  content: "\e6fb";
}
%lnr-film2:before, .lnr-film2:before {
  content: "\e6fc";
}
%lnr-surveillance:before, .lnr-surveillance:before {
  content: "\e6fd";
}
%lnr-surveillance2:before, .lnr-surveillance2:before {
  content: "\e6fe";
}
%lnr-camera:before, .lnr-camera:before {
  content: "\e6ff";
}
%lnr-camera-crossed:before, .lnr-camera-crossed:before {
  content: "\e700";
}
%lnr-camera-play:before, .lnr-camera-play:before {
  content: "\e701";
}
%lnr-time-lapse:before, .lnr-time-lapse:before {
  content: "\e702";
}
%lnr-record:before, .lnr-record:before {
  content: "\e703";
}
%lnr-camera2:before, .lnr-camera2:before {
  content: "\e704";
}
%lnr-camera-flip:before, .lnr-camera-flip:before {
  content: "\e705";
}
%lnr-panorama:before, .lnr-panorama:before {
  content: "\e706";
}
%lnr-time-lapse2:before, .lnr-time-lapse2:before {
  content: "\e707";
}
%lnr-shutter:before, .lnr-shutter:before {
  content: "\e708";
}
%lnr-shutter2:before, .lnr-shutter2:before {
  content: "\e709";
}
%lnr-face-detection:before, .lnr-face-detection:before {
  content: "\e70a";
}
%lnr-flare:before, .lnr-flare:before {
  content: "\e70b";
}
%lnr-convex:before, .lnr-convex:before {
  content: "\e70c";
}
%lnr-concave:before, .lnr-concave:before {
  content: "\e70d";
}
%lnr-picture:before, .lnr-picture:before {
  content: "\e70e";
}
%lnr-picture2:before, .lnr-picture2:before {
  content: "\e70f";
}
%lnr-picture3:before, .lnr-picture3:before {
  content: "\e710";
}
%lnr-pictures:before, .lnr-pictures:before {
  content: "\e711";
}
%lnr-book:before, .lnr-book:before {
  content: "\e712";
}
%lnr-audio-book:before, .lnr-audio-book:before {
  content: "\e713";
}
%lnr-book2:before, .lnr-book2:before {
  content: "\e714";
}
%lnr-bookmark:before, .lnr-bookmark:before {
  content: "\e715";
}
%lnr-bookmark2:before, .lnr-bookmark2:before {
  content: "\e716";
}
%lnr-label:before, .lnr-label:before {
  content: "\e717";
}
%lnr-library:before, .lnr-library:before {
  content: "\e718";
}
%lnr-library2:before, .lnr-library2:before {
  content: "\e719";
}
%lnr-contacts:before, .lnr-contacts:before {
  content: "\e71a";
}
%lnr-profile:before, .lnr-profile:before {
  content: "\e71b";
}
%lnr-portrait:before, .lnr-portrait:before {
  content: "\e71c";
}
%lnr-portrait2:before, .lnr-portrait2:before {
  content: "\e71d";
}
%lnr-user:before, .lnr-user:before {
  content: "\e71e";
}
%lnr-user-plus:before, .lnr-user-plus:before {
  content: "\e71f";
}
%lnr-user-minus:before, .lnr-user-minus:before {
  content: "\e720";
}
%lnr-user-lock:before, .lnr-user-lock:before {
  content: "\e721";
}
%lnr-users:before, .lnr-users:before {
  content: "\e722";
}
%lnr-users2:before, .lnr-users2:before {
  content: "\e723";
}
%lnr-users-plus:before, .lnr-users-plus:before {
  content: "\e724";
}
%lnr-users-minus:before, .lnr-users-minus:before {
  content: "\e725";
}
%lnr-group-work:before, .lnr-group-work:before {
  content: "\e726";
}
%lnr-woman:before, .lnr-woman:before {
  content: "\e727";
}
%lnr-man:before, .lnr-man:before {
  content: "\e728";
}
%lnr-baby:before, .lnr-baby:before {
  content: "\e729";
}
%lnr-baby2:before, .lnr-baby2:before {
  content: "\e72a";
}
%lnr-baby3:before, .lnr-baby3:before {
  content: "\e72b";
}
%lnr-baby-bottle:before, .lnr-baby-bottle:before {
  content: "\e72c";
}
%lnr-walk:before, .lnr-walk:before {
  content: "\e72d";
}
%lnr-hand-waving:before, .lnr-hand-waving:before {
  content: "\e72e";
}
%lnr-jump:before, .lnr-jump:before {
  content: "\e72f";
}
%lnr-run:before, .lnr-run:before {
  content: "\e730";
}
%lnr-woman2:before, .lnr-woman2:before {
  content: "\e731";
}
%lnr-man2:before, .lnr-man2:before {
  content: "\e732";
}
%lnr-man-woman:before, .lnr-man-woman:before {
  content: "\e733";
}
%lnr-height:before, .lnr-height:before {
  content: "\e734";
}
%lnr-weight:before, .lnr-weight:before {
  content: "\e735";
}
%lnr-scale:before, .lnr-scale:before {
  content: "\e736";
}
%lnr-button:before, .lnr-button:before {
  content: "\e737";
}
%lnr-bow-tie:before, .lnr-bow-tie:before {
  content: "\e738";
}
%lnr-tie:before, .lnr-tie:before {
  content: "\e739";
}
%lnr-socks:before, .lnr-socks:before {
  content: "\e73a";
}
%lnr-shoe:before, .lnr-shoe:before {
  content: "\e73b";
}
%lnr-shoes:before, .lnr-shoes:before {
  content: "\e73c";
}
%lnr-hat:before, .lnr-hat:before {
  content: "\e73d";
}
%lnr-pants:before, .lnr-pants:before {
  content: "\e73e";
}
%lnr-shorts:before, .lnr-shorts:before {
  content: "\e73f";
}
%lnr-flip-flops:before, .lnr-flip-flops:before {
  content: "\e740";
}
%lnr-shirt:before, .lnr-shirt:before {
  content: "\e741";
}
%lnr-hanger:before, .lnr-hanger:before {
  content: "\e742";
}
%lnr-laundry:before, .lnr-laundry:before {
  content: "\e743";
}
%lnr-store:before, .lnr-store:before {
  content: "\e744";
}
%lnr-haircut:before, .lnr-haircut:before {
  content: "\e745";
}
%lnr-store-24:before, .lnr-store-24:before {
  content: "\e746";
}
%lnr-barcode:before, .lnr-barcode:before {
  content: "\e747";
}
%lnr-barcode2:before, .lnr-barcode2:before {
  content: "\e748";
}
%lnr-barcode3:before, .lnr-barcode3:before {
  content: "\e749";
}
%lnr-cashier:before, .lnr-cashier:before {
  content: "\e74a";
}
%lnr-bag:before, .lnr-bag:before {
  content: "\e74b";
}
%lnr-bag2:before, .lnr-bag2:before {
  content: "\e74c";
}
%lnr-cart:before, .lnr-cart:before {
  content: "\e74d";
}
%lnr-cart-empty:before, .lnr-cart-empty:before {
  content: "\e74e";
}
%lnr-cart-full:before, .lnr-cart-full:before {
  content: "\e74f";
}
%lnr-cart-plus:before, .lnr-cart-plus:before {
  content: "\e750";
}
%lnr-cart-plus2:before, .lnr-cart-plus2:before {
  content: "\e751";
}
%lnr-cart-add:before, .lnr-cart-add:before {
  content: "\e752";
}
%lnr-cart-remove:before, .lnr-cart-remove:before {
  content: "\e753";
}
%lnr-cart-exchange:before, .lnr-cart-exchange:before {
  content: "\e754";
}
%lnr-tag:before, .lnr-tag:before {
  content: "\e755";
}
%lnr-tags:before, .lnr-tags:before {
  content: "\e756";
}
%lnr-receipt:before, .lnr-receipt:before {
  content: "\e757";
}
%lnr-wallet:before, .lnr-wallet:before {
  content: "\e758";
}
%lnr-credit-card:before, .lnr-credit-card:before {
  content: "\e759";
}
%lnr-cash-dollar:before, .lnr-cash-dollar:before {
  content: "\e75a";
}
%lnr-cash-euro:before, .lnr-cash-euro:before {
  content: "\e75b";
}
%lnr-cash-pound:before, .lnr-cash-pound:before {
  content: "\e75c";
}
%lnr-cash-yen:before, .lnr-cash-yen:before {
  content: "\e75d";
}
%lnr-bag-dollar:before, .lnr-bag-dollar:before {
  content: "\e75e";
}
%lnr-bag-euro:before, .lnr-bag-euro:before {
  content: "\e75f";
}
%lnr-bag-pound:before, .lnr-bag-pound:before {
  content: "\e760";
}
%lnr-bag-yen:before, .lnr-bag-yen:before {
  content: "\e761";
}
%lnr-coin-dollar:before, .lnr-coin-dollar:before {
  content: "\e762";
}
%lnr-coin-euro:before, .lnr-coin-euro:before {
  content: "\e763";
}
%lnr-coin-pound:before, .lnr-coin-pound:before {
  content: "\e764";
}
%lnr-coin-yen:before, .lnr-coin-yen:before {
  content: "\e765";
}
%lnr-calculator:before, .lnr-calculator:before {
  content: "\e766";
}
%lnr-calculator2:before, .lnr-calculator2:before {
  content: "\e767";
}
%lnr-abacus:before, .lnr-abacus:before {
  content: "\e768";
}
%lnr-vault:before, .lnr-vault:before {
  content: "\e769";
}
%lnr-telephone:before, .lnr-telephone:before {
  content: "\e76a";
}
%lnr-phone-lock:before, .lnr-phone-lock:before {
  content: "\e76b";
}
%lnr-phone-wave:before, .lnr-phone-wave:before {
  content: "\e76c";
}
%lnr-phone-pause:before, .lnr-phone-pause:before {
  content: "\e76d";
}
%lnr-phone-outgoing:before, .lnr-phone-outgoing:before {
  content: "\e76e";
}
%lnr-phone-incoming:before, .lnr-phone-incoming:before {
  content: "\e76f";
}
%lnr-phone-in-out:before, .lnr-phone-in-out:before {
  content: "\e770";
}
%lnr-phone-error:before, .lnr-phone-error:before {
  content: "\e771";
}
%lnr-phone-sip:before, .lnr-phone-sip:before {
  content: "\e772";
}
%lnr-phone-plus:before, .lnr-phone-plus:before {
  content: "\e773";
}
%lnr-phone-minus:before, .lnr-phone-minus:before {
  content: "\e774";
}
%lnr-voicemail:before, .lnr-voicemail:before {
  content: "\e775";
}
%lnr-dial:before, .lnr-dial:before {
  content: "\e776";
}
%lnr-telephone2:before, .lnr-telephone2:before {
  content: "\e777";
}
%lnr-pushpin:before, .lnr-pushpin:before {
  content: "\e778";
}
%lnr-pushpin2:before, .lnr-pushpin2:before {
  content: "\e779";
}
%lnr-map-marker:before, .lnr-map-marker:before {
  content: "\e77a";
}
%lnr-map-marker-user:before, .lnr-map-marker-user:before {
  content: "\e77b";
}
%lnr-map-marker-down:before, .lnr-map-marker-down:before {
  content: "\e77c";
}
%lnr-map-marker-check:before, .lnr-map-marker-check:before {
  content: "\e77d";
}
%lnr-map-marker-crossed:before, .lnr-map-marker-crossed:before {
  content: "\e77e";
}
%lnr-radar:before, .lnr-radar:before {
  content: "\e77f";
}
%lnr-compass2:before, .lnr-compass2:before {
  content: "\e780";
}
%lnr-map:before, .lnr-map:before {
  content: "\e781";
}
%lnr-map2:before, .lnr-map2:before {
  content: "\e782";
}
%lnr-location:before, .lnr-location:before {
  content: "\e783";
}
%lnr-road-sign:before, .lnr-road-sign:before {
  content: "\e784";
}
%lnr-calendar-empty:before, .lnr-calendar-empty:before {
  content: "\e785";
}
%lnr-calendar-check:before, .lnr-calendar-check:before {
  content: "\e786";
}
%lnr-calendar-cross:before, .lnr-calendar-cross:before {
  content: "\e787";
}
%lnr-calendar-31:before, .lnr-calendar-31:before {
  content: "\e788";
}
%lnr-calendar-full:before, .lnr-calendar-full:before {
  content: "\e789";
}
%lnr-calendar-insert:before, .lnr-calendar-insert:before {
  content: "\e78a";
}
%lnr-calendar-text:before, .lnr-calendar-text:before {
  content: "\e78b";
}
%lnr-calendar-user:before, .lnr-calendar-user:before {
  content: "\e78c";
}
%lnr-mouse:before, .lnr-mouse:before {
  content: "\e78d";
}
%lnr-mouse-left:before, .lnr-mouse-left:before {
  content: "\e78e";
}
%lnr-mouse-right:before, .lnr-mouse-right:before {
  content: "\e78f";
}
%lnr-mouse-both:before, .lnr-mouse-both:before {
  content: "\e790";
}
%lnr-keyboard:before, .lnr-keyboard:before {
  content: "\e791";
}
%lnr-keyboard-up:before, .lnr-keyboard-up:before {
  content: "\e792";
}
%lnr-keyboard-down:before, .lnr-keyboard-down:before {
  content: "\e793";
}
%lnr-delete:before, .lnr-delete:before {
  content: "\e794";
}
%lnr-spell-check:before, .lnr-spell-check:before {
  content: "\e795";
}
%lnr-escape:before, .lnr-escape:before {
  content: "\e796";
}
%lnr-enter2:before, .lnr-enter2:before {
  content: "\e797";
}
%lnr-screen:before, .lnr-screen:before {
  content: "\e798";
}
%lnr-aspect-ratio:before, .lnr-aspect-ratio:before {
  content: "\e799";
}
%lnr-signal:before, .lnr-signal:before {
  content: "\e79a";
}
%lnr-signal-lock:before, .lnr-signal-lock:before {
  content: "\e79b";
}
%lnr-signal-80:before, .lnr-signal-80:before {
  content: "\e79c";
}
%lnr-signal-60:before, .lnr-signal-60:before {
  content: "\e79d";
}
%lnr-signal-40:before, .lnr-signal-40:before {
  content: "\e79e";
}
%lnr-signal-20:before, .lnr-signal-20:before {
  content: "\e79f";
}
%lnr-signal-0:before, .lnr-signal-0:before {
  content: "\e7a0";
}
%lnr-signal-blocked:before, .lnr-signal-blocked:before {
  content: "\e7a1";
}
%lnr-sim:before, .lnr-sim:before {
  content: "\e7a2";
}
%lnr-flash-memory:before, .lnr-flash-memory:before {
  content: "\e7a3";
}
%lnr-usb-drive:before, .lnr-usb-drive:before {
  content: "\e7a4";
}
%lnr-phone:before, .lnr-phone:before {
  content: "\e7a5";
}
%lnr-smartphone:before, .lnr-smartphone:before {
  content: "\e7a6";
}
%lnr-smartphone-notification:before, .lnr-smartphone-notification:before {
  content: "\e7a7";
}
%lnr-smartphone-vibration:before, .lnr-smartphone-vibration:before {
  content: "\e7a8";
}
%lnr-smartphone-embed:before, .lnr-smartphone-embed:before {
  content: "\e7a9";
}
%lnr-smartphone-waves:before, .lnr-smartphone-waves:before {
  content: "\e7aa";
}
%lnr-tablet:before, .lnr-tablet:before {
  content: "\e7ab";
}
%lnr-tablet2:before, .lnr-tablet2:before {
  content: "\e7ac";
}
%lnr-laptop:before, .lnr-laptop:before {
  content: "\e7ad";
}
%lnr-laptop-phone:before, .lnr-laptop-phone:before {
  content: "\e7ae";
}
%lnr-desktop:before, .lnr-desktop:before {
  content: "\e7af";
}
%lnr-launch:before, .lnr-launch:before {
  content: "\e7b0";
}
%lnr-new-tab:before, .lnr-new-tab:before {
  content: "\e7b1";
}
%lnr-window:before, .lnr-window:before {
  content: "\e7b2";
}
%lnr-cable:before, .lnr-cable:before {
  content: "\e7b3";
}
%lnr-cable2:before, .lnr-cable2:before {
  content: "\e7b4";
}
%lnr-tv:before, .lnr-tv:before {
  content: "\e7b5";
}
%lnr-radio:before, .lnr-radio:before {
  content: "\e7b6";
}
%lnr-remote-control:before, .lnr-remote-control:before {
  content: "\e7b7";
}
%lnr-power-switch:before, .lnr-power-switch:before {
  content: "\e7b8";
}
%lnr-power:before, .lnr-power:before {
  content: "\e7b9";
}
%lnr-power-crossed:before, .lnr-power-crossed:before {
  content: "\e7ba";
}
%lnr-flash-auto:before, .lnr-flash-auto:before {
  content: "\e7bb";
}
%lnr-lamp:before, .lnr-lamp:before {
  content: "\e7bc";
}
%lnr-flashlight:before, .lnr-flashlight:before {
  content: "\e7bd";
}
%lnr-lampshade:before, .lnr-lampshade:before {
  content: "\e7be";
}
%lnr-cord:before, .lnr-cord:before {
  content: "\e7bf";
}
%lnr-outlet:before, .lnr-outlet:before {
  content: "\e7c0";
}
%lnr-battery-power:before, .lnr-battery-power:before {
  content: "\e7c1";
}
%lnr-battery-empty:before, .lnr-battery-empty:before {
  content: "\e7c2";
}
%lnr-battery-alert:before, .lnr-battery-alert:before {
  content: "\e7c3";
}
%lnr-battery-error:before, .lnr-battery-error:before {
  content: "\e7c4";
}
%lnr-battery-low1:before, .lnr-battery-low1:before {
  content: "\e7c5";
}
%lnr-battery-low2:before, .lnr-battery-low2:before {
  content: "\e7c6";
}
%lnr-battery-low3:before, .lnr-battery-low3:before {
  content: "\e7c7";
}
%lnr-battery-mid1:before, .lnr-battery-mid1:before {
  content: "\e7c8";
}
%lnr-battery-mid2:before, .lnr-battery-mid2:before {
  content: "\e7c9";
}
%lnr-battery-mid3:before, .lnr-battery-mid3:before {
  content: "\e7ca";
}
%lnr-battery-full:before, .lnr-battery-full:before {
  content: "\e7cb";
}
%lnr-battery-charging:before, .lnr-battery-charging:before {
  content: "\e7cc";
}
%lnr-battery-charging2:before, .lnr-battery-charging2:before {
  content: "\e7cd";
}
%lnr-battery-charging3:before, .lnr-battery-charging3:before {
  content: "\e7ce";
}
%lnr-battery-charging4:before, .lnr-battery-charging4:before {
  content: "\e7cf";
}
%lnr-battery-charging5:before, .lnr-battery-charging5:before {
  content: "\e7d0";
}
%lnr-battery-charging6:before, .lnr-battery-charging6:before {
  content: "\e7d1";
}
%lnr-battery-charging7:before, .lnr-battery-charging7:before {
  content: "\e7d2";
}
%lnr-chip:before, .lnr-chip:before {
  content: "\e7d3";
}
%lnr-chip-x64:before, .lnr-chip-x64:before {
  content: "\e7d4";
}
%lnr-chip-x86:before, .lnr-chip-x86:before {
  content: "\e7d5";
}
%lnr-bubble:before, .lnr-bubble:before {
  content: "\e7d6";
}
%lnr-bubbles:before, .lnr-bubbles:before {
  content: "\e7d7";
}
%lnr-bubble-dots:before, .lnr-bubble-dots:before {
  content: "\e7d8";
}
%lnr-bubble-alert:before, .lnr-bubble-alert:before {
  content: "\e7d9";
}
%lnr-bubble-question:before, .lnr-bubble-question:before {
  content: "\e7da";
}
%lnr-bubble-text:before, .lnr-bubble-text:before {
  content: "\e7db";
}
%lnr-bubble-pencil:before, .lnr-bubble-pencil:before {
  content: "\e7dc";
}
%lnr-bubble-picture:before, .lnr-bubble-picture:before {
  content: "\e7dd";
}
%lnr-bubble-video:before, .lnr-bubble-video:before {
  content: "\e7de";
}
%lnr-bubble-user:before, .lnr-bubble-user:before {
  content: "\e7df";
}
%lnr-bubble-quote:before, .lnr-bubble-quote:before {
  content: "\e7e0";
}
%lnr-bubble-heart:before, .lnr-bubble-heart:before {
  content: "\e7e1";
}
%lnr-bubble-emoticon:before, .lnr-bubble-emoticon:before {
  content: "\e7e2";
}
%lnr-bubble-attachment:before, .lnr-bubble-attachment:before {
  content: "\e7e3";
}
%lnr-phone-bubble:before, .lnr-phone-bubble:before {
  content: "\e7e4";
}
%lnr-quote-open:before, .lnr-quote-open:before {
  content: "\e7e5";
}
%lnr-quote-close:before, .lnr-quote-close:before {
  content: "\e7e6";
}
%lnr-dna:before, .lnr-dna:before {
  content: "\e7e7";
}
%lnr-heart-pulse:before, .lnr-heart-pulse:before {
  content: "\e7e8";
}
%lnr-pulse:before, .lnr-pulse:before {
  content: "\e7e9";
}
%lnr-syringe:before, .lnr-syringe:before {
  content: "\e7ea";
}
%lnr-pills:before, .lnr-pills:before {
  content: "\e7eb";
}
%lnr-first-aid:before, .lnr-first-aid:before {
  content: "\e7ec";
}
%lnr-lifebuoy:before, .lnr-lifebuoy:before {
  content: "\e7ed";
}
%lnr-bandage:before, .lnr-bandage:before {
  content: "\e7ee";
}
%lnr-bandages:before, .lnr-bandages:before {
  content: "\e7ef";
}
%lnr-thermometer:before, .lnr-thermometer:before {
  content: "\e7f0";
}
%lnr-microscope:before, .lnr-microscope:before {
  content: "\e7f1";
}
%lnr-brain:before, .lnr-brain:before {
  content: "\e7f2";
}
%lnr-beaker:before, .lnr-beaker:before {
  content: "\e7f3";
}
%lnr-skull:before, .lnr-skull:before {
  content: "\e7f4";
}
%lnr-bone:before, .lnr-bone:before {
  content: "\e7f5";
}
%lnr-construction:before, .lnr-construction:before {
  content: "\e7f6";
}
%lnr-construction-cone:before, .lnr-construction-cone:before {
  content: "\e7f7";
}
%lnr-pie-chart:before, .lnr-pie-chart:before {
  content: "\e7f8";
}
%lnr-pie-chart2:before, .lnr-pie-chart2:before {
  content: "\e7f9";
}
%lnr-graph:before, .lnr-graph:before {
  content: "\e7fa";
}
%lnr-chart-growth:before, .lnr-chart-growth:before {
  content: "\e7fb";
}
%lnr-chart-bars:before, .lnr-chart-bars:before {
  content: "\e7fc";
}
%lnr-chart-settings:before, .lnr-chart-settings:before {
  content: "\e7fd";
}
%lnr-cake:before, .lnr-cake:before {
  content: "\e7fe";
}
%lnr-gift:before, .lnr-gift:before {
  content: "\e7ff";
}
%lnr-balloon:before, .lnr-balloon:before {
  content: "\e800";
}
%lnr-rank:before, .lnr-rank:before {
  content: "\e801";
}
%lnr-rank2:before, .lnr-rank2:before {
  content: "\e802";
}
%lnr-rank3:before, .lnr-rank3:before {
  content: "\e803";
}
%lnr-crown:before, .lnr-crown:before {
  content: "\e804";
}
%lnr-lotus:before, .lnr-lotus:before {
  content: "\e805";
}
%lnr-diamond:before, .lnr-diamond:before {
  content: "\e806";
}
%lnr-diamond2:before, .lnr-diamond2:before {
  content: "\e807";
}
%lnr-diamond3:before, .lnr-diamond3:before {
  content: "\e808";
}
%lnr-diamond4:before, .lnr-diamond4:before {
  content: "\e809";
}
%lnr-linearicons:before, .lnr-linearicons:before {
  content: "\e80a";
}
%lnr-teacup:before, .lnr-teacup:before {
  content: "\e80b";
}
%lnr-teapot:before, .lnr-teapot:before {
  content: "\e80c";
}
%lnr-glass:before, .lnr-glass:before {
  content: "\e80d";
}
%lnr-bottle2:before, .lnr-bottle2:before {
  content: "\e80e";
}
%lnr-glass-cocktail:before, .lnr-glass-cocktail:before {
  content: "\e80f";
}
%lnr-glass2:before, .lnr-glass2:before {
  content: "\e810";
}
%lnr-dinner:before, .lnr-dinner:before {
  content: "\e811";
}
%lnr-dinner2:before, .lnr-dinner2:before {
  content: "\e812";
}
%lnr-chef:before, .lnr-chef:before {
  content: "\e813";
}
%lnr-scale2:before, .lnr-scale2:before {
  content: "\e814";
}
%lnr-egg:before, .lnr-egg:before {
  content: "\e815";
}
%lnr-egg2:before, .lnr-egg2:before {
  content: "\e816";
}
%lnr-eggs:before, .lnr-eggs:before {
  content: "\e817";
}
%lnr-platter:before, .lnr-platter:before {
  content: "\e818";
}
%lnr-steak:before, .lnr-steak:before {
  content: "\e819";
}
%lnr-hamburger:before, .lnr-hamburger:before {
  content: "\e81a";
}
%lnr-hotdog:before, .lnr-hotdog:before {
  content: "\e81b";
}
%lnr-pizza:before, .lnr-pizza:before {
  content: "\e81c";
}
%lnr-sausage:before, .lnr-sausage:before {
  content: "\e81d";
}
%lnr-chicken:before, .lnr-chicken:before {
  content: "\e81e";
}
%lnr-fish:before, .lnr-fish:before {
  content: "\e81f";
}
%lnr-carrot:before, .lnr-carrot:before {
  content: "\e820";
}
%lnr-cheese:before, .lnr-cheese:before {
  content: "\e821";
}
%lnr-bread:before, .lnr-bread:before {
  content: "\e822";
}
%lnr-ice-cream:before, .lnr-ice-cream:before {
  content: "\e823";
}
%lnr-ice-cream2:before, .lnr-ice-cream2:before {
  content: "\e824";
}
%lnr-candy:before, .lnr-candy:before {
  content: "\e825";
}
%lnr-lollipop:before, .lnr-lollipop:before {
  content: "\e826";
}
%lnr-coffee-bean:before, .lnr-coffee-bean:before {
  content: "\e827";
}
%lnr-coffee-cup:before, .lnr-coffee-cup:before {
  content: "\e828";
}
%lnr-cherry:before, .lnr-cherry:before {
  content: "\e829";
}
%lnr-grapes:before, .lnr-grapes:before {
  content: "\e82a";
}
%lnr-citrus:before, .lnr-citrus:before {
  content: "\e82b";
}
%lnr-apple:before, .lnr-apple:before {
  content: "\e82c";
}
%lnr-leaf:before, .lnr-leaf:before {
  content: "\e82d";
}
%lnr-landscape:before, .lnr-landscape:before {
  content: "\e82e";
}
%lnr-pine-tree:before, .lnr-pine-tree:before {
  content: "\e82f";
}
%lnr-tree:before, .lnr-tree:before {
  content: "\e830";
}
%lnr-cactus:before, .lnr-cactus:before {
  content: "\e831";
}
%lnr-paw:before, .lnr-paw:before {
  content: "\e832";
}
%lnr-footprint:before, .lnr-footprint:before {
  content: "\e833";
}
%lnr-speed-slow:before, .lnr-speed-slow:before {
  content: "\e834";
}
%lnr-speed-medium:before, .lnr-speed-medium:before {
  content: "\e835";
}
%lnr-speed-fast:before, .lnr-speed-fast:before {
  content: "\e836";
}
%lnr-rocket:before, .lnr-rocket:before {
  content: "\e837";
}
%lnr-hammer2:before, .lnr-hammer2:before {
  content: "\e838";
}
%lnr-balance:before, .lnr-balance:before {
  content: "\e839";
}
%lnr-briefcase:before, .lnr-briefcase:before {
  content: "\e83a";
}
%lnr-luggage-weight:before, .lnr-luggage-weight:before {
  content: "\e83b";
}
%lnr-dolly:before, .lnr-dolly:before {
  content: "\e83c";
}
%lnr-plane:before, .lnr-plane:before {
  content: "\e83d";
}
%lnr-plane-crossed:before, .lnr-plane-crossed:before {
  content: "\e83e";
}
%lnr-helicopter:before, .lnr-helicopter:before {
  content: "\e83f";
}
%lnr-traffic-lights:before, .lnr-traffic-lights:before {
  content: "\e840";
}
%lnr-siren:before, .lnr-siren:before {
  content: "\e841";
}
%lnr-road:before, .lnr-road:before {
  content: "\e842";
}
%lnr-engine:before, .lnr-engine:before {
  content: "\e843";
}
%lnr-oil-pressure:before, .lnr-oil-pressure:before {
  content: "\e844";
}
%lnr-coolant-temperature:before, .lnr-coolant-temperature:before {
  content: "\e845";
}
%lnr-car-battery:before, .lnr-car-battery:before {
  content: "\e846";
}
%lnr-gas:before, .lnr-gas:before {
  content: "\e847";
}
%lnr-gallon:before, .lnr-gallon:before {
  content: "\e848";
}
%lnr-transmission:before, .lnr-transmission:before {
  content: "\e849";
}
%lnr-car:before, .lnr-car:before {
  content: "\e84a";
}
%lnr-car-wash:before, .lnr-car-wash:before {
  content: "\e84b";
}
%lnr-car-wash2:before, .lnr-car-wash2:before {
  content: "\e84c";
}
%lnr-bus:before, .lnr-bus:before {
  content: "\e84d";
}
%lnr-bus2:before, .lnr-bus2:before {
  content: "\e84e";
}
%lnr-car2:before, .lnr-car2:before {
  content: "\e84f";
}
%lnr-parking:before, .lnr-parking:before {
  content: "\e850";
}
%lnr-car-lock:before, .lnr-car-lock:before {
  content: "\e851";
}
%lnr-taxi:before, .lnr-taxi:before {
  content: "\e852";
}
%lnr-car-siren:before, .lnr-car-siren:before {
  content: "\e853";
}
%lnr-car-wash3:before, .lnr-car-wash3:before {
  content: "\e854";
}
%lnr-car-wash4:before, .lnr-car-wash4:before {
  content: "\e855";
}
%lnr-ambulance:before, .lnr-ambulance:before {
  content: "\e856";
}
%lnr-truck:before, .lnr-truck:before {
  content: "\e857";
}
%lnr-trailer:before, .lnr-trailer:before {
  content: "\e858";
}
%lnr-scale-truck:before, .lnr-scale-truck:before {
  content: "\e859";
}
%lnr-train:before, .lnr-train:before {
  content: "\e85a";
}
%lnr-ship:before, .lnr-ship:before {
  content: "\e85b";
}
%lnr-ship2:before, .lnr-ship2:before {
  content: "\e85c";
}
%lnr-anchor:before, .lnr-anchor:before {
  content: "\e85d";
}
%lnr-boat:before, .lnr-boat:before {
  content: "\e85e";
}
%lnr-bicycle:before, .lnr-bicycle:before {
  content: "\e85f";
}
%lnr-bicycle2:before, .lnr-bicycle2:before {
  content: "\e860";
}
%lnr-dumbbell:before, .lnr-dumbbell:before {
  content: "\e861";
}
%lnr-bench-press:before, .lnr-bench-press:before {
  content: "\e862";
}
%lnr-swim:before, .lnr-swim:before {
  content: "\e863";
}
%lnr-football:before, .lnr-football:before {
  content: "\e864";
}
%lnr-baseball-bat:before, .lnr-baseball-bat:before {
  content: "\e865";
}
%lnr-baseball:before, .lnr-baseball:before {
  content: "\e866";
}
%lnr-tennis:before, .lnr-tennis:before {
  content: "\e867";
}
%lnr-tennis2:before, .lnr-tennis2:before {
  content: "\e868";
}
%lnr-ping-pong:before, .lnr-ping-pong:before {
  content: "\e869";
}
%lnr-hockey:before, .lnr-hockey:before {
  content: "\e86a";
}
%lnr-8ball:before, .lnr-8ball:before {
  content: "\e86b";
}
%lnr-bowling:before, .lnr-bowling:before {
  content: "\e86c";
}
%lnr-bowling-pins:before, .lnr-bowling-pins:before {
  content: "\e86d";
}
%lnr-golf:before, .lnr-golf:before {
  content: "\e86e";
}
%lnr-golf2:before, .lnr-golf2:before {
  content: "\e86f";
}
%lnr-archery:before, .lnr-archery:before {
  content: "\e870";
}
%lnr-slingshot:before, .lnr-slingshot:before {
  content: "\e871";
}
%lnr-soccer:before, .lnr-soccer:before {
  content: "\e872";
}
%lnr-basketball:before, .lnr-basketball:before {
  content: "\e873";
}
%lnr-cube:before, .lnr-cube:before {
  content: "\e874";
}
%lnr-3d-rotate:before, .lnr-3d-rotate:before {
  content: "\e875";
}
%lnr-puzzle:before, .lnr-puzzle:before {
  content: "\e876";
}
%lnr-glasses:before, .lnr-glasses:before {
  content: "\e877";
}
%lnr-glasses2:before, .lnr-glasses2:before {
  content: "\e878";
}
%lnr-accessibility:before, .lnr-accessibility:before {
  content: "\e879";
}
%lnr-wheelchair:before, .lnr-wheelchair:before {
  content: "\e87a";
}
%lnr-wall:before, .lnr-wall:before {
  content: "\e87b";
}
%lnr-fence:before, .lnr-fence:before {
  content: "\e87c";
}
%lnr-wall2:before, .lnr-wall2:before {
  content: "\e87d";
}
%lnr-icons:before, .lnr-icons:before {
  content: "\e87e";
}
%lnr-resize-handle:before, .lnr-resize-handle:before {
  content: "\e87f";
}
%lnr-icons2:before, .lnr-icons2:before {
  content: "\e880";
}
%lnr-select:before, .lnr-select:before {
  content: "\e881";
}
%lnr-select2:before, .lnr-select2:before {
  content: "\e882";
}
%lnr-site-map:before, .lnr-site-map:before {
  content: "\e883";
}
%lnr-earth:before, .lnr-earth:before {
  content: "\e884";
}
%lnr-earth-lock:before, .lnr-earth-lock:before {
  content: "\e885";
}
%lnr-network:before, .lnr-network:before {
  content: "\e886";
}
%lnr-network-lock:before, .lnr-network-lock:before {
  content: "\e887";
}
%lnr-planet:before, .lnr-planet:before {
  content: "\e888";
}
%lnr-happy:before, .lnr-happy:before {
  content: "\e889";
}
%lnr-smile:before, .lnr-smile:before {
  content: "\e88a";
}
%lnr-grin:before, .lnr-grin:before {
  content: "\e88b";
}
%lnr-tongue:before, .lnr-tongue:before {
  content: "\e88c";
}
%lnr-sad:before, .lnr-sad:before {
  content: "\e88d";
}
%lnr-wink:before, .lnr-wink:before {
  content: "\e88e";
}
%lnr-dream:before, .lnr-dream:before {
  content: "\e88f";
}
%lnr-shocked:before, .lnr-shocked:before {
  content: "\e890";
}
%lnr-shocked2:before, .lnr-shocked2:before {
  content: "\e891";
}
%lnr-tongue2:before, .lnr-tongue2:before {
  content: "\e892";
}
%lnr-neutral:before, .lnr-neutral:before {
  content: "\e893";
}
%lnr-happy-grin:before, .lnr-happy-grin:before {
  content: "\e894";
}
%lnr-cool:before, .lnr-cool:before {
  content: "\e895";
}
%lnr-mad:before, .lnr-mad:before {
  content: "\e896";
}
%lnr-grin-evil:before, .lnr-grin-evil:before {
  content: "\e897";
}
%lnr-evil:before, .lnr-evil:before {
  content: "\e898";
}
%lnr-wow:before, .lnr-wow:before {
  content: "\e899";
}
%lnr-annoyed:before, .lnr-annoyed:before {
  content: "\e89a";
}
%lnr-wondering:before, .lnr-wondering:before {
  content: "\e89b";
}
%lnr-confused:before, .lnr-confused:before {
  content: "\e89c";
}
%lnr-zipped:before, .lnr-zipped:before {
  content: "\e89d";
}
%lnr-grumpy:before, .lnr-grumpy:before {
  content: "\e89e";
}
%lnr-mustache:before, .lnr-mustache:before {
  content: "\e89f";
}
%lnr-tombstone-hipster:before, .lnr-tombstone-hipster:before {
  content: "\e8a0";
}
%lnr-tombstone:before, .lnr-tombstone:before {
  content: "\e8a1";
}
%lnr-ghost:before, .lnr-ghost:before {
  content: "\e8a2";
}
%lnr-ghost-hipster:before, .lnr-ghost-hipster:before {
  content: "\e8a3";
}
%lnr-halloween:before, .lnr-halloween:before {
  content: "\e8a4";
}
%lnr-christmas:before, .lnr-christmas:before {
  content: "\e8a5";
}
%lnr-easter-egg:before, .lnr-easter-egg:before {
  content: "\e8a6";
}
%lnr-mustache2:before, .lnr-mustache2:before {
  content: "\e8a7";
}
%lnr-mustache-glasses:before, .lnr-mustache-glasses:before {
  content: "\e8a8";
}
%lnr-pipe:before, .lnr-pipe:before {
  content: "\e8a9";
}
%lnr-alarm:before, .lnr-alarm:before {
  content: "\e8aa";
}
%lnr-alarm-add:before, .lnr-alarm-add:before {
  content: "\e8ab";
}
%lnr-alarm-snooze:before, .lnr-alarm-snooze:before {
  content: "\e8ac";
}
%lnr-alarm-ringing:before, .lnr-alarm-ringing:before {
  content: "\e8ad";
}
%lnr-bullhorn:before, .lnr-bullhorn:before {
  content: "\e8ae";
}
%lnr-hearing:before, .lnr-hearing:before {
  content: "\e8af";
}
%lnr-volume-high:before, .lnr-volume-high:before {
  content: "\e8b0";
}
%lnr-volume-medium:before, .lnr-volume-medium:before {
  content: "\e8b1";
}
%lnr-volume-low:before, .lnr-volume-low:before {
  content: "\e8b2";
}
%lnr-volume:before, .lnr-volume:before {
  content: "\e8b3";
}
%lnr-mute:before, .lnr-mute:before {
  content: "\e8b4";
}
%lnr-lan:before, .lnr-lan:before {
  content: "\e8b5";
}
%lnr-lan2:before, .lnr-lan2:before {
  content: "\e8b6";
}
%lnr-wifi:before, .lnr-wifi:before {
  content: "\e8b7";
}
%lnr-wifi-lock:before, .lnr-wifi-lock:before {
  content: "\e8b8";
}
%lnr-wifi-blocked:before, .lnr-wifi-blocked:before {
  content: "\e8b9";
}
%lnr-wifi-mid:before, .lnr-wifi-mid:before {
  content: "\e8ba";
}
%lnr-wifi-low:before, .lnr-wifi-low:before {
  content: "\e8bb";
}
%lnr-wifi-low2:before, .lnr-wifi-low2:before {
  content: "\e8bc";
}
%lnr-wifi-alert:before, .lnr-wifi-alert:before {
  content: "\e8bd";
}
%lnr-wifi-alert-mid:before, .lnr-wifi-alert-mid:before {
  content: "\e8be";
}
%lnr-wifi-alert-low:before, .lnr-wifi-alert-low:before {
  content: "\e8bf";
}
%lnr-wifi-alert-low2:before, .lnr-wifi-alert-low2:before {
  content: "\e8c0";
}
%lnr-stream:before, .lnr-stream:before {
  content: "\e8c1";
}
%lnr-stream-check:before, .lnr-stream-check:before {
  content: "\e8c2";
}
%lnr-stream-error:before, .lnr-stream-error:before {
  content: "\e8c3";
}
%lnr-stream-alert:before, .lnr-stream-alert:before {
  content: "\e8c4";
}
%lnr-communication:before, .lnr-communication:before {
  content: "\e8c5";
}
%lnr-communication-crossed:before, .lnr-communication-crossed:before {
  content: "\e8c6";
}
%lnr-broadcast:before, .lnr-broadcast:before {
  content: "\e8c7";
}
%lnr-antenna:before, .lnr-antenna:before {
  content: "\e8c8";
}
%lnr-satellite:before, .lnr-satellite:before {
  content: "\e8c9";
}
%lnr-satellite2:before, .lnr-satellite2:before {
  content: "\e8ca";
}
%lnr-mic:before, .lnr-mic:before {
  content: "\e8cb";
}
%lnr-mic-mute:before, .lnr-mic-mute:before {
  content: "\e8cc";
}
%lnr-mic2:before, .lnr-mic2:before {
  content: "\e8cd";
}
%lnr-spotlights:before, .lnr-spotlights:before {
  content: "\e8ce";
}
%lnr-hourglass:before, .lnr-hourglass:before {
  content: "\e8cf";
}
%lnr-loading:before, .lnr-loading:before {
  content: "\e8d0";
}
%lnr-loading2:before, .lnr-loading2:before {
  content: "\e8d1";
}
%lnr-loading3:before, .lnr-loading3:before {
  content: "\e8d2";
}
%lnr-refresh:before, .lnr-refresh:before {
  content: "\e8d3";
}
%lnr-refresh2:before, .lnr-refresh2:before {
  content: "\e8d4";
}
%lnr-undo:before, .lnr-undo:before {
  content: "\e8d5";
}
%lnr-redo:before, .lnr-redo:before {
  content: "\e8d6";
}
%lnr-jump2:before, .lnr-jump2:before {
  content: "\e8d7";
}
%lnr-undo2:before, .lnr-undo2:before {
  content: "\e8d8";
}
%lnr-redo2:before, .lnr-redo2:before {
  content: "\e8d9";
}
%lnr-sync:before, .lnr-sync:before {
  content: "\e8da";
}
%lnr-repeat-one2:before, .lnr-repeat-one2:before {
  content: "\e8db";
}
%lnr-sync-crossed:before, .lnr-sync-crossed:before {
  content: "\e8dc";
}
%lnr-sync2:before, .lnr-sync2:before {
  content: "\e8dd";
}
%lnr-repeat-one3:before, .lnr-repeat-one3:before {
  content: "\e8de";
}
%lnr-sync-crossed2:before, .lnr-sync-crossed2:before {
  content: "\e8df";
}
%lnr-return:before, .lnr-return:before {
  content: "\e8e0";
}
%lnr-return2:before, .lnr-return2:before {
  content: "\e8e1";
}
%lnr-refund:before, .lnr-refund:before {
  content: "\e8e2";
}
%lnr-history:before, .lnr-history:before {
  content: "\e8e3";
}
%lnr-history2:before, .lnr-history2:before {
  content: "\e8e4";
}
%lnr-self-timer:before, .lnr-self-timer:before {
  content: "\e8e5";
}
%lnr-clock:before, .lnr-clock:before {
  content: "\e8e6";
}
%lnr-clock2:before, .lnr-clock2:before {
  content: "\e8e7";
}
%lnr-clock3:before, .lnr-clock3:before {
  content: "\e8e8";
}
%lnr-watch:before, .lnr-watch:before {
  content: "\e8e9";
}
%lnr-alarm2:before, .lnr-alarm2:before {
  content: "\e8ea";
}
%lnr-alarm-add2:before, .lnr-alarm-add2:before {
  content: "\e8eb";
}
%lnr-alarm-remove:before, .lnr-alarm-remove:before {
  content: "\e8ec";
}
%lnr-alarm-check:before, .lnr-alarm-check:before {
  content: "\e8ed";
}
%lnr-alarm-error:before, .lnr-alarm-error:before {
  content: "\e8ee";
}
%lnr-timer:before, .lnr-timer:before {
  content: "\e8ef";
}
%lnr-timer-crossed:before, .lnr-timer-crossed:before {
  content: "\e8f0";
}
%lnr-timer2:before, .lnr-timer2:before {
  content: "\e8f1";
}
%lnr-timer-crossed2:before, .lnr-timer-crossed2:before {
  content: "\e8f2";
}
%lnr-download:before, .lnr-download:before {
  content: "\e8f3";
}
%lnr-upload:before, .lnr-upload:before {
  content: "\e8f4";
}
%lnr-download2:before, .lnr-download2:before {
  content: "\e8f5";
}
%lnr-upload2:before, .lnr-upload2:before {
  content: "\e8f6";
}
%lnr-enter-up:before, .lnr-enter-up:before {
  content: "\e8f7";
}
%lnr-enter-down:before, .lnr-enter-down:before {
  content: "\e8f8";
}
%lnr-enter-left:before, .lnr-enter-left:before {
  content: "\e8f9";
}
%lnr-enter-right:before, .lnr-enter-right:before {
  content: "\e8fa";
}
%lnr-exit-up:before, .lnr-exit-up:before {
  content: "\e8fb";
}
%lnr-exit-down:before, .lnr-exit-down:before {
  content: "\e8fc";
}
%lnr-exit-left:before, .lnr-exit-left:before {
  content: "\e8fd";
}
%lnr-exit-right:before, .lnr-exit-right:before {
  content: "\e8fe";
}
%lnr-enter-up2:before, .lnr-enter-up2:before {
  content: "\e8ff";
}
%lnr-enter-down2:before, .lnr-enter-down2:before {
  content: "\e900";
}
%lnr-enter-vertical:before, .lnr-enter-vertical:before {
  content: "\e901";
}
%lnr-enter-left2:before, .lnr-enter-left2:before {
  content: "\e902";
}
%lnr-enter-right2:before, .lnr-enter-right2:before {
  content: "\e903";
}
%lnr-enter-horizontal:before, .lnr-enter-horizontal:before {
  content: "\e904";
}
%lnr-exit-up2:before, .lnr-exit-up2:before {
  content: "\e905";
}
%lnr-exit-down2:before, .lnr-exit-down2:before {
  content: "\e906";
}
%lnr-exit-left2:before, .lnr-exit-left2:before {
  content: "\e907";
}
%lnr-exit-right2:before, .lnr-exit-right2:before {
  content: "\e908";
}
%lnr-cli:before, .lnr-cli:before {
  content: "\e909";
}
%lnr-bug:before, .lnr-bug:before {
  content: "\e90a";
}
%lnr-code:before, .lnr-code:before {
  content: "\e90b";
}
%lnr-file-code:before, .lnr-file-code:before {
  content: "\e90c";
}
%lnr-file-image:before, .lnr-file-image:before {
  content: "\e90d";
}
%lnr-file-zip:before, .lnr-file-zip:before {
  content: "\e90e";
}
%lnr-file-audio:before, .lnr-file-audio:before {
  content: "\e90f";
}
%lnr-file-video:before, .lnr-file-video:before {
  content: "\e910";
}
%lnr-file-preview:before, .lnr-file-preview:before {
  content: "\e911";
}
%lnr-file-charts:before, .lnr-file-charts:before {
  content: "\e912";
}
%lnr-file-stats:before, .lnr-file-stats:before {
  content: "\e913";
}
%lnr-file-spreadsheet:before, .lnr-file-spreadsheet:before {
  content: "\e914";
}
%lnr-link:before, .lnr-link:before {
  content: "\e915";
}
%lnr-unlink:before, .lnr-unlink:before {
  content: "\e916";
}
%lnr-link2:before, .lnr-link2:before {
  content: "\e917";
}
%lnr-unlink2:before, .lnr-unlink2:before {
  content: "\e918";
}
%lnr-thumbs-up:before, .lnr-thumbs-up:before {
  content: "\e919";
}
%lnr-thumbs-down:before, .lnr-thumbs-down:before {
  content: "\e91a";
}
%lnr-thumbs-up2:before, .lnr-thumbs-up2:before {
  content: "\e91b";
}
%lnr-thumbs-down2:before, .lnr-thumbs-down2:before {
  content: "\e91c";
}
%lnr-thumbs-up3:before, .lnr-thumbs-up3:before {
  content: "\e91d";
}
%lnr-thumbs-down3:before, .lnr-thumbs-down3:before {
  content: "\e91e";
}
%lnr-share:before, .lnr-share:before {
  content: "\e91f";
}
%lnr-share2:before, .lnr-share2:before {
  content: "\e920";
}
%lnr-share3:before, .lnr-share3:before {
  content: "\e921";
}
%lnr-magnifier:before, .lnr-magnifier:before {
  content: "\e922";
}
%lnr-file-search:before, .lnr-file-search:before {
  content: "\e923";
}
%lnr-find-replace:before, .lnr-find-replace:before {
  content: "\e924";
}
%lnr-zoom-in:before, .lnr-zoom-in:before {
  content: "\e925";
}
%lnr-zoom-out:before, .lnr-zoom-out:before {
  content: "\e926";
}
%lnr-loupe:before, .lnr-loupe:before {
  content: "\e927";
}
%lnr-loupe-zoom-in:before, .lnr-loupe-zoom-in:before {
  content: "\e928";
}
%lnr-loupe-zoom-out:before, .lnr-loupe-zoom-out:before {
  content: "\e929";
}
%lnr-cross:before, .lnr-cross:before {
  content: "\e92a";
}
%lnr-menu:before, .lnr-menu:before {
  content: "\e92b";
}
%lnr-list:before, .lnr-list:before {
  content: "\e92c";
}
%lnr-list2:before, .lnr-list2:before {
  content: "\e92d";
}
%lnr-list3:before, .lnr-list3:before {
  content: "\e92e";
}
%lnr-menu2:before, .lnr-menu2:before {
  content: "\e92f";
}
%lnr-list4:before, .lnr-list4:before {
  content: "\e930";
}
%lnr-menu3:before, .lnr-menu3:before {
  content: "\e931";
}
%lnr-exclamation:before, .lnr-exclamation:before {
  content: "\e932";
}
%lnr-question:before, .lnr-question:before {
  content: "\e933";
}
%lnr-check:before, .lnr-check:before {
  content: "\e934";
}
%lnr-cross2:before, .lnr-cross2:before {
  content: "\e935";
}
%lnr-plus:before, .lnr-plus:before {
  content: "\e936";
}
%lnr-minus:before, .lnr-minus:before {
  content: "\e937";
}
%lnr-percent:before, .lnr-percent:before {
  content: "\e938";
}
%lnr-chevron-up:before, .lnr-chevron-up:before {
  content: "\e939";
}
%lnr-chevron-down:before, .lnr-chevron-down:before {
  content: "\e93a";
}
%lnr-chevron-left:before, .lnr-chevron-left:before {
  content: "\e93b";
}
%lnr-chevron-right:before, .lnr-chevron-right:before {
  content: "\e93c";
}
%lnr-chevrons-expand-vertical:before, .lnr-chevrons-expand-vertical:before {
  content: "\e93d";
}
%lnr-chevrons-expand-horizontal:before, .lnr-chevrons-expand-horizontal:before {
  content: "\e93e";
}
%lnr-chevrons-contract-vertical:before, .lnr-chevrons-contract-vertical:before {
  content: "\e93f";
}
%lnr-chevrons-contract-horizontal:before, .lnr-chevrons-contract-horizontal:before {
  content: "\e940";
}
%lnr-arrow-up:before, .lnr-arrow-up:before {
  content: "\e941";
}
%lnr-arrow-down:before, .lnr-arrow-down:before {
  content: "\e942";
}
%lnr-arrow-left:before, .lnr-arrow-left:before {
  content: "\e943";
}
%lnr-arrow-right:before, .lnr-arrow-right:before {
  content: "\e944";
}
%lnr-arrow-up-right:before, .lnr-arrow-up-right:before {
  content: "\e945";
}
%lnr-arrows-merge:before, .lnr-arrows-merge:before {
  content: "\e946";
}
%lnr-arrows-split:before, .lnr-arrows-split:before {
  content: "\e947";
}
%lnr-arrow-divert:before, .lnr-arrow-divert:before {
  content: "\e948";
}
%lnr-arrow-return:before, .lnr-arrow-return:before {
  content: "\e949";
}
%lnr-expand:before, .lnr-expand:before {
  content: "\e94a";
}
%lnr-contract:before, .lnr-contract:before {
  content: "\e94b";
}
%lnr-expand2:before, .lnr-expand2:before {
  content: "\e94c";
}
%lnr-contract2:before, .lnr-contract2:before {
  content: "\e94d";
}
%lnr-move:before, .lnr-move:before {
  content: "\e94e";
}
%lnr-tab:before, .lnr-tab:before {
  content: "\e94f";
}
%lnr-arrow-wave:before, .lnr-arrow-wave:before {
  content: "\e950";
}
%lnr-expand3:before, .lnr-expand3:before {
  content: "\e951";
}
%lnr-expand4:before, .lnr-expand4:before {
  content: "\e952";
}
%lnr-contract3:before, .lnr-contract3:before {
  content: "\e953";
}
%lnr-notification:before, .lnr-notification:before {
  content: "\e954";
}
%lnr-warning:before, .lnr-warning:before {
  content: "\e955";
}
%lnr-notification-circle:before, .lnr-notification-circle:before {
  content: "\e956";
}
%lnr-question-circle:before, .lnr-question-circle:before {
  content: "\e957";
}
%lnr-menu-circle:before, .lnr-menu-circle:before {
  content: "\e958";
}
%lnr-checkmark-circle:before, .lnr-checkmark-circle:before {
  content: "\e959";
}
%lnr-cross-circle:before, .lnr-cross-circle:before {
  content: "\e95a";
}
%lnr-plus-circle:before, .lnr-plus-circle:before {
  content: "\e95b";
}
%lnr-circle-minus:before, .lnr-circle-minus:before {
  content: "\e95c";
}
%lnr-percent-circle:before, .lnr-percent-circle:before {
  content: "\e95d";
}
%lnr-arrow-up-circle:before, .lnr-arrow-up-circle:before {
  content: "\e95e";
}
%lnr-arrow-down-circle:before, .lnr-arrow-down-circle:before {
  content: "\e95f";
}
%lnr-arrow-left-circle:before, .lnr-arrow-left-circle:before {
  content: "\e960";
}
%lnr-arrow-right-circle:before, .lnr-arrow-right-circle:before {
  content: "\e961";
}
%lnr-chevron-up-circle:before, .lnr-chevron-up-circle:before {
  content: "\e962";
}
%lnr-chevron-down-circle:before, .lnr-chevron-down-circle:before {
  content: "\e963";
}
%lnr-chevron-left-circle:before, .lnr-chevron-left-circle:before {
  content: "\e964";
}
%lnr-chevron-right-circle:before, .lnr-chevron-right-circle:before {
  content: "\e965";
}
%lnr-backward-circle:before, .lnr-backward-circle:before {
  content: "\e966";
}
%lnr-first-circle:before, .lnr-first-circle:before {
  content: "\e967";
}
%lnr-previous-circle:before, .lnr-previous-circle:before {
  content: "\e968";
}
%lnr-stop-circle:before, .lnr-stop-circle:before {
  content: "\e969";
}
%lnr-play-circle:before, .lnr-play-circle:before {
  content: "\e96a";
}
%lnr-pause-circle:before, .lnr-pause-circle:before {
  content: "\e96b";
}
%lnr-next-circle:before, .lnr-next-circle:before {
  content: "\e96c";
}
%lnr-last-circle:before, .lnr-last-circle:before {
  content: "\e96d";
}
%lnr-forward-circle:before, .lnr-forward-circle:before {
  content: "\e96e";
}
%lnr-eject-circle:before, .lnr-eject-circle:before {
  content: "\e96f";
}
%lnr-crop:before, .lnr-crop:before {
  content: "\e970";
}
%lnr-frame-expand:before, .lnr-frame-expand:before {
  content: "\e971";
}
%lnr-frame-contract:before, .lnr-frame-contract:before {
  content: "\e972";
}
%lnr-focus:before, .lnr-focus:before {
  content: "\e973";
}
%lnr-transform:before, .lnr-transform:before {
  content: "\e974";
}
%lnr-grid:before, .lnr-grid:before {
  content: "\e975";
}
%lnr-grid-crossed:before, .lnr-grid-crossed:before {
  content: "\e976";
}
%lnr-layers:before, .lnr-layers:before {
  content: "\e977";
}
%lnr-layers-crossed:before, .lnr-layers-crossed:before {
  content: "\e978";
}
%lnr-toggle:before, .lnr-toggle:before {
  content: "\e979";
}
%lnr-rulers:before, .lnr-rulers:before {
  content: "\e97a";
}
%lnr-ruler:before, .lnr-ruler:before {
  content: "\e97b";
}
%lnr-funnel:before, .lnr-funnel:before {
  content: "\e97c";
}
%lnr-flip-horizontal:before, .lnr-flip-horizontal:before {
  content: "\e97d";
}
%lnr-flip-vertical:before, .lnr-flip-vertical:before {
  content: "\e97e";
}
%lnr-flip-horizontal2:before, .lnr-flip-horizontal2:before {
  content: "\e97f";
}
%lnr-flip-vertical2:before, .lnr-flip-vertical2:before {
  content: "\e980";
}
%lnr-angle:before, .lnr-angle:before {
  content: "\e981";
}
%lnr-angle2:before, .lnr-angle2:before {
  content: "\e982";
}
%lnr-subtract:before, .lnr-subtract:before {
  content: "\e983";
}
%lnr-combine:before, .lnr-combine:before {
  content: "\e984";
}
%lnr-intersect:before, .lnr-intersect:before {
  content: "\e985";
}
%lnr-exclude:before, .lnr-exclude:before {
  content: "\e986";
}
%lnr-align-center-vertical:before, .lnr-align-center-vertical:before {
  content: "\e987";
}
%lnr-align-right:before, .lnr-align-right:before {
  content: "\e988";
}
%lnr-align-bottom:before, .lnr-align-bottom:before {
  content: "\e989";
}
%lnr-align-left:before, .lnr-align-left:before {
  content: "\e98a";
}
%lnr-align-center-horizontal:before, .lnr-align-center-horizontal:before {
  content: "\e98b";
}
%lnr-align-top:before, .lnr-align-top:before {
  content: "\e98c";
}
%lnr-square:before, .lnr-square:before {
  content: "\e98d";
}
%lnr-plus-square:before, .lnr-plus-square:before {
  content: "\e98e";
}
%lnr-minus-square:before, .lnr-minus-square:before {
  content: "\e98f";
}
%lnr-percent-square:before, .lnr-percent-square:before {
  content: "\e990";
}
%lnr-arrow-up-square:before, .lnr-arrow-up-square:before {
  content: "\e991";
}
%lnr-arrow-down-square:before, .lnr-arrow-down-square:before {
  content: "\e992";
}
%lnr-arrow-left-square:before, .lnr-arrow-left-square:before {
  content: "\e993";
}
%lnr-arrow-right-square:before, .lnr-arrow-right-square:before {
  content: "\e994";
}
%lnr-chevron-up-square:before, .lnr-chevron-up-square:before {
  content: "\e995";
}
%lnr-chevron-down-square:before, .lnr-chevron-down-square:before {
  content: "\e996";
}
%lnr-chevron-left-square:before, .lnr-chevron-left-square:before {
  content: "\e997";
}
%lnr-chevron-right-square:before, .lnr-chevron-right-square:before {
  content: "\e998";
}
%lnr-check-square:before, .lnr-check-square:before {
  content: "\e999";
}
%lnr-cross-square:before, .lnr-cross-square:before {
  content: "\e99a";
}
%lnr-menu-square:before, .lnr-menu-square:before {
  content: "\e99b";
}
%lnr-prohibited:before, .lnr-prohibited:before {
  content: "\e99c";
}
%lnr-circle:before, .lnr-circle:before {
  content: "\e99d";
}
%lnr-radio-button:before, .lnr-radio-button:before {
  content: "\e99e";
}
%lnr-ligature:before, .lnr-ligature:before {
  content: "\e99f";
}
%lnr-text-format:before, .lnr-text-format:before {
  content: "\e9a0";
}
%lnr-text-format-remove:before, .lnr-text-format-remove:before {
  content: "\e9a1";
}
%lnr-text-size:before, .lnr-text-size:before {
  content: "\e9a2";
}
%lnr-bold:before, .lnr-bold:before {
  content: "\e9a3";
}
%lnr-italic:before, .lnr-italic:before {
  content: "\e9a4";
}
%lnr-underline:before, .lnr-underline:before {
  content: "\e9a5";
}
%lnr-strikethrough:before, .lnr-strikethrough:before {
  content: "\e9a6";
}
%lnr-highlight:before, .lnr-highlight:before {
  content: "\e9a7";
}
%lnr-text-align-left:before, .lnr-text-align-left:before {
  content: "\e9a8";
}
%lnr-text-align-center:before, .lnr-text-align-center:before {
  content: "\e9a9";
}
%lnr-text-align-right:before, .lnr-text-align-right:before {
  content: "\e9aa";
}
%lnr-text-align-justify:before, .lnr-text-align-justify:before {
  content: "\e9ab";
}
%lnr-line-spacing:before, .lnr-line-spacing:before {
  content: "\e9ac";
}
%lnr-indent-increase:before, .lnr-indent-increase:before {
  content: "\e9ad";
}
%lnr-indent-decrease:before, .lnr-indent-decrease:before {
  content: "\e9ae";
}
%lnr-text-wrap:before, .lnr-text-wrap:before {
  content: "\e9af";
}
%lnr-pilcrow:before, .lnr-pilcrow:before {
  content: "\e9b0";
}
%lnr-direction-ltr:before, .lnr-direction-ltr:before {
  content: "\e9b1";
}
%lnr-direction-rtl:before, .lnr-direction-rtl:before {
  content: "\e9b2";
}
%lnr-page-break:before, .lnr-page-break:before {
  content: "\e9b3";
}
%lnr-page-break2:before, .lnr-page-break2:before {
  content: "\e9b4";
}
%lnr-sort-alpha-asc:before, .lnr-sort-alpha-asc:before {
  content: "\e9b5";
}
%lnr-sort-alpha-desc:before, .lnr-sort-alpha-desc:before {
  content: "\e9b6";
}
%lnr-sort-numeric-asc:before, .lnr-sort-numeric-asc:before {
  content: "\e9b7";
}
%lnr-sort-numeric-desc:before, .lnr-sort-numeric-desc:before {
  content: "\e9b8";
}
%lnr-sort-amount-asc:before, .lnr-sort-amount-asc:before {
  content: "\e9b9";
}
%lnr-sort-amount-desc:before, .lnr-sort-amount-desc:before {
  content: "\e9ba";
}
%lnr-sort-time-asc:before, .lnr-sort-time-asc:before {
  content: "\e9bb";
}
%lnr-sort-time-desc:before, .lnr-sort-time-desc:before {
  content: "\e9bc";
}
%lnr-sigma:before, .lnr-sigma:before {
  content: "\e9bd";
}
%lnr-pencil-line:before, .lnr-pencil-line:before {
  content: "\e9be";
}
%lnr-hand:before, .lnr-hand:before {
  content: "\e9bf";
}
%lnr-pointer-up:before, .lnr-pointer-up:before {
  content: "\e9c0";
}
%lnr-pointer-right:before, .lnr-pointer-right:before {
  content: "\e9c1";
}
%lnr-pointer-down:before, .lnr-pointer-down:before {
  content: "\e9c2";
}
%lnr-pointer-left:before, .lnr-pointer-left:before {
  content: "\e9c3";
}
%lnr-finger-tap:before, .lnr-finger-tap:before {
  content: "\e9c4";
}
%lnr-fingers-tap:before, .lnr-fingers-tap:before {
  content: "\e9c5";
}
%lnr-reminder:before, .lnr-reminder:before {
  content: "\e9c6";
}
%lnr-fingers-crossed:before, .lnr-fingers-crossed:before {
  content: "\e9c7";
}
%lnr-fingers-victory:before, .lnr-fingers-victory:before {
  content: "\e9c8";
}
%lnr-gesture-zoom:before, .lnr-gesture-zoom:before {
  content: "\e9c9";
}
%lnr-gesture-pinch:before, .lnr-gesture-pinch:before {
  content: "\e9ca";
}
%lnr-fingers-scroll-horizontal:before, .lnr-fingers-scroll-horizontal:before {
  content: "\e9cb";
}
%lnr-fingers-scroll-vertical:before, .lnr-fingers-scroll-vertical:before {
  content: "\e9cc";
}
%lnr-fingers-scroll-left:before, .lnr-fingers-scroll-left:before {
  content: "\e9cd";
}
%lnr-fingers-scroll-right:before, .lnr-fingers-scroll-right:before {
  content: "\e9ce";
}
%lnr-hand2:before, .lnr-hand2:before {
  content: "\e9cf";
}
%lnr-pointer-up2:before, .lnr-pointer-up2:before {
  content: "\e9d0";
}
%lnr-pointer-right2:before, .lnr-pointer-right2:before {
  content: "\e9d1";
}
%lnr-pointer-down2:before, .lnr-pointer-down2:before {
  content: "\e9d2";
}
%lnr-pointer-left2:before, .lnr-pointer-left2:before {
  content: "\e9d3";
}
%lnr-finger-tap2:before, .lnr-finger-tap2:before {
  content: "\e9d4";
}
%lnr-fingers-tap2:before, .lnr-fingers-tap2:before {
  content: "\e9d5";
}
%lnr-reminder2:before, .lnr-reminder2:before {
  content: "\e9d6";
}
%lnr-gesture-zoom2:before, .lnr-gesture-zoom2:before {
  content: "\e9d7";
}
%lnr-gesture-pinch2:before, .lnr-gesture-pinch2:before {
  content: "\e9d8";
}
%lnr-fingers-scroll-horizontal2:before, .lnr-fingers-scroll-horizontal2:before {
  content: "\e9d9";
}
%lnr-fingers-scroll-vertical2:before, .lnr-fingers-scroll-vertical2:before {
  content: "\e9da";
}
%lnr-fingers-scroll-left2:before, .lnr-fingers-scroll-left2:before {
  content: "\e9db";
}
%lnr-fingers-scroll-right2:before, .lnr-fingers-scroll-right2:before {
  content: "\e9dc";
}
%lnr-fingers-scroll-vertical3:before, .lnr-fingers-scroll-vertical3:before {
  content: "\e9dd";
}
%lnr-border-style:before, .lnr-border-style:before {
  content: "\e9de";
}
%lnr-border-all:before, .lnr-border-all:before {
  content: "\e9df";
}
%lnr-border-outer:before, .lnr-border-outer:before {
  content: "\e9e0";
}
%lnr-border-inner:before, .lnr-border-inner:before {
  content: "\e9e1";
}
%lnr-border-top:before, .lnr-border-top:before {
  content: "\e9e2";
}
%lnr-border-horizontal:before, .lnr-border-horizontal:before {
  content: "\e9e3";
}
%lnr-border-bottom:before, .lnr-border-bottom:before {
  content: "\e9e4";
}
%lnr-border-left:before, .lnr-border-left:before {
  content: "\e9e5";
}
%lnr-border-vertical:before, .lnr-border-vertical:before {
  content: "\e9e6";
}
%lnr-border-right:before, .lnr-border-right:before {
  content: "\e9e7";
}
%lnr-border-none:before, .lnr-border-none:before {
  content: "\e9e8";
}
%lnr-ellipsis:before, .lnr-ellipsis:before {
  content: "\e9e9";
}
%lnr-uni21:before, .lnr-uni21:before {
  content: "\21";
}
%lnr-uni22:before, .lnr-uni22:before {
  content: "\22";
}
%lnr-uni23:before, .lnr-uni23:before {
  content: "\23";
}
%lnr-uni24:before, .lnr-uni24:before {
  content: "\24";
}
%lnr-uni25:before, .lnr-uni25:before {
  content: "\25";
}
%lnr-uni26:before, .lnr-uni26:before {
  content: "\26";
}
%lnr-uni27:before, .lnr-uni27:before {
  content: "\27";
}
%lnr-uni28:before, .lnr-uni28:before {
  content: "\28";
}
%lnr-uni29:before, .lnr-uni29:before {
  content: "\29";
}
%lnr-uni2a:before, .lnr-uni2a:before {
  content: "\2a";
}
%lnr-uni2b:before, .lnr-uni2b:before {
  content: "\2b";
}
%lnr-uni2c:before, .lnr-uni2c:before {
  content: "\2c";
}
%lnr-uni2d:before, .lnr-uni2d:before {
  content: "\2d";
}
%lnr-uni2e:before, .lnr-uni2e:before {
  content: "\2e";
}
%lnr-uni2f:before, .lnr-uni2f:before {
  content: "\2f";
}
%lnr-uni30:before, .lnr-uni30:before {
  content: "\30";
}
%lnr-uni31:before, .lnr-uni31:before {
  content: "\31";
}
%lnr-uni32:before, .lnr-uni32:before {
  content: "\32";
}
%lnr-uni33:before, .lnr-uni33:before {
  content: "\33";
}
%lnr-uni34:before, .lnr-uni34:before {
  content: "\34";
}
%lnr-uni35:before, .lnr-uni35:before {
  content: "\35";
}
%lnr-uni36divbrdiv:before, .lnr-uni36divbrdiv:before {
  content: "\36";
}
%lnr-uni37:before, .lnr-uni37:before {
  content: "\37";
}
%lnr-uni38:before, .lnr-uni38:before {
  content: "\38";
}
%lnr-uni39:before, .lnr-uni39:before {
  content: "\39";
}
%lnr-uni3a:before, .lnr-uni3a:before {
  content: "\3a";
}
%lnr-uni3b:before, .lnr-uni3b:before {
  content: "\3b";
}
%lnr-uni3c:before, .lnr-uni3c:before {
  content: "\3c";
}
%lnr-uni3d:before, .lnr-uni3d:before {
  content: "\3d";
}
%lnr-uni3e:before, .lnr-uni3e:before {
  content: "\3e";
}
%lnr-uni3f:before, .lnr-uni3f:before {
  content: "\3f";
}
%lnr-uni40:before, .lnr-uni40:before {
  content: "\40";
}
%lnr-uni41:before, .lnr-uni41:before {
  content: "\41";
}
%lnr-uni42:before, .lnr-uni42:before {
  content: "\42";
}
%lnr-uni43:before, .lnr-uni43:before {
  content: "\43";
}
%lnr-uni44:before, .lnr-uni44:before {
  content: "\44";
}
%lnr-uni45:before, .lnr-uni45:before {
  content: "\45";
}
%lnr-uni46:before, .lnr-uni46:before {
  content: "\46";
}
%lnr-uni47:before, .lnr-uni47:before {
  content: "\47";
}
%lnr-uni48:before, .lnr-uni48:before {
  content: "\48";
}
%lnr-uni49:before, .lnr-uni49:before {
  content: "\49";
}
%lnr-uni4a:before, .lnr-uni4a:before {
  content: "\4a";
}
%lnr-uni4b:before, .lnr-uni4b:before {
  content: "\4b";
}
%lnr-uni4c:before, .lnr-uni4c:before {
  content: "\4c";
}
%lnr-uni4d:before, .lnr-uni4d:before {
  content: "\4d";
}
%lnr-uni4e:before, .lnr-uni4e:before {
  content: "\4e";
}
%lnr-uni4f:before, .lnr-uni4f:before {
  content: "\4f";
}
%lnr-uni50:before, .lnr-uni50:before {
  content: "\50";
}
%lnr-uni51:before, .lnr-uni51:before {
  content: "\51";
}
%lnr-uni52:before, .lnr-uni52:before {
  content: "\52";
}
%lnr-uni53:before, .lnr-uni53:before {
  content: "\53";
}
%lnr-uni54:before, .lnr-uni54:before {
  content: "\54";
}
%lnr-uni55:before, .lnr-uni55:before {
  content: "\55";
}
%lnr-uni56:before, .lnr-uni56:before {
  content: "\56";
}
%lnr-uni57:before, .lnr-uni57:before {
  content: "\57";
}
%lnr-uni58:before, .lnr-uni58:before {
  content: "\58";
}
%lnr-uni59:before, .lnr-uni59:before {
  content: "\59";
}
%lnr-uni5a:before, .lnr-uni5a:before {
  content: "\5a";
}
%lnr-uni5b:before, .lnr-uni5b:before {
  content: "\5b";
}
%lnr-uni5c:before, .lnr-uni5c:before {
  content: "\5c";
}
%lnr-uni5d:before, .lnr-uni5d:before {
  content: "\5d";
}
%lnr-uni5e:before, .lnr-uni5e:before {
  content: "\5e";
}
%lnr-uni5f:before, .lnr-uni5f:before {
  content: "\5f";
}
%lnr-uni60:before, .lnr-uni60:before {
  content: "\60";
}
%lnr-uni61:before, .lnr-uni61:before {
  content: "\61";
}
%lnr-uni62:before, .lnr-uni62:before {
  content: "\62";
}
%lnr-uni63:before, .lnr-uni63:before {
  content: "\63";
}
%lnr-uni64:before, .lnr-uni64:before {
  content: "\64";
}
%lnr-uni65:before, .lnr-uni65:before {
  content: "\65";
}
%lnr-uni66:before, .lnr-uni66:before {
  content: "\66";
}
%lnr-uni67:before, .lnr-uni67:before {
  content: "\67";
}
%lnr-uni68:before, .lnr-uni68:before {
  content: "\68";
}
%lnr-uni69:before, .lnr-uni69:before {
  content: "\69";
}
%lnr-uni6a:before, .lnr-uni6a:before {
  content: "\6a";
}
%lnr-uni6b:before, .lnr-uni6b:before {
  content: "\6b";
}
%lnr-uni6c:before, .lnr-uni6c:before {
  content: "\6c";
}
%lnr-uni6d:before, .lnr-uni6d:before {
  content: "\6d";
}
%lnr-uni6e:before, .lnr-uni6e:before {
  content: "\6e";
}
%lnr-uni6f:before, .lnr-uni6f:before {
  content: "\6f";
}
%lnr-uni70:before, .lnr-uni70:before {
  content: "\70";
}
%lnr-uni71:before, .lnr-uni71:before {
  content: "\71";
}
%lnr-uni72:before, .lnr-uni72:before {
  content: "\72";
}
%lnr-uni73:before, .lnr-uni73:before {
  content: "\73";
}
%lnr-uni74:before, .lnr-uni74:before {
  content: "\74";
}
%lnr-uni75:before, .lnr-uni75:before {
  content: "\75";
}
%lnr-uni76:before, .lnr-uni76:before {
  content: "\76";
}
%lnr-uni77:before, .lnr-uni77:before {
  content: "\77";
}
%lnr-uni78:before, .lnr-uni78:before {
  content: "\78";
}
%lnr-uni79:before, .lnr-uni79:before {
  content: "\79";
}
%lnr-uni7a:before, .lnr-uni7a:before {
  content: "\7a";
}
%lnr-uni7b:before, .lnr-uni7b:before {
  content: "\7b";
}
%lnr-uni7c:before, .lnr-uni7c:before {
  content: "\7c";
}
%lnr-uni7d:before, .lnr-uni7d:before {
  content: "\7d";
}
%lnr-uni7e:before, .lnr-uni7e:before {
  content: "\7e";
}
%lnr-copyright:before, .lnr-copyright:before {
  content: "\a9";
}

#mobileSearchContainer {
    height: calc(100% + 110px);
    padding-top: 23px;
    background-color: $green;

    .container-fluid {
        padding-right: 30px;
        padding-left: 15px;
        position: relative;

        @include onlyOniOS {
            left: -15px;

            .submitBtn {
                margin-right: -27px;
            }
        }
    }

    .searchInput {
        border: none;
        background: transparent;
        color: #fff;
        font-size: 24px;
        font-weight: 700;

        @include placeholder(#fff);

        &::placeholder {
            font-size: 22px;
        }

        @media (max-width: 485px) {
            text-overflow: ellipsis;
        }

        @media (max-width: 415px) {
            max-width: 88%;
        }

        @media (max-width: 395px) {
            max-width: 86%;
        }

        @media (max-width: 360px) {
            max-width: 85%;
        }
    }

    .submitBtn {
        color: #fff;
        padding-right: 12px;
        padding-left: 12px;
        margin-right: -12px;

        .icon {
            position: relative;
            top: 8px;
            font-size: 28px;
        }

        &:hover, &:focus {
            text-decoration: none;
            color: $white-link-hover-color;
        }
    }

    @include media-breakpoint-down(sm) {
        .container-fluid {
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    @include onlyOniOS {
        @include media-breakpoint-down(md) {
            .container-fluid {
                padding-right: 30px;
                padding-left: 15px;
            }
        }
    }
}

.hidden {
    // Fix for wes_maps.
    display: none !important;
}

.modal-wesmaps-showiframe,
.modal-wesmaps-routingiframe {
    .modal-header {
        button.close {
            position: absolute;
            top: 37px;
            right: 16px;
            background-color: #3db60f;
            color: #fff;
            opacity: 1;
            text-shadow: none;
            font-size: 20px;
            padding: 10px;
            z-index: 1;

            @include onlyInIE {
                top: 36px;
            }

            &:focus,
            &:hover {
                opacity: 1 !important;
                color: #fff !important;
                background-color: #267407;
            }

            &:before {
                content: "\e907";
                font-family: 'iconfont-murrelektronik' !important;
            }

            span {
                display: none;
            }
        }
    }
}

.embed-responsive-wesmaps {
    max-height: 850px;
}

.embed-responsive-wesmaps:before {
    @include media-breakpoint-down(sm) {
        padding-top: 90%;
    }
}

@font-face {
    font-family: 'iconfont-murrelektronik';
    font-display: swap;
    src:
        url('../fonts/Iconfont-Murrelektronik/iconfont-murrelektronik.ttf?4ofn1e') format('truetype'),
        url('../fonts/Iconfont-Murrelektronik/iconfont-murrelektronik.woff?4ofn1e') format('woff'),
        url('../fonts/Iconfont-Murrelektronik/iconfont-murrelektronik.svg?4ofn1e#iconfont-murrelektronik') format('svg');
    font-weight: normal;
    font-style: normal;
}

%iconfont-murrelektronik, [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'iconfont-murrelektronik' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

%icon-play:before, .icon-play:before {
    content: "\e900";
}
%icon-cart:before, .icon-cart:before {
    content: "\e901";
}
%icon-world-map:before, .icon-world-map:before {
    content: "\e902";
}
%icon-check:before, .icon-check:before {
    content: "\e903";
}
%icon-position-marker:before, .icon-position-marker:before {
    content: "\e904";
}
%icon-arrow-left:before, .icon-arrow-left:before {
    content: "\e905";
}
%icon-arrow-right:before, .icon-arrow-right:before {
    content: "\e906";
}
%icon-close:before, .icon-close:before {
    content: "\e907";
}
%icon-search:before, .icon-search:before {
    content: "\e908";
}
%icon-chevron-right:before, .icon-chevron-right:before {
    content: "\e909";
}
%icon-chevron-up:before, .icon-chevron-up:before {
    content: "\e90a";
}
%icon-chevron-down:before, .icon-chevron-down:before {
    content: "\e90b";
}
%icon-chevron-left:before, .icon-chevron-left:before {
    content: "\e90c";
}
%icon-check-bold:before, .icon-check-bold:before {
  content: "\e90d";
}
%icon-contact:before, .icon-contact:before {
  content: "\e90e";
}
%icon-wechat-outline:before, .icon-wechat-outline:before {
  content: "\e90f";
}

#languageModal {
    .modal-dialog {
        .modal-footer {
            justify-content: center;
            @include media-breakpoint-down(sm) {
                display: block;
                text-align: center;
            }
            button {
                padding: 11px 12px;
                font-size: 14px;
                @include media-breakpoint-down(sm) {
                    width: 100%;
                    &:first-child {
                        margin-bottom: 16px;
                    }
                    &:last-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

.languageModal {
    padding-right: 0 !important;
    .modal-dialog {
        max-width: 830px;
        margin-top: 64px;
        @include media-breakpoint-down(sm) {
            min-height: 100vh;
            margin: 0;
        }
        .modal-body {
            padding: 30px;
            @include media-breakpoint-down(sm) {
                display: flex;
                flex-direction: column;
                height: 100vh;
            }
            .title {
                margin-top: 0;
            }
            .close {
                position: absolute;
                top: 0px;
                right: 0px;
                background-color: #3db60f;
                color: #fff;
                opacity: 1;
                text-shadow: none;
                font-size: 20px;
                padding: 10px;
                z-index: 1;
                &:before {
                    content: "\e907";
                    font-family: 'iconfont-murrelektronik' !important;
                }
                span {
                    display: none;
                }
            }
            .lang-buttons {
                display: flex;
                justify-content: flex-end;
                margin-top: 29px;
                margin-left: -20px;
                .btn {
                    margin-top: 20px;
                    margin-left: 20px;
                }
                .custom-select {
                    height: 44px;
                    padding: 9px 13px;
                    appearance: none;
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    border-radius: 3px;
                    border-color: #dde0e5;
                    background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 7'%3E%3Cpath fill='rgb(221,224,229)' d='M0 0L11 0L6 7z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
                    background-size: 11px 7px;
                    margin-top: 20px;
                    margin-left: 20px;
                    &:focus {
                        box-shadow: none;
                    }
                }
                @include media-breakpoint-down(sm) {
                    flex-grow: 1;
                    flex-direction: column;
                    margin-top: 10px;
                    z-index: 100;
                    > button {
                        order: 2
                    }
                    > a {
                        order: 1;
                        > button {
                            width: calc(100% - 20px);
                        }
                    }
                    > select {
                        width: calc(100% - 20px);
                    }
                }

            }
        }
        @include media-breakpoint-down(sm) {
            .modal-content {
                min-height: 100vh;
            }
            .lang-message {
                overflow: auto;
            }
        }
    }
}

.languageHeader {
    background-color: #fff;

    & > .container {
        max-width: 1100px;
        padding: 10px 15px 15px 15px;
    } 

    .btnClose {
        float: right;
        margin-right: -10px;
        margin-left: 10px;
        color: #3db60f;
        border: none;
        background: none;
        font-size: 32px;
        line-height: 32px;
        font-weight: 100;
        cursor: pointer;
    }

    .title {
        margin: 0 0 6px 0;
    }

    .content {
        display: flex;
        align-items: center;
        gap: 0 60px;
    }

    .buttons {
        display: flex;
        gap: 15px;
    }

    .btnClose.md,
    .text .btnClose {
        display: none;
    }

    .custom-select {
        height: calc(2.375rem + 6px);
        height: 43px;
        padding: 9px 13px;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        border-radius: 3px;
        border-color: #dde0e5;
        flex-grow: 0;
        width: auto;
        background: #fff url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 7'><path fill='rgb(221,224,229)' d='M0 0L11 0L6 7z'/></svg>") no-repeat right .75rem center;
        background-size: 11px 7px;
    }

    .custom-select:focus {
        border-color: #3db60f;
        outline: 0;
        box-shadow: none;
    }

    @include media-breakpoint-down(lg) {
        .titleContainer {
            display: none;
        }

        .btnClose.md {
            display: block;
        }

        .content {
            gap: 30px;
        }
    }

    @media (max-width: 800px) {
        .content {
            flex-wrap: wrap;
            gap: 10px;
            justify-content: center;
        }

        .btnClose.md {
            display: none;
        }

        .text .btnClose {
            display: block;
        }
    }

    @include media-breakpoint-down(sm) {
        .custom-select {
            width: 100%;
        }
    }
}
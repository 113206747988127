.table-responsive {
    margin-top: 40px;
    margin-bottom: 40px;
}

.table {
    .table-responsive & {
        margin-bottom: 0;
    }

    &:not(.table-bordered) {
        thead {
            th {
                border-top: none;
            }
        }
    }
}
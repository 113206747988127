#header {
    height: 146px;

    @include media-breakpoint-down(md) {
        height: 110px;
    }

    &.affix {
        position: static !important;
        height: 70px;
    }

    &.landingpageHeader {
        .headerTop {
            ul span {
                padding-right: 10px;
                position: relative;
                top: 1px;
                font-size: 14px;
            }
        }

        &.affix {
            .navbar-nav {
                .nav-button {
                    .btn-white {
                        padding-top: 11px !important;
                        padding-bottom: 11px !important;
                    }
                }
            }
        }

        .shopWebsiteSelection {
            a {
                margin-right: -16px;

                @media (max-width: 1101px) {
                    margin-right: -15px;
                    padding-right: 15px;
                }

                @include media-breakpoint-down(md) {
                    margin-right: 0;
                }
            }
        }

        .navbar {
            > .container {
                .navbar-brand {
                    margin-right: auto;
                }

                .ctaButton {
                    margin-right: 10px;

                    @include media-breakpoint-down(md) {
                        display: flex !important;
                    }

                    @media (max-width: 400px) {
                        padding: 7px 10px 9px;
                    }
                }

                .menuText {
                    @media (max-width: 450px) {
                        display: none;
                    }
                }

                .menuIcon {
                    @media (max-width: 450px) {
                        margin-right: 0;
                    }
                }
            }
        }

        .mobileSearchToggle {
            display: none !important;
        }



        .affix {
            .nav-button {
                .nav-link {
                    &.btn {
                        padding-top: 5px;
                        padding-bottom: 4px;
                    }
                }
            }
        }

        .nav-button {
            display: flex;
            align-items: center;

            .nav-link.btn {
                padding: 11px 29px;
                margin-left: 16px;
            }
        }
    }

    .headerTop {
        min-height: 40px;
        background-color: #37a40d;
        color: #fff;
        position: relative;
        z-index: 14;

        > .container {
            display: flex;
            flex-wrap: wrap;
            // max-width: 1470px;
            max-width: 1100px;
        }

        .langSelectionOpener {
            a {
                display: block;
                padding-top: 6px;
                padding-bottom: 8px;
                color: #fff;

                &:hover, &:focus {
                    color: $white-link-hover-color;
                    text-decoration: none;
                }

                .icon {
                    position: relative;
                    top: 3px;
                    padding-right: 10px;
                    font-size: 15px;
                }

                .text {
                    font-size: 14px;
                }

                @include media-breakpoint-down(xs) {
                    display: flex;
                    line-height: 1;
                    align-items: center;
                    padding-top: 5px;
                    padding-bottom: 7px;

                    .icon {
                        top: 2px;
                    }
                }
            }
        }

        .shopWebsiteSelection {
            margin-left: auto;

            ul {
                display: flex;
                margin-bottom: 0;

                @include media-breakpoint-down(xs) {
                    height: 100%;
                }
            }

            a {
                display: block;
                padding: 5px 16px 9px;
                color: #fff;
                font-weight: 700;
                // margin-right: -16px;

                &:hover, &:focus {
                    color: $white-link-hover-color;
                    text-decoration: none;
                }
            }

            li.active a {
                background-color: $green;
            }

            @include media-breakpoint-down(sm) {
                margin-right: -15px;

                li {
                    &.active {
                        display: none;
                    }

                    &:not(.active) {
                        a {
                            font-weight: 700;
                            font-size: 16px;
                            padding-top: 7px;
                            padding-bottom: 7px;

                            @include onlyInGC {
                                padding-top: 6px;
                                padding-bottom: 8px;
                            }
                        }
                    }
                }
            }

            @include media-breakpoint-down(xs) {
                li {
                    &:not(.active) {
                        a {
                            display: flex;
                            align-items: center;
                            height: 100%;
                            font-size: 14px;
                            line-height: 1;
                            text-align: center;
                            padding-top: 6px;
                            padding-bottom: 6px;

                            @include onlyInGC {
                                padding-top: 5px;
                                padding-bottom: 7px;
                            }
                        }
                    }
                }
            }
        }

        .langSelection {
            position: relative;
            display: none;
            width: 100%;
            padding-top: 142px;
            padding-bottom: 136px;

            .closeBtn {
                position: absolute;
                top: 7px;
                right: 1px;
                font-size: 20px;
                color: #fff;
                padding: 14px;

                &:hover, &:focus {
                    text-decoration: none;
                    color: $white-link-hover-color;
                }

                @include media-breakpoint-down(sm) {
                    right: -14px;
                }
            }

            > .container {
                position: relative;
                max-width: 1070px;
                min-height: 624px;
                // min-height: 600px;
                // min-height: 522px;
                padding-right: 0;
                padding-left: 0;

                @media (max-width: 430px) {
                    min-height: 745px;
                }
            }

            .continentSelection {
                padding-left: 8px;

                .header {
                    font-size: 46px;
                    line-height: 50px;

                    @include media-breakpoint-down(sm) {
                        font-size: 34px;
                        line-height: 42px;
                    }
                }

                .nav {
                    margin-top: 25px;
                    margin-left: -16px;
                }

                .nav-item {
                }

                .nav-link {
                    font-size: 30px;
                    line-height: 38px;
                    font-weight: 300;
                    color: #fff;
                    padding-top: 11px;
                    padding-bottom: 11px;

                    &:hover, &:focus {
                        color: $white-link-hover-color;
                    }

                    @include media-breakpoint-down(sm) {
                        font-size: 24px;
                        line-height: 30px;
                    }
                }

                .worldMap {
                    margin-top: 70px;
                }

                .headquarters {
                    margin-top: 52px;

                    .nav-item {
                        display: flex;
                        &:first-child {
                            padding-left: 16px;
                            padding-right: 16px;
                            padding-bottom: 6px;
                            font-size: 14px;
                            font-weight: 700;
                        }

                        &.active {
                            .nav-link {
                                background-color: #fff;
                                color: $green-dark;
                                border-radius: 2px;

                                &:hover {
                                    background-color: $white-link-hover-color;
                                }
                            }
                        }

                        .nav-link {
                            font-size: 16px;
                            line-height: 22px;
                            padding-top: 6px;
                            padding-bottom: 6px;
                            font-weight: 500;
                            padding-left: 8px;
                            padding-right: 8px;
                            margin-left: 8px;
                        }
                    }
                }
            }

            .continent {
                position: absolute;
                top: 0;
                width: 100%;
                overflow: hidden;
                pointer-events: none;
                height: 100%;
                visibility: hidden;
                transition: visibility 0ms linear 300ms;

                .headquarters {
                    margin-top: 52px;

                    .nav-item {
                        display: flex;
                        &:first-child {
                            padding-left: 8px;
                            padding-right: 8px;
                            padding-bottom: 6px;
                            font-size: 14px;
                            font-weight: 700;
                        }

                        &.active {
                            .nav-link {
                                background-color: #fff;
                                color: $green-dark;
                                border-radius: 2px;

                                &:hover {
                                    background-color: $white-link-hover-color;
                                }
                            }
                        }

                        .nav-link {
                            font-size: 16px;
                            line-height: 22px;
                            padding-top: 6px;
                            padding-bottom: 6px;
                            font-weight: 500;
                            padding-left: 8px;
                            padding-right: 8px;
                        }
                    }
                }

                .left, .right {
                    transition: transform 300ms linear;
                    background-color: #37a40d;
                    pointer-events: all;
                }

                .left {
                    transform: translate3d(-100%, 0, 0);
                    padding-left: 8px;

                    .w-33 {
                        margin-right: 15px;

                        + .w-66 {
                            margin-left: 15px;
                        }
                    }
                }

                .right {
                    transform: translate3d(100%, 0, 0);
                }

                &.show {
                    visibility: visible;
                    transition: visibility 0ms linear;

                    .left {
                        transform: translate3d(0, 0, 0);
                    }

                    .right {
                        transform: translate3d(0, 0, 0);
                    }
                }

                .backLink {
                    .btn {
                        color: #fff;
                        font-size: 30px;
                        line-height: 35px;
                        font-weight: 300;
                        margin-top: -8px;
                        margin-left: -11px;
                        padding: 6px 11px 6px;

                        &:hover, &:focus {
                            text-decoration: none;
                            color: $white-link-hover-color;
                        }

                        .icon {
                            position: relative;
                            top: -1px;
                            padding-right: 14px;
                            font-size: 19px;
                        }

                        @include media-breakpoint-down(sm) {
                            font-size: 24px;
                            line-height: 30px;
                        }
                    }
                }

                .header {
                    font-size: 14px;
                    line-height: 22px;
                    font-weight: bold;
                    margin-top: 43px;
                    margin-bottom: 6px;
                }

                .nav {
                    display: block;
                    margin-left: -8px;

                    &.twoColumns {
                        column-count: 2;
                    }

                    &.threeColumns {
                        column-count: 3;
                    }

                    &.salesPartner {
                        .nav-link {
                            font-size: 14px;
                            line-height: 22px;
                            padding-top: 3px;
                            padding-bottom: 5px;

                            &:not(:hover):not(:focus) {
                                color: #9bd186;
                            }
                        }
                    }
                }

                .nav-item {
                    page-break-inside: avoid;
                    break-inside: avoid;
                }

                .nav-link {
                    color: #fff;
                    padding: 6px 8px;
                    line-height: 22px;

                    &:hover, &:focus {
                        color: $white-link-hover-color;
                    }
                }

                .nav-item.active {
                    // padding-left: 8px;
                 .nav-link {
                    background-color: #fff;
                    color: $green-dark;
                    border-radius: 2px;

                        &:hover {
                            background-color: $white-link-hover-color;
                        }
                    }
                }

                .worldMap {
                    margin-top: 129px;
                }
            }

            @media (max-width: 1030.98px) {
                .continent {
                    width: calc(100% + 23px);
                    margin-right: -15px;
                    margin-left: -8px;
                    padding-right: 15px;
                    padding-left: 15px;

                    .left {
                        width: calc(100% + 30px) !important;
                        min-height: 450px;
                        margin-right: -15px;
                        margin-left: -15px;
                        padding-right: 15px;
                        padding-left: 15px;
                    }

                    .right {
                        display: none;
                    }
                }
            }

            @media (max-width: 850.98px) {
                .continentSelection .worldMap {
                    display: none;
                }
            }

            @media (max-width: 500.98px) {
                padding-top: 70px;
                padding-bottom: 80px;

                .continent {
                    .w-33, .w-66 {
                        width: 50% !important;
                    }

                    .nav {
                        &.twoColumns {
                            column-count: 1;
                        }

                        &.threeColumns {
                            column-count: 2;
                        }
                    }
                }
            }
        }
    }
}

.navbar {
    z-index: 100;
    padding: 0;
    transition: all 300ms;

    @include media-breakpoint-down(md) {
        animation-duration: 300ms;
        animation-fill-mode: forwards;
    }

    &.affix {
        width: 100%;
        top: 0;
        // box-shadow: 1px 2px 27px 0px rgba(0, 0, 0, 0.13);
        border-bottom: 1px solid $green-dark;
    }

    &.noAffix {
        position: relative;
    }

    .logoSmall {
        @include media-breakpoint-up(lg) {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            // position: relative;
            // left: -50%;
        }
    }

    #header & {
        .logoSmall {
            display: none;
        }
    }

    #header.affix-top & {
        .logoSmall {
            display: block !important;
        }

        @include media-breakpoint-up(lg) {
            .logoBig {
                opacity: 1;
                // transition: all 300ms linear;
                transition-duration: 300ms;
                transition-property: width, opacity;
                transition-timing-function: linear;
            }

            .logoSmall {
                opacity: 0;
                // transition: all 300ms linear;
                transition-duration: 300ms;
                transition-property: width, opacity;
                transition-timing-function: linear;
            }
        }
    }

    #header.affix:not(.noAffix) & {
        // animation-name: slideInDown; // animation from animate.css
        position: fixed;
        top: 0;
        width: 100%;
        // box-shadow: 1px 2px 27px 0px rgba(0, 0, 0, 0.13);
        border-bottom: 1px solid rgba(38, 116, 7, 0.5);

        .logoSmall {
            display: block !important;
        }

        @include media-breakpoint-up(lg) {
            .logoBig {
            //     // display: none !important;
                opacity: 0;
                // transition: all 300ms;
                transition-duration: 300ms;
                transition-property: width, opacity;
                transition-timing-function: linear;
            }
            //
            .logoSmall {
                // display: block !important;
                opacity: 1;
                // transition: all 300ms;
                display: block !important;
                transition-duration: 300ms;
                transition-property: width, opacity;
                transition-timing-function: linear;
            }
        }

        .navbar-brand {
            height: 70px;
            padding-top: 0;
            transition: 300ms linear;

            @include media-breakpoint-down(md) {
                transition: none;
            }

            img {
                width: 116px;
                transition: 300ms linear;
                padding-top: 16px;
            }
        }

        .navbar-nav {
            > .nav-item {
                > .nav-link {
                    padding-top: 20px;
                    padding-bottom: 21px;
                    transition: padding 300ms linear;

                    &.btn {
                        border: none;
                    }

                    &:before {
                        bottom: 8px;
                    }

                    &:after {
                        bottom: 10px;
                    }

                    span {
                        &:before {
                            bottom: -7px !important;
                        }
                    }
                }
            }
        }
    }

    > .container {
        max-width: 1100px;
        // max-width: 1470px;
    }

    .mobileSearchToggle, .mobileNavToggle {
        &:hover, &:focus {
            text-decoration: none;
            color: $white-link-hover-color;

            .menuIcon span {
                background-color: $white-link-hover-color;
            }
            .cross {
                &:before,
                &:after {
                    background-color: $white-link-hover-color;
                }
            }
        }
    }

    .mobileSearchToggle {
        position: relative;
        width: 45px;
        padding-right: 12px;
        padding-left: 10px;
        color: #fff;
        font-size: 20px;
        line-height: 26px;
        text-align: left;

        .icon-search {
            position: relative;
            top: 4px;
            display: inline-block;
            width: 22px;
            transition: width 150ms ease 300ms;
            overflow: hidden;
            // left: 3px;
            padding-top: 1px;
            // text-align: center;

            &:before {
                position: relative;
                padding-left: 1px;
            }

            // @include onlyOniOS {
            //     top: 4px;
            // }


        }

        .cross {
            display: block;
            position: absolute;
            top: 15px;
            left: 10px;
            width: 22px;
            height: 22px;
            transform: rotate(45deg);

            @include media-breakpoint-down(sm) {
                top: 11px;
            }

            &:before, &:after {
                display: block;
                content: '';
                position: absolute;
                background-color: #fff;
            }

            &:before {
                position: absolute;
                top: 0;
                left: 10px;
                width: 2px;
                height: 0;
                transition:
                    transform .15s ease-in-out,
                    width .15s ease-in-out,
                    height .15s ease-in-out,
                    background-color 0.3s ease
                ;
            }

            &:after {
                position: absolute;
                top: 10px;
                left: 0;
                width: 0;
                height: 2px;
                transition:
                    transform .15s ease-in-out .15s,
                    width .15s ease-in-out .15s,
                    height .15s ease-in-out .15s,
                    background-color 0.3s ease
                ;
            }
        }

        &[aria-expanded="true"] {
            .icon-search {
                width: 0;
                padding-left: 0;
                overflow: hidden;
                transition: width 150ms ease;
            }

            .cross {
                &:before {
                    height: 100%;
                    transition:
                        transform .15s ease-in-out,
                        width .15s ease-in-out,
                        height .15s ease-in-out,
                        background-color 0.3s ease
                    ;
                }

                &:after {
                    width: 100%;
                    transition:
                        transform .15s ease-in-out .15s,
                        width .15s ease-in-out .15s,
                        height .15s ease-in-out .15s,
                        background-color 0.3s ease
                    ;
                }
            }
            // .cross {
            //     &:before {
            //         height: 100%;
            //         transition:
            //             transform .125s ease-in-out .3125s,
            //             width .125s ease-in-out .3125s,
            //             height .125s ease-in-out .3125s,
            //             background-color 0.25s ease
            //         ;
            //     }
            //
            //     &:after {
            //         width: 100%;
            //         transition:
            //             transform .125s ease-in-out .1875s,
            //             width .125s ease-in-out .1875s,
            //             height .125s ease-in-out .1875s,
            //             background-color 0.25s ease
            //         ;
            //     }
            // }
        }
    }

    .mobileNavToggle {
        color: #fff;
        padding-right: 13px;
        padding-left: 13px;
        margin-right: -14px;
        position: relative;

        /////////
        /// TODO testweise drin
        .cross {
            display: block;
            position: absolute;
            top: 15px;
            left: 13px;
            width: 22px;
            height: 22px;
            transform: rotate(45deg);

            @include media-breakpoint-down(sm) {
                top: 11px;
            }

            &:before, &:after {
                display: block;
                content: '';
                position: absolute;
                background-color: #fff;
            }

            &:before {
                position: absolute;
                top: 0;
                left: 10px;
                width: 2px;
                height: 0;
                transition:
                    transform .15s ease-in-out,
                    width .15s ease-in-out,
                    height .15s ease-in-out,
                    background-color 0.3s ease
                ;
            }

            &:after {
                position: absolute;
                top: 10px;
                left: 0;
                width: 0;
                height: 2px;
                transition:
                    transform .15s ease-in-out .15s,
                    width .15s ease-in-out .15s,
                    height .15s ease-in-out .15s,
                    background-color 0.3s ease
                ;
            }
        }

        &[aria-expanded="true"] {
            .menuIcon span {
                width: 0;
                transition: width 150ms ease;
            }

            .cross {
                &:before {
                    height: 100%;
                    transition:
                        transform .15s ease-in-out,
                        width .15s ease-in-out,
                        height .15s ease-in-out,
                        background-color 0.3s ease
                    ;
                }

                &:after {
                    width: 100%;
                    transition:
                        transform .15s ease-in-out .15s,
                        width .15s ease-in-out .15s,
                        height .15s ease-in-out .15s,
                        background-color 0.3s ease
                    ;
                }
            }
        }
        /////

        .menuIcon {
            position: relative;
            top: 5px;
            margin-right: 12px;

            //TODO testweiße drin
            display: inline-block;
            overflow: hidden;

            @extend %menuIcon;
        }

        // TODO testweise draußen
        // &.showOffcanvas {
        //     .menuIcon {
        //         @extend %menuIcon-active;
        //     }
        // }

        &:hover, &:focus {
            .hamburger span, .cross span {
                background-color: $white-link-hover-color;
            }
        }
    }
}

.navbar-expand-lg .navbar-nav {
    > .nav-item {
        > .nav-link {
            position: relative;
            font-size: 18px;
            font-weight: 700;
            color: #fff;
            padding: 35px 16px 42px;
            transition: padding 300ms linear;

            &:before, &:after {
                display: block;
                content: '';
                position: absolute;
                left: 50%;
                transform: scale(0);
                transform-origin: center top;
                transition: transform 300ms linear;
            }

            &:before {
                bottom: 27px;
                margin-left: -7px;

                border-top: 9px solid #fff;
                border-right: 7px solid transparent;
                border-left: 7px solid transparent;
            }

            &:after {
                bottom: 29px;
                margin-left: -6px;

                border-top: 8px solid $green;
                border-right: 6px solid transparent;
                border-left: 6px solid transparent;
            }

            &[aria-expanded="true"] {
                &:before, &:after {
                    transform: scale(1);
                }
            }
        }

        &.search {
            &.show > .nav-link > span:before {
                opacity: 1;
                transition: opacity 0s linear 0ms;
            }

            > .nav-link {
                > span:before {
                    opacity: 0;
                    transition: opacity 0s linear;
                    // transition: opacity 0s linear 300ms;
                }

                // &:hover, &:focus {
                //     color: #dde0e5;
                // }
            }
        }

        @at-root .navigationBackdrop {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            z-index: 1;
            pointer-events: none;
            background-color: rgba(#000, .2);
            opacity: 0;
            transition: opacity 300ms linear;
        }

        .navigationBackdrop {
            top: 100%;
        }

        &.show .navigationBackdrop {
            opacity: 1;
        }
    }

    > .nav-item > .nav-link {
        > span {
            position: relative;

            &:before {
                content: '';
                display: block;
                position: absolute;
                bottom: -9px;
                left: 0;
                right: 0;
                height: 1px;
                background-color: #fff;
                opacity: 0;
            }
        }
    }

    > .nav-item > .nav-link:hover,
    > .nav-item > .nav-link:focus,
    > .nav-item > .nav-link[aria-expanded="true"],
    > .nav-item.active > .nav-link {
        > span {

            &:before {
                opacity: 1;
                transition-delay: 0s !important;
            }
        }
    }

    > .nav-item > .nav-link[aria-expanded="false"] {
        > span {

            &:before {
                transition: opacity 0s;
                transition-delay: 0.3s;
            }
        }
    }


    .nav-link .icon-search {
        position: relative;
        top: 3px;
        font-size: 20px;
    }

    .dropdown {
        position: static;
    }

    .dropdown-menu {
        width: 100%;
        border: none;
        margin-top: 0;
        padding-top: 38px;
        padding-bottom: 39px;

        .nav {
            display: block;
        }

        @include onlyInEdge {
            .nav-item {
                display: block;
            }
        }

        .closeBtnContainer {
            .closeBtn {
                position: absolute;
                right: 0;
                top: 30px;

                .icon {
                    font-size: 20px;
                }
            }
        }

        > .nav {
            // max-width: 1470px;
            max-width: 1100px;
            margin: 0 auto;
            padding-right: 7px;
            padding-left: 7px;
            position: relative;

            .closeBtnLi {
                position: absolute;
                top: 0;
                // top: -15px;
                right: 0;

                .closeBtn {
                    .icon {
                        font-size: 20px;
                    }
                }
            }

            .nav {
                margin-bottom: 3px;
                padding-left: 10px;

                .nav-link {
                    font-weight: 300;
                    padding-top: 5px;
                    padding-bottom: 4px;
                    line-height: 24px;
                }
            }

            > .nav-item {
                overflow: hidden;
                page-break-inside: avoid;

                > .nav-link {
                    font-weight: 700;
                    padding-top: 12px;
                    padding-bottom: 12px;

                    &[target="_blank"]:after {
                        @extend %linearicons, %lnr-launch:before;
                        position: relative;
                        top: 2px;
                        margin-left: 10px;
                    }
                }

                &.hasChildren > .nav-link {
                    padding-bottom: 5px;
                }
            }
        }

        .nav-link {
            color: $body-color;

            &:hover, &:focus {
                color: $green;
            }
        }

        .nav-item.active > .nav-link {
            color: $green;
        }
    }

    .dropdown.search {
        .container {
            max-width: 1100px;
            // max-width: 1470px;
            padding-top: 11px;
            padding-bottom: 11px;
        }

        .searchInput {
            border: none;
            color: $green;
            font-size: 18px;
            line-height: 30px;
            font-weight: bold;
            padding-top: 10px;
            padding-bottom: 10px;

            @include placeholder($green);
        }

        .submitBtn, .closeBtn {
            position: relative;
            top: 2px;
            padding-right: 12px;
            padding-left: 12px;

            .icon {
                font-size: 20px;
            }

            &:hover, &:focus {
                text-decoration: none;
            }
        }

        // .submitBtn {
        //     right: -13px;
        // }

        .closeBtn {
            // margin-left: 4px;
            // margin-right: -13px;
            // position: absolute;
            // right: 18px;
            // top: 18px;
            // transition: top 300ms;
            // transform: translateY(-50%);
            // top: 6px;
            position: relative;
            right: 3px;
            margin-left: 11px;
        }

        &.show {
            .closeBtn {
            }
        }

        .suggestionsContainer {
            width: 100%;

            .headline {
                margin-top: 29px;
                margin-bottom: 15px;
                font-weight: 700;
            }
        }

        .suggestions {
            column-count: 2;
            column-gap: 60px;

            li {
                page-break-inside: avoid;
            }

            a {
                display: flex;
                color: $body-color;
                padding-top: 7px;
                padding-bottom: 7px;

                &:hover, &:focus {
                    color: $green;
                }
            }

            .imageContainer {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                margin-right: 17px;
            }

            .image {
                max-width: 30px;
                height: auto;
                max-height: 30px;
            }

            .highlighted {
                color: $green;
            }

            .category {
                position: relative;
                top: 0px;
                // margin-left: auto;
                font-size: 12px;
                line-height: 20px;
                font-weight: 300;
                padding-left: 30px;
                align-self: center;
            }
        }
    }
}

#mobileSearchContainer {
    overflow-y: scroll;

    .suggestionsContainer {
        width: 100%;

        .headline {
            margin-top: 29px;
            margin-bottom: 15px;
            font-weight: 700;
            color: #fff;
        }
    }

    .suggestions {

        li {
            page-break-inside: avoid;
            padding-bottom: 15px;
        }

        a {
            display: flex;
            color: #fff;
            padding-top: 10px;
            padding-bottom: 10px;

            &:hover, &:focus {
                .text {
                    text-decoration: underline;
                }
            }
        }

        // .text {
        //     display: flex;
        //     align-items: center;
        // }

        .imageContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            margin-right: 17px;
        }

        .image {
            max-width: 30px;
            height: auto;
            max-height: 30px;
        }

        .highlighted {
            // font-weight: bold;
            text-decoration: underline;

            @include media-breakpoint-down(md) {
                text-decoration: none;
            }
        }

        .category {
            position: relative;
            // top: 1px;
            margin-left: auto;
            font-size: 12px;
            line-height: 20px;
            font-weight: 300;
            padding-left: 15px;
            white-space: nowrap;
            align-self: center;
        }
    }
}

.navbar-brand {
    display: flex;
    align-items: center;
    height: 106px;
    padding-top: 0;
    padding-bottom: 0;
    transition: 300ms linear;
    position: relative;

    img {
        width: 164px;
        height: auto;
        transition: 300ms linear;
    }
}

@include media-breakpoint-down(md) {
    #header {
        .headerTop {
            > .container {
                padding-right: 15px;
                padding-left: 15px;
            }
        }
    }

    .navbar-expand-lg > .container {
        padding-right: 30px;
        padding-left: 15px;
    }

    .navbar-brand {
        height: 70px;
        // padding-top: 2px;

        img {
            width: 116px;
            padding-top: 16px;
        }
    }
}

@include media-breakpoint-down(sm) {
    #header {
        .headerTop {
            > .container {
                padding-right: 15px;
                padding-left: 15px;
            }
        }
    }

    .navbar-expand-lg > .container {
        padding-left: 15px;
        padding-right: 15px;
    }
}

body.modal-open.dp--cookie-consent {
    overflow: hidden;
}

.cookieConsentModal {
    padding-right: 0 !important;
    z-index: 1060;

    .modal-dialog {
        max-width: 830px;
        margin-top: 64px;
    }

    .modal-body {
        padding: 30px;
    }

    .title {
        margin-top: 0;
    }

    .dp--cookie-check {
        margin-top: 10px;
        margin-left: -20px;

        .custom-checkbox {
            margin-top: 20px;
        }
    }

    .detailsBtnContainer {
        margin-top: 13px;

        .btn {
            padding: 0;

            .icon {
                position: relative;
                display: inline-block;
                width: 20px;
                height: 18px;
                margin-right: 9px;

                &:before, &:after {
                    display: block;
                    content: '';
                    position: absolute;
                    height: 2px;
                    background-color: currentColor;
                    transition: transform 300ms linear, top 300ms linear;
                }

                &:before {
                    left: 0px;
                    width: 12px;
                    transform-origin: left center;
                }

                &:after {
                    right: 0px;
                    width: 12px;
                    transform-origin: right center;
                }
            }

            &.collapsed .icon {
                &:before, &:after {
                    top: 8px;
                }

                &:before {
                    transform: rotate(30deg);
                }

                &:after {
                    transform: rotate(-30deg);
                }
            }

            &:not(.collapsed) .icon {
                &:before, &:after {
                    top: 16px;
                }

                &:before {
                    transform: rotate(-30deg);
                }

                &:after {
                    transform: rotate(30deg);
                }
            }
        }
    }

    .cookieGroupTitle {
        margin-top: 30px;
        margin-bottom: 10px;

        &:first-child {
            margin-top: 22px;
        }
    }

    .collapse {
        .bodytext:last-child {
            margin-bottom: 0;
            padding-bottom: 10px;
        }
    }

    .cc-compliance {
        display: flex;
        justify-content: flex-end;
        margin-top: 29px;
        margin-left: -20px;

        .btn {
            margin-top: 20px;
            margin-left: 20px;
        }
    }

    @include media-breakpoint-down(sm) {
        .modal-dialog {
            min-height: 100vh;
            margin: 0;
        }

        .modal-content {
            min-height: 100vh;
        }

        .modal-body {
            display: flex;
            flex-direction: column;
            height: 100vh;
        }

        .cc-message {
            overflow: auto;
        }

        .cc-compliance {
            flex-grow: 1;
            flex-direction: column;
            margin-top: 10px;
            z-index: 100;

            .cc-allow {
                order: 2;
            }

            .cc-dismiss {
                order: 1;
            }
        }
    }
}

.mobileSafari {
    @include media-breakpoint-down(sm) {
        .cookieConsentModal {
            .modal-dialog {
                height: 100%;
                min-height: auto;
            }

            .modal-content {
                height: 100%;
                min-height: auto;
            }

            .modal-body {
                height: 100%;
            }

            .cc-compliance {
                padding-top: 10px;
            }

            .dp--cookie-check {
                margin-bottom: 50px;
            }
        }
    }
}

.dp--overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;

    #mapCanvas & {
        width: 100%;
        height: 100%;
        background-image: url('/fileadmin/images/cookie-consent-map-background.jpg');
        background-size: cover;
    }

    .dp--overlay-inner {
        max-width: 800px;
    }

    .dp--overlay-header {
        font-weight: 700;
        margin-bottom: 13px;
    }

    .dp--overlay-button {
        margin-top: 22px;
    }
}

.uc-embedding-container {
    position: absolute !important;
    top: 0;
    left: 0;
    max-height: none !important;
    overflow: hidden !important;

    @include media-breakpoint-down(sm) {
        min-height: auto !important;
    }

    .uc-embedding-buttons {
        justify-content: center !important;
    }
}

.uc-embedding-wrapper {
    font-family: $font-family-sans-serif !important;
    font-size: 16px !important;
    line-height: 26px !important;
    background-color: #fff !important;
    border-radius: 0 !important;
    padding: 30px !important;
    width: auto !important;

    @include media-breakpoint-down(sm) {
        width: calc(100% - 80px) !important;
        max-height: calc(100% - 30px) !important;
        overflow-y: scroll;
    }

    h3 {
        font-size: $h3-font-size !important;
    }

    .uc-embedding-buttons {
        @include media-breakpoint-down(xs) {
            padding-bottom: 30px !important;
            display: flex;
            flex-direction: column;
        }
    }

    .uc-embedding-accept,
    .uc-embedding-more-info {
        border-radius: 2px !important;
        padding: 11px 29px !important;
        font-weight: 700;
        text-transform: uppercase;
        height: auto !important;
        transition: color .15s ease-in-out , background-color .15s ease-in-out , border-color .15s ease-in-out , box-shadow .15s ease-in-out !important;
        margin-top: 20px !important;
        margin-bottom: 0px !important;
        width: auto !important;

        @include media-breakpoint-down(sm) {
            padding: 7px 26px 9px !important;

            @include onlyOnMobile {
                padding-top: 9px !important;
                padding-bottom: 7px !important;
            }
        }
    }

    .uc-embedding-accept {
        background-color: $green !important;
        border-color: $green !important;
        margin-left: 20px !important;

        &:focus, &.focus, &:active, &.active {
            box-shadow: none !important;
        }

        &:hover, &:focus {
            background-color: $green-dark !important;
            border-color: $green-dark !important;
        }

        @include media-breakpoint-down(xs) {
            margin-left: 0px !important;
        }
    }

    .uc-embedding-more-info {
        color: $green !important;
        background-color: transparent !important;
        background-image: none;
        border: 1px solid $green !important;

        &:hover, &:focus, &:active {
            color: $green-dark !important;
            background-color: #fff !important;
            border-color: $green-dark !important;
        }
    }
}

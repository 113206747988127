.downloadList {
    padding-top: 6px;
    margin-bottom: -9px;

    .list-inline-item {
        margin-bottom: 15px;

        &:not(:last-child) {
            margin-right: 30px;
        }

        a {
            width: 100%;
            white-space: normal;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;

            &:last-child {
                padding-bottom: 2px;
            }
        }

        .btn {
            .icon {
                margin-right: 10px;
            }
        }
    }
}

.btn {
    border-radius: 2px;
    padding: 11px 29px;

    &:focus, &.focus, &:active, &.active {
        box-shadow: none !important;
    }

    &:not(.btn-link) {
        font-weight: 700;
        text-transform: uppercase;
    }

    .bodytext & {
        margin-top: 7px;
        margin-bottom: 7px;
    }

    .icon {
        line-height: 0;
    }

    .icon-play {
        position: relative;
        top: 4px;
        left: -7px;
        padding-right: 4px;
        font-size: 22px;
    }

    .icon-cart {
        margin-right: 10px;
    }

    @include media-breakpoint-down(sm) {
        padding: 7px 26px 9px;

        @include onlyOnMobile {
            padding-top: 9px;
            padding-bottom: 7px;
        }
    }
}

.btn-primary {
    color: #fff;

    &:hover, &:focus {
        background-color: $green-dark;
        border-color: $green-dark;
    }
}

.btn-secondary {
    background-color: transparent;
    border-color: $green;
    color: $green;

    &:hover, &:focus, &:active {
        background-color: transparent !important;
        border-color: $green-dark !important;
        color: $green-dark !important;
    }
}

.btn-white {
    background-color: #fff;
    border-color: #fff;
    color: $green !important;
    text-decoration: none !important;

    &:hover, &:focus {
        background-color: $green-light !important;
        border-color: $green-light !important;
    }
}

.btn-gray {
    background-color: $gray-100;
    border-color: $gray-100;
    color: $body-color;

    &:hover, &:focus {
        background-color: $gray-200;
        border-color: $gray-200;
    }
}

.btn-outline-primary {
    &:hover, &:focus, &:active {
        color: $green-dark !important;
        background-color: #fff !important;
        border-color: $green-dark !important;
    }
}

.btn-sm {
    padding: 8px 17px;
    font-size: $font-size-base;
    line-height: $btn-line-height;
}

.btn-icon-external {
    &:before {
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: 'Linearicons' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        content: '\e7b0';

        margin-right: 10px;
    }
}

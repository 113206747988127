#footer {
    position: relative;
    z-index: 11;
    background-color: #fff;
    border-top: 1px solid $gray-200;
    padding-top: 40px;
    padding-bottom: 40px;

    .container {
        max-width: 1100px;
        // max-width: 1480px;
        padding-right: 15px;
        padding-left: 15px;
    }

    .footerTop {
        display: flex;
        align-items: center;
    }

    .logo {
        width: 117px;
        height: auto;
    }

    .socialMediaLinks {
        margin-right: -13px;
        margin-left: auto;

        .weChatBtn {
            font-size: 24px;
            position: relative;
            top: -2px;
            // text-transform: none;
            // padding-top: 3px;
            // padding-bottom: 14px;
            // padding-left: 13px;
            // padding-right: 15px;

            &:hover {
                color: #8be14c;
                // color: #4d4d4d;
            }

            &:before {
                // content: "";
                // width: 30px;
                // height: 30px;
                // background-image: url(/fileadmin/images/qr-code-we-chat.jpg);
                // background-size: contain;
                // display: inline-block;
                // position: relative;
                // top: 9px;
                // margin-right: 10px;
            }
        }

        .nav-link {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0;
        }

        .icon {
            padding: 8px 13px;
            color: $green;
            height: auto;
            width: auto;
        }

        .fa-twitter {
            font-size: 24px;
            position: relative;
            top: -2px;

            &:hover, &:focus {
                color: #1da1f3;
            }
        }

        .fa-youtube {
            font-size: 26px;
            position: relative;
            top: -2px;

            &:hover, &:focus {
                color: #c72020;
            }
        }

        .fa-google-plus-g {
            font-size: 20px;

            &:hover, &:focus {
                color: #dd4b39;
            }
        }

        .fa-linkedin-in {
            position: relative;
            top: -2px;
            font-size: 22px;

            &:hover, &:focus {
                color: #0077b5;
            }
        }

        .fa-facebook {
            position: relative;
            top: -2px;
            font-size: 22px;

            &:hover, &:focus {
                color: #3b5998;
            }
        }

        .fa-instagram {
            position: relative;
            top: -2px;
            font-size: 22px;

            &:hover, &:focus {
                color: #d10869;
            }
        }
    }

    .metaNavi {
        display: flex;
        margin-top: 25px;
        margin-left: -16px;

        .nav-link {
            color: $body-color;

            &:hover, &:focus {
                color: $green;
            }

            &.active {
                color: $green;
            }
        }
    }

    .footerNav {
        flex-direction: row;
        margin-top: 32px;
        margin-bottom: 50px;
        margin-right: -15px;
        margin-left: -15px;

        > .nav-item {
            max-width: 25%;
            flex-basis: 25%;
            padding-right: 15px;
            padding-left: 15px;

            > span {
                @at-root body.textIsSometimesNotBoldEnough & {
                    // Fix for https://www.murrelektronik.kr/kr/
                    font-weight: 600;
                }
            }

            > .nav-link {
                // pointer-events: none;
                color: $body-color;
                font-weight: bold;
            }

            .nav {
                .nav-item {
                    // font-size: 14px;
                    font-weight: 300;

                    .nav-link {
                        color: $body-color;
                        padding-top: 7px;
                        padding-bottom: 7px;
                        line-height: 20px;

                        &:hover {
                            color: $green;
                        }

                        &[target="_blank"]:after {
                            @extend %linearicons, %lnr-launch:before;
                            position: relative;
                            top: 2px;
                            margin-left: 10px;
                        }
                    }

                    &.active {
                        .nav-link {
                            color: $green;

                            &:hover {
                                color: $green-dark;
                            }
                        }
                    }
                }
            }
        }
    }

    #footerAccordion {
        margin-top: 42px;
        margin-bottom: 0;

        .header {
            &:hover {
                color: $body-color !important;

                .btn {
                    color: $body-color;
                }
            }

            .btn-link {
                padding-top: 14px;
                padding-bottom: 14px;

                &:before,
                &:after {
                    top: 24px;
                }

                &:hover {
                    color: $green;
                }
            }
        }

        .card-body {
            .nav-item {
                .nav-link {
                    padding-right: 0;
                    padding-left: 0;
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 24px;
                    color: $body-color;

                    &:hover {
                        color: $green;
                    }

                    &[target="_blank"]:after {
                        @extend %linearicons, %lnr-launch:before;
                        position: relative;
                        top: 2px;
                        margin-left: 10px;
                    }
                }
            }
        }

        .legalMobile {
            margin-top: 20px;
            margin-bottom: 0;

            @include media-breakpoint-down(sm) {
                padding-left: 15px;
                padding-right: 15px;
            }


            > span {
                font-weight: 500;
                padding-bottom: 14px;
                display: block;
            }
            .nav-item {
                .nav-link {
                    padding-right: 0;
                    padding-left: 0;
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 24px;
                    color: $body-color;

                    &:hover {
                        color: $green;
                    }

                    &[target="_blank"]:after {
                        @extend %linearicons, %lnr-launch:before;
                        position: relative;
                        top: 2px;
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    .footerBottom {
        margin-top: 5px;
    }

    .copyrightAndNavigationContainer {
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }

    .legalNavi {
        margin-bottom: 2px;
        margin-left: -16px;

        a {
            color: $body-color;
            font-weight: 300;

            &:hover, &:focus, &.active {
                color: $link-color;
            }

            &[target="_blank"]:after {
                @extend %linearicons, %lnr-launch:before;
                position: relative;
                top: 2px;
                margin-left: 10px;
            }
        }
    }

    .copyright {
        color: $gray-400;
        font-size: 14px;
        line-height: 22px;
        max-width: none;

        a {
            color: $gray-400;

            &:hover, &:focus {
                color: $green;
                text-decoration: none;
            }
        }

        .icpLicence {
            padding-left: 20px;
            padding-right: 20px;

            @include media-breakpoint-down(md) {
                display: block;
                padding-left: 0;
                padding-right: 0;
                margin-top: 5px;
                margin-bottom: 5px;
            }
        }

        .psbFiling {
            padding-right: 20px;

            .psbIcon {
                content: url(../images/psb-icon.png);
                position: relative;
                top: 3px;
                height: 16px;
                padding-right: 5px;
            }

            @include media-breakpoint-down(md) {
                padding-right: 0;
                display: block;
            }
        }
    }

    .jumpToTop {
        align-self: flex-end;
        margin-bottom: 10px;
        margin-left: auto;
        font-size: 14px;
        line-height: 22px;

        @media (max-width: 1300px) {
            margin-right: 100px;
        }

        @include media-breakpoint-down(sm) {
            margin-left: 0;
            margin-right: 0;
        }

        &:hover, &:focus {
            text-decoration: none;
        }

        .icon {
            font-size: 8px;
            padding-right: 7px;
        }
    }

    @include media-breakpoint-down(xs) {
        padding-top: 47px;
        padding-bottom: 24px;

        .footerTop {
            display: block;
        }

        .logo {
            width: 99px;
        }

        .socialMediaLinks {
            margin-top: 36px;
            margin-left: -13px;
        }

        .metaNavi {
            flex-direction: column;

            .nav-link {
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }

        .copyright {
            margin-top: 28px;
            margin-bottom: 23px;

            .realization {
                display: block;
                margin-top: -4px;
            }
        }
    }
}

h1, .h1 {
    line-height: $h1-line-height;
    max-width: 800px;
}

h2, .h2 {
    line-height: $h2-line-height;
    color: $green;
    margin-top: 74px;
    margin-bottom: 20px;
    max-width: 800px;

    .bg-gray & {
        color: $green !important;
    }

    .bg-green-light & {
        color: $green !important;
    }

    .bg-green & {
        color: #fff !important;
    }

    &.leadHeadline {
        max-width: 800px;
    }

    .centeredVideoAndText & {
        max-width: 935px;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
    }
}

h3, .h3 {
    line-height: $h3-line-height;
    font-weight: 700;
    margin-top: 64px;
    margin-bottom: 20px;
    max-width: 800px;

    @include media-breakpoint-down(sm) {
        margin-top: 54px;
    }
}

.bigBoldHeadlineContainer {
    margin-top: 94px;
    margin-bottom: 91px;
}

.bigBoldHeadline {
    max-width: none;
    margin: 0;
    font-size: 140px;
    // font-size: 80px;
    line-height: percentage(115 / 140);
    font-weight: 900;
    text-transform: uppercase;
    // text-align: center;
}

@include media-breakpoint-down(sm) {
    .bigBoldHeadlineContainer {
        margin-top: 57px;
        margin-bottom: 59px;
    }

    .bigBoldHeadline {
        // font-size: 112px;
        font-size: 64px;
    }
}

// @include media-breakpoint-down(xs) {
//     .bigBoldHeadline {
//         font-size: 80px;
//     }
// }

.lead {
    max-width: 800px;
    line-height: 28px;
    margin-bottom: 20px;

    .centeredVideoAndText & {
        max-width: 935px;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
    }
}

p {
    max-width: 800px;

    .centeredVideoAndText & {
        max-width: 935px;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
    }
}

.contentList {
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 800px;

    li {
        position: relative;
        padding-top: 5px;
        padding-left: 32px;
        padding-bottom: 5px;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }

    .contentList, ul, ol {
        margin-top: 10px;
        margin-bottom: 0;
    }
}

ul.contentList {
    li {
        &:before {
            content: '';
            position: absolute;
            top: 14px;
            left: 4px;
            display: block;
            width: 8px;
            height: 8px;
            background-color: $green;
        }

        &:first-child:before {
            top: 9px;
        }
    }
}

ol.contentList {
    counter-reset: index;

    li {
        &:before {
            content: counter(index);
            counter-increment: index;
            position: absolute;
            left: 4px;
        }
    }
}

.bg-green {
    &, h2 {
        color: #fff;
    }

    a {
        color: #fff;
        text-decoration: underline;

        &:hover, &:focus {
            text-decoration: none;
        }
    }

    ul.contentList {
        li {
            &:before {
                background-color: #fff;
            }
        }
    }
}

.errors,
.error {
    color: $error-red;
}

@include media-breakpoint-up(lg) {
    a[href^="tel:"] {
        display: inline-block;
        pointer-events: none;
        color: $body-color;

        .bg-green & {
            color: #fff;
            text-decoration: none;
        }
    }
}

.nl-landingpage {
    h1, h2, h3, .lead {
        max-width: 100%;
    }

    .stage .textContainer .text {
        max-width: 100% !important;
    }
}

@include media-breakpoint-down(sm) {
    h2, .h2 {
        font-size: 24px;
        line-height: 30px;
        margin-top: 39px;
    }

    h3, .h3 {
        margin-top: 39px;
    }

    // .lead {
    //     font-size: 18px;
    //     line-height: 26px;
    // }
}

.pagerContainer {
    background-color: $green;

    .fullWidthElement.bg-green + & {
        border-top: 1px solid $green-darker;
    }
}

.pager {
    margin-bottom: 0;

    li {
        display: flex;
        align-items: center;
    }

    a {
        position: relative;
        display: block;
        width: 100%;
        padding-top: 45px;
        padding-bottom: 45px;

        &:hover, &:focus {
            .icon {
                background-color: #dffbde;
            }

            .text {
                color: #dffbde;
            }
        }
    }

    .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
        width: 35px;
        height: 35px;
        flex-shrink: 0;
        background-color: #fff;
        color: $green;

        @include media-breakpoint-down(xs) {
            width: 30px;
            height: 30px;
        }

        &:before {
            position: relative;
            top: 8px;
            left: 14px;
            font-size: 19px;

            @include media-breakpoint-down(xs) {
                font-size: 16px;
                top: 7px;
                left: 12px;
            }
        }
    }

    .prev {
        a {
            padding-right: 15px;
            padding-left: 51px;

            @include media-breakpoint-down(xs) {
                padding-left: 40px;
            }
        }

        .icon {
            left: 0;
        }
    }

    .next {
        a {
            padding-right: 51px;
            padding-left: 15px;

            @include media-breakpoint-down(xs) {
                padding-right: 40px;
            }
        }

        .icon {
            right: 0;
        }
    }

    .text {
        font-size: 30px;
        line-height: 38px;
        font-weight: 300;
        color: #fff;
    }

    @include media-breakpoint-down(md) {
        .text {
            font-size: 24px;
            line-height: 30px;
        }
    }

    @include media-breakpoint-down(sm) {
        .text {
            font-size: 20px;
            font-weight: 500;
        }
    }

    @include media-breakpoint-down(xs) {
        .text {
            font-size: 16px;
            line-height: 20px;
        }
    }
}

.browsebox-bottom {
    .pagination {
        margin-top: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 4px;
        margin-bottom: 0px;

        li {
            margin-right: 5px;
            margin-left: 5px;

            a {
                color: $gray-500;
                padding: 5px;

                &:hover,
                &:focus {
                    color: $green;
                }
            }

            &.active {
                a {
                    color: $green;
                    pointer-events: none;
                }
            }

            &.pull-left,
            &.pull-right {
                font-size: 20px;

                a {
                    color: #fff !important;
                    background-color: $green;
                    padding-right: 16px;
                    padding-left: 16px;
                    padding-top: 7px;
                    padding-bottom: 7px;

                    &:focus,
                    &:hover {
                        background-color: $green-dark;
                    }

                    .icon {
                        position: relative;
                        top: 2px;
                    }
                }
            }

            &.pull-left {
                margin-left: 0;
                margin-right: 20px;
            }

            &.pull-right{
                margin-left: 20px;
                margin-right: 0;
            }
        }


    }
}

.contactLayerToggler {
    position: fixed;
    right: 15px;
    bottom: 15px;
    width: 80px;
    height: 80px;
    z-index: 12;
    border-radius: 50%;
    background-color: $green;
    border: 1px solid $green-darker;
    color: #fff;
    cursor: pointer;
    box-shadow: 1px 2px 27px 0px rgba(#000, 0.13);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateX(200px);
    transition: 300ms transform linear;

    &.show {
        transform: translateX(0);
    }

    @include media-breakpoint-down(sm) {
        width: 60px;
        height: 60px;
    }

    &:hover, &:focus {
        background-color: $green-dark;
        border-color: $green-dark;
    }

    .icon {
        font-size: 26px;
        // margin-top: 6px;
        margin-bottom: 5px;
        margin-left: 1px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 0;
        }
    }

    .text {
        font-size: 12px;
        line-height: 14px;
        max-width: 72px;
        hyphens: none;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}

.closeBtnContainer {
    max-width: 1100px;
    // height: 100%;
    position: absolute;
    margin-right: auto;
    margin-left: auto;
    left: 0;
    right: 0;
    top: 0;
    z-index: 10;

    @media (min-width: 1470px) {
        width: 1100px;
    }
}

// .weChatToggler {
//     margin-right: 15px;
// }

#tinet-chat-visitor {
    visibility: hidden;
    opacity: 0;
    z-index: -99999 !important;
}

#TinetWebChat {
    font-family: $font-family-sans-serif;

    .tinet-button {
        .iconfont {
            &.iconzuixiaohua {
                display: none;
            }
        }
    }

    .el-button--primary {
        color: #fff;
        background-color: $green !important;
        font-family: $font-family-sans-serif !important;
        transition: color .15s ease-in-out , background-color .15s ease-in-out , border-color .15s ease-in-out , box-shadow .15s ease-in-out;
        padding: 15px 26px !important;

        &:hover {
            background-color: $green-dark !important;
        }
    }

    .tinet-inquiry-content {
        padding: 30px;

        .tinet-title {
            display: none;
        }
    }

    .el-input.is-active .el-input__inner,
    .el-input__inner:focus {
        border-color: $green;
    }

    .tinet-inquiry-content .el-form-item label {
        font-family: $font-family-sans-serif !important;
    }

    .el-input__inner {
        height: 43px;
        padding: 6px 13px 7px;
        border-radius: 3px;
        font-size: 16px;
        line-height: 1.625;
        color: #495057;
        border: 1px solid #dde0e5;
        transition: border-color 0.15s ease-in-out;
        font-family: $font-family-sans-serif !important;
    }

    .tinet-inquiry-content .el-form-item label {
        font-size: 16px;
        font-weight: 700;
        line-height: 1.5;
        margin-bottom: 2px;
    }
}

.contactLayer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 800px;
    min-height: 350px;
    z-index: 1000;
    overflow: hidden;
    background-color: #37a40d;
    color: #fff;
    transform: translate3d(0, -100%, 0);
    transition: transform 300ms linear;

    @media (max-height: 920.01px) {
        height: 100vh;
    }

    &.show {
        transform: translate3d(0, 0, 0);
        overflow: visible;
    }

    .closeBtnContainer {
        height: 0;
        z-index: 2;
    }

    .closeBtn {
        position: absolute;
        top: 17px;
        right: 22px;
        z-index: 1;
        color: #fff;
        background: none;
        border: none;
        cursor: pointer;
        padding: 10px;
        font-size: 20px;
        line-height: 1;

        &:hover, &:focus {
            color: $white-link-hover-color;
        }

        @include media-breakpoint-down(sm) {
            right: 4px;
        }
    }

    .contentElementContainer, .row {
        height: 100%;
        position: relative;
    }

    .textContainerWrap {
        display: flex;
        align-items: center;
        max-width: #{$content-width + 30px};
        padding-right: 15px;
        padding-left: 15px;
        margin: 0 auto;

        @media (max-height: 920.01px) {
            padding-left: 32px;
        }
    }

    .imageContainer {
        width: percentage(500 / 1100);
        flex-grow: 1;
        flex-basis: auto;
        align-self: flex-end;
        text-align: right;

        .img-fluid {
            @include onlyInIE {
                max-height: 393px;
            }
        }

        @media (max-height: 920.01px) {
            padding-right: 32px;
        }
    }

    .textContainer {
        max-width: 520px;
        position: relative;
        margin-top: 72px;
        flex-shrink: 0;
    }

    .headline {
        color: #fff;
        // margin-top: 92px;
        margin-bottom: 19px;
        // position: absolute;
        top: 0;
        left: 0;
        width: 500px;
        max-width: calc(100vw - 15px);
    }

    .text {
        // font-size: 20px;
        // line-height: 28px;
        // font-weight: 300;
        line-height: 30px;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 51px;
        // padding-top: 70px;
    }

    .weChatLayer {
        position: absolute;
        top: 0;
        width: 100%;
        overflow: hidden;
        pointer-events: none;
        height: 100%;
        visibility: hidden;
        transition: visibility 0ms linear 300ms;
        padding-left: 14px;
        padding-right: 15px;

        &.show {
            visibility: visible;
            transition: visibility 0ms linear;

            .left {
                transform: translate3d(0, 0, 0);
            }

            .right {
                transform: translate3d(0, 0, 0);
            }
        }

        .backLink {
            position: absolute;
            top: 142px;
            left: 0;
            z-index: 100;

            @include media-breakpoint-down(md) {
                position: static;
            }

            .btn {
                color: #fff;
                font-size: 30px;
                line-height: 35px;
                font-weight: 300;
                margin-left: -3px;
                padding: 6px 11px 6px;

                @include media-breakpoint-down(md) {
                    margin-left: -11px;
                }

                .icon {
                    position: relative;
                    top: -1px;
                    padding-right: 14px;
                    font-size: 19px;
                }
            }
        }

        .descriptionContainer {
            min-height: 344px;

            @include media-breakpoint-down(xs) {
                max-width: 100%;
            }

            .headline {
                width: auto;
                position: relative;
            }

            .text {
                padding-top: 0;
            }

            .qrCodeContainer {
                justify-content: center;

                .qrCodeContainerInner {
                    max-width: 250px;
                }
            }
        }

        .left,
        .right {
            transition: transform 300ms linear;
            background-color: #37a40d;
            pointer-events: all;
            display: flex;
            align-items: center;
        }

        .left {
            transform: translate3d(-100%, 0, 0);
            padding-left: 8px;
            padding-right: 15px;

            @include media-breakpoint-down(xs) {
                width: 100% !important;
                padding: 0;
                align-items: flex-start;
            }
        }

        .right {
            transform: translate3d(100%, 0, 0);
            justify-content: center;
            padding-left: 15px;

            .qrCode {
                @media (max-width: 850px) {
                    max-width: 250px;
                }
            }
        }
    }

    .links {
        // column-count: 2;
        margin-bottom: 67px;

        li {
            // display: inline-block;
            display: block;
            page-break-inside: avoid;
        }

        @media (max-width: 500.98px) {
            // column-count: 1;

            li {
                display: block;
            }
        }

        a {
            display: block;
            padding-top: 6px;
            padding-bottom: 6px;
            font-size: 20px;
            line-height: 28px;
            font-weight: 300;
            color: #fff;
            padding-left: 44px;
            text-indent: -44px;


            &:hover, &:focus {
                color: $white-link-hover-color;
            }
        }

        .icon {
            position: relative;
            line-height: 0;
        }

        .lnr-man {
            top: 2px;
            font-size: 25px;
            padding-right: 20px;
        }

        .lnr-envelope-open {
            top: 5px;
            font-size: 29px;
            padding-right: 16px;
        }

        .lnr-hammer-wrench {
            top: 5px;
            font-size: 25px;
            padding-right: 19px;
        }

        .lnr-location {
            top: 5px;
            font-size: 25px;
            padding-right: 19px;
        }

        .lnr-register {
            top: 5px;
            font-size: 25px;
            padding-right: 20px;
        }

        .lnr-telephone {
            top: 5px;
            font-size: 26px;
            padding-right: 19px;
        }

        .lnr-earth {
            top: 6px;
            font-size: 27px;
            padding-right: 17px;
        }

        .lnr-bubbles {
            top: 5px;
            font-size: 29px;
            padding-right: 16px;
        }

        .icon-wechat-outline {
            top: 5px;
            font-size: 24px;
            padding-right: 16px;
        }
    }

    @media (max-height: 737.01px) {
        .imageContainer {
            // display: none;
        }
    }

    @media (max-width: 1100.01px) {
        // height: #{(800 / 1100) * 100vw};

        .imageContainer {
            width: calc(100vw - 600px);
        }
    }

    @include media-breakpoint-down(md) {
        // height: auto;
        max-height: 100vh;

        .imageContainer {
            // display: none;
            // flex-grow: 1;
            // max-width: 325px;
        }
    }

    @media (max-width: 380px) and (max-height: 690px) {
        .imageContainer {
            display: none !important;
        }

        .textContainer {
            align-self: center;
        }
    }

    @media (max-height: 675px) and (max-width: 767px) {
        .imageContainer {
            display: none !important;
        }

        .textContainer {
            align-self: center;
        }
    }

    @include media-breakpoint-down(sm) {
        .imageContainer {
            display: block;
            width: 100vw;
            max-width: none;
            text-align: center;
            padding-right: 15px;
        }
    }

    @include media-breakpoint-down(sm) {
        height: 100vh;

        .textContainerWrap {
            align-items: flex-start;
            padding-left: 15px;
        }

        .textContainer {
            margin-top: 56px;
        }

        .weChatLayer {
            .backLink {
                margin-top: 56px;
            }
        }

        .headline {
            // margin-top: 59px;
            position: static;
            width: auto;
            max-width: none;
            font-size: 34px;
            line-height: 42px;
        }

        .text {
            margin-bottom: 36px;
            padding-top: 0;
        }

        .links {
            margin-bottom: 52px;
        }
    }
}

.noTransition {
    transition: none !important;
}

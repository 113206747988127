.swiper-button-prev, .swiper-button-next {
    display: none;
    width: 35px;
    height: 35px;
    background-image: none;
    background-color: $gray-300;
    margin-top: 0;
    transform: translateY(-50%);

    :hover > .swiper-container &,
    :hover > .swiper-container ~ & {
        display: block;
    }

    &:hover {
        background-color: $gray-400;
    }

    &.swiper-button-disabled {
        display: none !important;
    }

    &:before {
        position: relative;
        top: 9px;
        left: 14px;
        display: block;
        @extend %iconfont-murrelektronik;
        color: #fff;
        font-size: 19px;
    }

    @include media-breakpoint-down(md) {
        display: block;
    }
}

.swiper-button-prev {
    left: 0;

    &:before {
        @extend %icon-chevron-left:before;
    }
}

.swiper-button-next {
    right: 0;

    &:before {
        @extend %icon-chevron-right:before;
    }
}

.swiper-pagination {
    bottom: -50px !important;
    left: 0;
    width: 100%;
    text-align: center;
    line-height: 0;
}

.swiper-pagination-bullet {
    border-radius: 0;
    opacity: 1;
    background-color: #e1e1e1;
    margin: 0 !important;
    border: 5px solid transparent;
    background-clip: padding-box;
    width: 18px;
    height: 18px;

    &:hover, &:focus {
        background-color: $gray-400;
        outline: none;
    }

    &-active {
        &, &:hover, &:focus {
            background-color: $green;
        }
    }

    .bg-gray & {
        &:not(:hover):not(:focus):not(.swiper-pagination-bullet-active) {
            background-color: $gray-300;
        }
    }

    .bg-green & {
        background-color: rgba(#fff, .4);

        &:hover, &:focus {
            background-color: $green-dark;
        }

        &-active {
            &, &:hover, &:focus {
                background-color: #fff;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .swiper-pagination {
        bottom: -37px !important;
    }
}

.swiper-slide {
    width: 25%;

    @media (max-width: 950px) {
        width: 33%;
    }
    @media (max-width: 677px) {
        width: 50%;
    }
    @media (max-width: 404px) {
        width: 100%;
    }
}
